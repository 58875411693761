import { PantryColor, sxCompose } from '@dropkitchen/pantry-react';
import type { TextFieldProps } from '@mui/material';
import { InputAdornment, Box, TextField } from '@mui/material';
import { useState, useEffect, useId, memo } from 'react';
import type { FC } from 'react';

import { ErrorHelperText } from 'components/ErrorHelperText/ErrorHelperText';
import { timeFieldStrings } from 'components/timeField/TimeField.constants';
import type { AppTime } from 'types/appTime';
import { timeUnitToString } from 'utils/convertTimes';
import type { ValidatorError } from 'utils/validator';

const isInvalidTime = (time: number, max: number): boolean =>
  Number.isNaN(time) || time < 0 || time > max;

const validateFormat = (time: AppTime): TimeFormatErrors => {
  if (time.hours !== undefined && isInvalidTime(time.hours, 999)) {
    return { hours: timeFieldStrings.errors.hoursNotValid };
  }
  if (time.minutes !== undefined && isInvalidTime(time.minutes, 59)) {
    return { minutes: timeFieldStrings.errors.minutesNotValid };
  }
  if (time.seconds !== undefined && isInvalidTime(time.seconds, 59)) {
    return { seconds: timeFieldStrings.errors.secondsNotValid };
  }
  return {};
};

const hasWrongFormat = (formatErrors: TimeFormatErrors): boolean =>
  !!(formatErrors.hours || formatErrors.minutes || formatErrors.seconds);

interface TimeFormatErrors {
  hours?: string;
  minutes?: string;
  seconds?: string;
}
export interface TimeFieldProps
  extends Omit<TextFieldProps, 'onChange' | 'value'> {
  onChange: (value: AppTime) => void;
  errors?: ValidatorError;
  showSeconds?: boolean;
  value?: AppTime;
  required?: boolean;
}

export const TimeField: FC<TimeFieldProps> = function TimeField({
  id,
  label,
  onChange,
  errors = {},
  showSeconds = true,
  value,
  required,
  sx,
}) {
  const [inputHours, setInputHours] = useState<string>(
    timeUnitToString(value?.hours)
  );
  const [inputMinutes, setInputMinutes] = useState<string>(
    timeUnitToString(value?.minutes)
  );
  const [inputSeconds, setInputSeconds] = useState<string>(
    timeUnitToString(value?.seconds)
  );
  const [formatErrors, setFormatErrors] = useState<TimeFormatErrors>({});

  const generatedId = useId();
  const componentId = id ?? generatedId;

  useEffect(() => {
    setInputHours(timeUnitToString(value?.hours));
    setInputMinutes(timeUnitToString(value?.minutes));
    setInputSeconds(timeUnitToString(value?.seconds));
  }, [value]);

  const { placeholders, ids } = timeFieldStrings;

  const handleChange = (key: keyof AppTime, newValue: string) => {
    const newTime: AppTime = {
      ...value,
      [key]: newValue === '' ? undefined : +newValue,
    };
    const currentFormatErrors = validateFormat(newTime);
    setFormatErrors(currentFormatErrors);
    if (!hasWrongFormat(currentFormatErrors)) {
      onChange(newTime);
    }
  };

  const hasErrors =
    !!Object.values(formatErrors).length || !!Object.values(errors).length;
  const errorMessage =
    formatErrors.hours ||
    formatErrors.minutes ||
    formatErrors.seconds ||
    Object.values(errors)[0];

  return (
    <>
      <Box sx={sxCompose(sx, { display: 'flex' })}>
        <TextField
          error={!!hasErrors}
          id={`${componentId}${ids.hours}`}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: <TimeInputAdornment text="h" />,
          }}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          label={label}
          onChange={(e) => {
            setInputHours(e.target.value);
            handleChange('hours', e.target.value);
          }}
          placeholder={placeholders.hours}
          sx={{ mr: 1 }}
          value={inputHours}
          required={required}
        />
        <TextField
          error={hasErrors}
          id={`${componentId}${ids.minutes}`}
          InputProps={{
            endAdornment: <TimeInputAdornment text="m" />,
          }}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          onChange={(e) => {
            setInputMinutes(e.target.value);
            handleChange('minutes', e.target.value);
          }}
          placeholder={placeholders.minutes}
          sx={{ mr: showSeconds ? 1 : 0 }}
          value={inputMinutes}
          required={required}
        />
        {showSeconds && (
          <TextField
            error={hasErrors}
            id={`${componentId}${ids.seconds}`}
            InputProps={{
              endAdornment: <TimeInputAdornment text="s" />,
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            onChange={(e) => {
              setInputSeconds(e.target.value);
              handleChange('seconds', e.target.value);
            }}
            placeholder={placeholders.seconds}
            value={inputSeconds}
            required={required}
          />
        )}
      </Box>
      {errorMessage && <ErrorHelperText message={errorMessage} />}
    </>
  );
};

interface TimeInputAdornmentProps {
  text: string;
}

const TimeInputAdornment: FC<TimeInputAdornmentProps> = memo(
  function TimeInputAdornment({ text }) {
    return (
      <InputAdornment position="end">
        <Box component="span" sx={{ color: PantryColor.TextMuted }}>
          {text}
        </Box>
      </InputAdornment>
    );
  }
);
