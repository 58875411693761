import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createAction, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import round from 'lodash/round';

import type { ApiGetRecipeFromTextRequest } from 'api/recipes';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import type { RootState } from 'app/store/rootReducer';
import { calculateIndexAfterReordering } from 'components/DragAndDrop/DragZone';
import { authSignOutFinished } from 'features/auth/authSlice';
import { recipePageConstants } from 'features/recipe/RecipePage.constants';
import { getQuantityAsText } from 'features/recipe/review/ingredients/recipeReviewIngredients.utils';
import { recipeValidator } from 'features/recipe/shared/validators/recipeValidator';
import type { AppRecipe } from 'types/recipe/appRecipe';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';
import { noTime } from 'utils/convertTimes';
import { getCurrentTime } from 'utils/getCurrentTime';
import type { ValidatorErrors } from 'utils/validator';

export interface RecipeState {
  apiError?: string;
  recipeErrors: ValidatorErrors;
  fetching: boolean;
  recipe: AppRecipe;
  pristineRecipe?: AppRecipe;
  saving: boolean;
  recipeIngredientsUsage: AppRecipeIngredientsUsage[];
  publishing: boolean;
  unpublishing: boolean;
  submitted: boolean;
  hasUnsavedChanges: boolean;
  lastSavedAt?: number;
  publishConfirmationRequired?: boolean;
}

export interface AppRecipeIngredientsUsageStep
  extends Pick<AppRecipeStep, 'id'> {
  index: number;
}

export interface AppRecipeIngredientsUsage {
  used: {
    steps: AppRecipeIngredientsUsageStep[];
    quantity: AppRecipeIngredientQuantity;
  };
  left: AppRecipeIngredientQuantity;
}

const initialRecipe: AppRecipe = {
  locale: undefined,
  ingredients: [],
  name: '',
  steps: [],
  description: undefined,
  author: { name: '', image: '', url: '' },
  prepTime: noTime,
  cookTime: noTime,
  totalTime: noTime,
  state: ApiRcpRecipeState.Draft,
  applianceReferenceTags: undefined,
  serves: 0,
};

export const initialState: RecipeState = {
  fetching: false,
  saving: false,
  publishing: false,
  unpublishing: false,
  submitted: false,
  hasUnsavedChanges: false,
  recipe: initialRecipe,
  recipeIngredientsUsage: [],
  recipeErrors: recipeValidator.validate({ ...initialRecipe }) || {},
  lastSavedAt: undefined,
};

/**
 * Common recipe fields that can be updated by the recipeFieldUpdated action
 */
export type AppRecipeFieldUpdateKeys = Extract<
  keyof AppRecipe,
  | 'name'
  | 'description'
  | 'author'
  | 'prepTime'
  | 'cookTime'
  | 'totalTime'
  | 'applianceReferenceTags'
  | 'generalTags'
  | 'serves'
>;

export interface RecipeGetFromUrlPayload {
  url: string;
  locale: ApiLocale;
}

const recipeSlice = createSlice({
  name: 'recipeSlice',
  initialState,
  reducers: {
    recipeFieldUpdated<T extends AppRecipeFieldUpdateKeys>(
      state: RecipeState,
      {
        payload,
      }: PayloadAction<{
        key: T;
        value: AppRecipe[T];
      }>
    ) {
      const { key, value } = payload;
      state.recipe[key] = value;
      const errors = recipeValidator.validateField(key, value);
      if (errors) {
        state.recipeErrors[key] = errors;
      } else {
        delete state.recipeErrors[key];
      }
    },
    recipeIngredientAdded(
      state,
      { payload }: PayloadAction<AppRecipeIngredient>
    ) {
      const newIngredient = getIngredientWithText(payload);
      state.recipe.ingredients.push(newIngredient);
      startTrackingIngredientUsage(state, newIngredient);
    },
    recipeIngredientUpdated(
      state,
      {
        payload: { index, ingredient },
      }: PayloadAction<{ index: number; ingredient: AppRecipeIngredient }>
    ) {
      const ingredientWithText = getIngredientWithText(ingredient);
      const oldAmount = state.recipe.ingredients[index].quantity.amount;
      const newAmount = ingredientWithText.quantity.amount;
      state.recipe.ingredients[index] = ingredientWithText;

      // Update all the steps where the ingredient is used
      for (const step of state.recipe.steps) {
        if (!step.ingredients) {
          continue;
        }

        for (const stepIngredient of step.ingredients) {
          if (stepIngredient.ingredientIdx !== index) {
            continue;
          }

          const recalculatedAmount = recalculateStepIngredientAmount({
            oldTotalAmount: oldAmount,
            newTotalAmount: newAmount,
            stepAmount: stepIngredient.quantity.amount,
          });
          stepIngredient.ingredient = ingredientWithText;
          stepIngredient.quantity = createQuantity({
            unit: ingredient.quantity.unit,
            amount: recalculatedAmount,
          });
        }
      }

      // Update ingredient usage
      const amountUsed =
        newAmount === null
          ? null
          : calculateIngredientUsedAmount(state.recipe.steps, index);
      const amountLeft =
        newAmount === null
          ? null
          : calculateIngredientLeftAmount(newAmount, amountUsed);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.recipeIngredientsUsage[index]!.used.quantity = createQuantity({
        unit: ingredient.quantity.unit,
        amount: amountUsed,
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.recipeIngredientsUsage[index]!.left = createQuantity({
        unit: ingredient.quantity.unit,
        amount: amountLeft,
      });
    },
    recipeIngredientDeleted(
      state,
      { payload: ingredientIdxToRemove }: PayloadAction<number>
    ) {
      const removeIngredientByIdx = ({
        ingredientIdx,
      }: AppRecipeStepIngredient) => ingredientIdx !== ingredientIdxToRemove;

      const reassignIngredientIdx = (
        ingredient: AppRecipeStepIngredient
      ): AppRecipeStepIngredient => ({
        ...ingredient,
        ingredientIdx:
          ingredient.ingredientIdx > ingredientIdxToRemove
            ? ingredient.ingredientIdx - 1
            : ingredient.ingredientIdx,
      });

      state.recipe.steps = state.recipe.steps.map(
        ({ ingredients, ...rest }) => ({
          ...rest,
          ingredients: ingredients
            ?.filter(removeIngredientByIdx)
            .map(reassignIngredientIdx),
        })
      );

      state.recipe.ingredients.splice(ingredientIdxToRemove, 1);
      state.recipeIngredientsUsage.splice(ingredientIdxToRemove, 1);
    },
    recipeIngredientMoved(
      state,
      { payload: { from, to } }: PayloadAction<{ from: number; to: number }>
    ) {
      const ingredientToMove = state.recipe.ingredients[from];
      state.recipe.ingredients.splice(from, 1);
      state.recipe.ingredients.splice(to, 0, ingredientToMove);

      const ingredientUsageToMove = state.recipeIngredientsUsage[from];
      state.recipeIngredientsUsage.splice(from, 1);
      state.recipeIngredientsUsage.splice(to, 0, ingredientUsageToMove);

      if (!state.recipe.steps.length) {
        return;
      }

      state.recipe.steps.forEach((step) => {
        step.ingredients?.forEach((ingredient) => {
          ingredient.ingredientIdx = calculateIndexAfterReordering(
            ingredient.ingredientIdx,
            from,
            to
          );
        });
      });
    },
    recipeStepAdded(state, { payload }: PayloadAction<AppRecipeStep>) {
      state.recipe.steps.push(payload);
      if (!payload.ingredients?.length) {
        return;
      }
      const stepIndex = state.recipe.steps.length - 1;
      payload.ingredients.forEach((ingredient) =>
        useIngredient(state, payload.id, stepIndex, ingredient)
      );
    },
    recipeStepUpdated(
      state,
      {
        payload: { index, step },
      }: PayloadAction<{ index: number; step: AppRecipeStep }>
    ) {
      const isIngredientRemoved = (
        previousIngredient: AppRecipeStepIngredient
      ) =>
        !step.ingredients?.some(
          (ingredient) =>
            previousIngredient.ingredientIdx === ingredient.ingredientIdx
        );

      const removedIngredients =
        state.recipe.steps[index].ingredients?.filter(isIngredientRemoved);

      state.recipe.steps[index] = step;

      removedIngredients?.forEach((ingredient) =>
        stopUsingIngredient(state, ingredient, index)
      );

      step.ingredients?.forEach((ingredient) =>
        useIngredient(state, step.id, index, ingredient)
      );
    },
    recipeStepDeleted(
      state,
      { payload: stepIdxToRemove }: PayloadAction<number>
    ) {
      const removedIngredients =
        state.recipe.steps[stepIdxToRemove].ingredients;

      state.recipe.steps.splice(stepIdxToRemove, 1);

      removedIngredients?.forEach((ingredient) =>
        stopUsingIngredient(state, ingredient, stepIdxToRemove)
      );
    },
    recipeStepMoved(
      state,
      { payload: { from, to } }: PayloadAction<{ from: number; to: number }>
    ) {
      const stepToMove = state.recipe.steps[from];
      state.recipe.steps.splice(from, 1);
      state.recipe.steps.splice(to, 0, stepToMove);
      state.recipeIngredientsUsage.forEach((usage) => {
        usage.used.steps = usage.used.steps
          .map((step) => reassignUsedStepsIndexes(step, state.recipe.steps))
          .sort(sortUsedSteps);
      });
    },
    recipeReset() {
      return initialState;
    },
    recipeFetchRequested(state, { payload }: PayloadAction<string>) {
      state.apiError = undefined;
      state.hasUnsavedChanges = false;
      state.submitted = false;
      if (state.recipe.id !== payload) {
        state.recipe = initialState.recipe;
        state.fetching = true;
      }
    },
    recipeFetchFailed(state, { payload }: PayloadAction<string>) {
      state.apiError = payload;
      state.fetching = false;
    },
    recipeFetchSucceed(state, { payload: recipe }: PayloadAction<AppRecipe>) {
      state.fetching = false;
      state.pristineRecipe = recipe;
      setRecipe(state, recipe);
    },
    recipeSaving(state) {
      state.saving = true;
      state.apiError = undefined;
    },
    recipeSaveFailed(state, { payload }: PayloadAction<string>) {
      state.saving = false;
      state.apiError = payload;
    },
    recipeSaveFinished(
      state,
      {
        payload: { success, savedAt },
      }: PayloadAction<{ success: boolean; savedAt: number }>
    ) {
      state.saving = false;
      if (success) {
        state.hasUnsavedChanges = false;
        state.lastSavedAt = savedAt;
        state.pristineRecipe = state.recipe;
      }
    },
    recipeSubmitted(state) {
      state.submitted = true;
    },
    recipePublishing(state) {
      state.publishing = true;
      state.apiError = undefined;
    },
    recipePublishFailed(state, { payload }: PayloadAction<string>) {
      state.publishing = false;
      state.apiError = payload;
    },
    recipePublishSucceed(state) {
      state.publishing = false;
      state.recipe.state = ApiRcpRecipeState.Published;
      state.pristineRecipe = state.recipe;
    },
    recipeUnpublishing(state) {
      state.unpublishing = true;
      state.apiError = undefined;
    },
    recipeUnpublishFailed(state, { payload }: PayloadAction<string>) {
      state.unpublishing = false;
      state.apiError = payload;
    },
    recipeUnpublishSucceed(state) {
      state.unpublishing = false;
      state.recipe.state = ApiRcpRecipeState.Draft;
      state.pristineRecipe = state.recipe;
    },
    recipeGetFromUrlRequested(
      state,
      _action: PayloadAction<RecipeGetFromUrlPayload>
    ) {
      state.apiError = undefined;
      state.recipe = initialState.recipe;
      state.fetching = true;
    },
    recipeGetFromTextRequested(
      state,
      _action: PayloadAction<ApiGetRecipeFromTextRequest>
    ) {
      state.apiError = undefined;
      state.recipe = initialState.recipe;
      state.fetching = true;
    },
    recipeFromScratchRequested(state, { payload }: PayloadAction<ApiLocale>) {
      const recipeState: AppRecipe = {
        ...initialState.recipe,
        locale: payload,
      };
      return { ...initialState, recipe: recipeState };
    },
    recipeHasChanged(
      state,
      {
        payload: { hasChanges, changedAt },
      }: PayloadAction<{ hasChanges: boolean; changedAt: number }>
    ) {
      if (!state.hasUnsavedChanges && hasChanges) {
        state.lastSavedAt = changedAt;
      }
      state.hasUnsavedChanges = hasChanges;
    },
    recipeDiscardChanges(state) {
      state.hasUnsavedChanges = false;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setRecipe(state, state.pristineRecipe!);
    },
    recipePublishConfirmationRequired(state) {
      state.publishConfirmationRequired = true;
    },
    recipePublishConfirmed(state) {
      state.publishConfirmationRequired = false;
    },
    recipePublishCanceled(state) {
      state.publishConfirmationRequired = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSignOutFinished, () => initialState);
  },
});

const getIngredientWithText = (
  ingredient: AppRecipeIngredient
): AppRecipeIngredient =>
  produce(ingredient, (draft) => {
    draft.quantity.text = getQuantityAsText(ingredient.quantity);
  });

export const {
  reducer: recipeReducer,
  actions: {
    recipeIngredientAdded,
    recipeIngredientUpdated,
    recipeIngredientDeleted,
    recipeIngredientMoved,
    recipeStepAdded,
    recipeStepUpdated,
    recipeStepDeleted,
    recipeStepMoved,
    recipeFetchRequested,
    recipeFetchFailed,
    recipeFetchSucceed,
    recipeReset,
    recipeSaving,
    recipeSaveFailed,
    recipeSubmitted,
    recipeFieldUpdated,
    recipePublishing,
    recipePublishFailed,
    recipePublishSucceed,
    recipeUnpublishing,
    recipeUnpublishFailed,
    recipeUnpublishSucceed,
    recipeGetFromUrlRequested,
    recipeGetFromTextRequested,
    recipeFromScratchRequested,
    recipeDiscardChanges,
    recipePublishConfirmationRequired,
    recipePublishConfirmed,
    recipePublishCanceled,
  },
} = recipeSlice;

export const recipeSaveRequested = createAction<string | undefined>(
  'recipeSlice/recipeSaveRequested'
);

export const recipeAutoSaveRequested = createAction<string>(
  'recipeSlice/recipeAutoSaveRequested'
);

export const recipeSaveBeforeLeavingRequested = createAction<string>(
  'recipeSlice/recipeSaveBeforeLeavingRequested'
);

export const recipePublishRequested = createAction<string>(
  'recipeSlice/recipePublishRequested'
);

export const recipeUnpublishRequested = createAction<string>(
  'recipeSlice/recipeUnpublishRequested'
);

export const recipeSaveFinished = createAction(
  'recipeSlice/recipeSaveFinished',
  function prepare({ success }: { success: boolean }) {
    return {
      payload: {
        success,
        savedAt: getCurrentTime(),
      },
    };
  }
);

export const recipeHasChanged = createAction(
  'recipeSlice/recipeHasChanged',
  function prepare(hasChanges: boolean) {
    return {
      payload: {
        hasChanges,
        changedAt: getCurrentTime(),
      },
    };
  }
);

const selectRecipeState = (state: RootState): RecipeState => state.recipe;

export const selectRecipe = (state: RootState): AppRecipe =>
  selectRecipeState(state).recipe;

export const selectRecipeLocale = (state: RootState): ApiLocale =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  selectRecipe(state).locale!;

export const selectRecipeIngredients = (
  state: RootState
): AppRecipeIngredient[] => selectRecipe(state).ingredients;

export const selectRecipeIngredient =
  (index: number | null) =>
  (state: RootState): AppRecipeIngredient | undefined =>
    typeof index === 'number'
      ? selectRecipe(state).ingredients[index]
      : undefined;

export const selectRecipeIngredientsUsage = (
  state: RootState
): AppRecipeIngredientsUsage[] =>
  selectRecipeState(state).recipeIngredientsUsage;

export const selectRecipeIngredientUsage =
  (index: number | null) =>
  (state: RootState): AppRecipeIngredientsUsage | undefined =>
    typeof index === 'number'
      ? selectRecipeState(state).recipeIngredientsUsage[index]
      : undefined;

export const selectRecipeSteps = (state: RootState): AppRecipeStep[] =>
  selectRecipe(state).steps;

export const selectRecipeStep =
  (index: number | null) =>
  (state: RootState): AppRecipeStep | undefined =>
    typeof index === 'number' ? selectRecipe(state).steps[index] : undefined;

export const selectRecipeSaving = (state: RootState): boolean =>
  selectRecipeState(state).saving;

export const selectRecipeHasUnsavedChanges = (state: RootState): boolean =>
  selectRecipeState(state).hasUnsavedChanges;

export const selectRecipePublishConfirmationRequired = (
  state: RootState
): boolean => !!selectRecipeState(state).publishConfirmationRequired;

export const selectRecipeFetching = (state: RootState): boolean =>
  selectRecipeState(state).fetching;

export const selectRecipePublishing = (state: RootState): boolean =>
  selectRecipeState(state).publishing;

export const selectRecipeUnpublishing = (state: RootState): boolean =>
  selectRecipeState(state).unpublishing;

export const selectRecipeErrors = (state: RootState): ValidatorErrors =>
  selectRecipeState(state).recipeErrors;

export const selectRecipeHasErrors = createSelector(
  selectRecipeErrors,
  (errors): boolean => !!Object.values(errors).length
);

export const selectRecipeSubmitted = (state: RootState): boolean =>
  selectRecipeState(state).submitted;

export const selectRecipeApiError = (state: RootState): string | undefined =>
  selectRecipeState(state).apiError;

export const selectRecipeLastSavedAt = (state: RootState): number | undefined =>
  selectRecipeState(state).lastSavedAt;

const getIngredientUsageDetails = (
  { used, left }: AppRecipeIngredientsUsage,
  stepIndex: number | null
) => {
  const isNewStep = stepIndex === null;
  const hasLeft = !!left.amount;
  const hasLimit = used.quantity.amount !== null;
  const isUsed = !!used.quantity.amount;
  const isUsedByCurrentStep = used.steps.some(
    ({ index }) => index === stepIndex
  );
  const isUsedByManySteps = used.steps.length > 1;
  return {
    hasLeft,
    hasLimit,
    isNewStep,
    isUsed,
    isUsedByCurrentStep,
    isUsedByManySteps,
  };
};

export const selectAvailableIngredientsByStep = (stepIndex: number | null) =>
  createSelector(
    selectRecipeIngredients,
    selectRecipeIngredientsUsage,
    selectIngredientAmountUsedByOtherSteps(stepIndex),
    (
      ingredients,
      ingredientsUsage,
      amountUsedByOtherSteps
    ): AppRecipeStepIngredient[] => {
      return ingredients
        .map((ingredient, ingredientIdx) => {
          const { amount, unit } = ingredient.quantity;
          const amountUsed = amountUsedByOtherSteps[ingredientIdx] || 0;
          return {
            ingredientIdx,
            ingredient,
            quantity: {
              amount: amount === null ? null : round(amount - amountUsed, 3),
              unit,
            },
          };
        })
        .filter(({ ingredientIdx }) => {
          const { isUsed, hasLeft, isUsedByCurrentStep } =
            getIngredientUsageDetails(
              ingredientsUsage[ingredientIdx],
              stepIndex
            );
          return !isUsed || hasLeft || isUsedByCurrentStep;
        });
    }
  );

export const selectUsedIngredientsByStep = (stepIndex: number | null) =>
  createSelector(
    selectRecipeIngredients,
    selectRecipeIngredientsUsage,
    (ingredients, ingredientsUsage): AppRecipeStepIngredient[] => {
      return ingredients
        .map((ingredient, ingredientIdx) => {
          const {
            isUsed,
            isNewStep,
            hasLimit,
            isUsedByCurrentStep,
            isUsedByManySteps,
          } = getIngredientUsageDetails(
            ingredientsUsage[ingredientIdx],
            stepIndex
          );
          return {
            ingredientIdx,
            ingredient,
            quantity: ingredientsUsage[ingredientIdx].used.quantity,
            isUsed:
              (isUsed || !hasLimit) &&
              (isNewStep || isUsedByManySteps || !isUsedByCurrentStep),
          };
        })
        .filter(({ isUsed }) => isUsed);
    }
  );

export const selectRecipeHasUnusedIngredients = createSelector(
  selectRecipeIngredientsUsage,
  (ingredientsUsage): boolean => {
    const {
      publish: { unusedIngredientThreshold },
    } = recipePageConstants;
    return ingredientsUsage.some(({ left, used }) => {
      if (left.amount === null) {
        return !used.steps.length;
      }
      return left.amount > unusedIngredientThreshold;
    });
  }
);

export const selectIngredientsByStep = (stepIndex: number | null) =>
  createSelector(
    selectAvailableIngredientsByStep(stepIndex),
    selectUsedIngredientsByStep(stepIndex),
    (available, used) => [...available, ...used]
  );

export const selectIngredientAmountUsedByOtherSteps = (
  stepIndex: number | null
) =>
  createSelector(selectRecipeSteps, (steps) => {
    return steps
      .filter((_, index) => index !== stepIndex)
      .flatMap(({ ingredients }) => ingredients || [])
      .reduce(
        (amountUsedByOtherSteps, { quantity: { amount }, ingredientIdx }) => {
          if (amount === null) {
            return amountUsedByOtherSteps;
          }
          return {
            ...amountUsedByOtherSteps,
            [ingredientIdx]:
              amount + (amountUsedByOtherSteps[ingredientIdx] || 0),
          };
        },
        {} as { [ingredientIdx: number]: number }
      );
  });

const startTrackingIngredientUsage = (
  state: RecipeState,
  ingredient: AppRecipeIngredient
) => {
  state.recipeIngredientsUsage.push({
    left: {
      amount: ingredient.quantity.amount,
      unit: ingredient.quantity.unit,
    },
    used: {
      steps: [],
      quantity: createQuantity({
        unit: ingredient.quantity.unit,
        amount: 0,
      }),
    },
  });
};

const calculateIngredientUsedAmount = (
  steps: AppRecipeStep[],
  ingredientIdx: number
): number => {
  return steps
    .flatMap(({ ingredients }) => ingredients)
    .filter((ingredient) => ingredient?.ingredientIdx === ingredientIdx)
    .reduce(
      (totalQuantity, ingredient) =>
        totalQuantity + (ingredient?.quantity.amount ?? 0),
      0
    );
};

const calculateIngredientLeftAmount = (
  availableAmount: number,
  usedAmount: number | null
): number | null => {
  return availableAmount - (usedAmount ?? 0);
};

const updateIngredientUsage = (
  state: RecipeState,
  steps: AppRecipeIngredientsUsageStep[],
  { ingredientIdx, ingredient, quantity }: AppRecipeStepIngredient
) => {
  const usedQuantity = createQuantity({
    unit: quantity.unit,
    amount:
      ingredient.quantity.amount !== null
        ? calculateIngredientUsedAmount(state.recipe.steps, ingredientIdx)
        : null,
  });

  state.recipeIngredientsUsage[ingredientIdx] = {
    left: {
      amount:
        ingredient.quantity.amount !== null
          ? calculateIngredientLeftAmount(
              ingredient.quantity.amount,
              usedQuantity.amount
            )
          : null,
      unit: ingredient.quantity.unit,
    },
    used: {
      steps,
      quantity: usedQuantity,
    },
  };
};

const useIngredient = (
  state: RecipeState,
  stepId: string,
  stepIndex: number,
  ingredient: AppRecipeStepIngredient
) => {
  const { ingredientIdx } = ingredient;
  const steps: AppRecipeIngredientsUsageStep[] = [
    ...state.recipeIngredientsUsage[ingredientIdx].used.steps.filter(
      ({ index }) => index !== stepIndex
    ),
    { id: stepId, index: stepIndex },
  ].sort(sortUsedSteps);

  updateIngredientUsage(state, steps, ingredient);
};

const stopUsingIngredient = (
  state: RecipeState,
  ingredient: AppRecipeStepIngredient,
  stepIndex: number
) => {
  const { ingredientIdx } = ingredient;
  if (state.recipeIngredientsUsage[ingredientIdx].used.steps.length === 1) {
    const usedQuantity = createQuantity({
      unit: ingredient.ingredient.quantity.unit,
      amount: 0,
    });
    state.recipeIngredientsUsage[ingredientIdx] = {
      left: {
        amount: ingredient.ingredient.quantity.amount,
        unit: ingredient.ingredient.quantity.unit,
      },
      used: {
        steps: [],
        quantity: usedQuantity,
      },
    };
  } else {
    const steps = state.recipeIngredientsUsage[ingredientIdx].used.steps.filter(
      ({ index }) => index !== stepIndex
    );
    updateIngredientUsage(state, steps, ingredient);
  }
};

const sortUsedSteps = (
  step: AppRecipeIngredientsUsageStep,
  anotherStep: AppRecipeIngredientsUsageStep
) => step.index - anotherStep.index;

const reassignUsedStepsIndexes = (
  { id }: AppRecipeIngredientsUsageStep,
  steps: AppRecipeStep[]
) => ({
  id,
  index: steps.findIndex((step) => step.id === id),
});

interface RecalculateStepIngredientAmountParams {
  oldTotalAmount: number | null;
  newTotalAmount: number | null;
  stepAmount: number | null;
}
export const recalculateStepIngredientAmount = ({
  oldTotalAmount,
  newTotalAmount,
  stepAmount,
}: RecalculateStepIngredientAmountParams): number | null => {
  if (oldTotalAmount === null && newTotalAmount !== null) {
    return 0;
  }

  if (oldTotalAmount === null || newTotalAmount === null) {
    return null;
  }

  return stepAmount;
};

interface CreateQuantityParams {
  unit: ApiQuantityUnit;
  amount: number | null;
}
export const createQuantity = ({
  unit,
  amount,
}: CreateQuantityParams): AppRecipeIngredientQuantity => ({
  unit,
  amount,
  text: getQuantityAsText({
    unit,
    amount,
  }),
});

const setRecipe = (state: RecipeState, recipe: AppRecipe) => {
  state.recipe = recipe;
  state.recipeIngredientsUsage = [];
  recipe.ingredients.forEach((ingredient) =>
    startTrackingIngredientUsage(state, ingredient)
  );
  recipe.steps.forEach(({ id, ingredients }, index) => {
    if (!ingredients?.length) {
      return;
    }
    ingredients.forEach((ingredient) =>
      useIngredient(state, id, index, ingredient)
    );
  });
  state.recipeErrors = recipeValidator.validate({ ...recipe }) || {};
};
