import { useEffect } from 'react';

export const useBeforeUnload = ({
  isEnabled,
  onBeforeUnload,
}: {
  isEnabled: boolean;
  onBeforeUnload: () => void;
}) => {
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      onBeforeUnload();
    };

    if (isEnabled) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isEnabled, onBeforeUnload]);
};
