import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { FC } from 'react';
import { useState, memo, useEffect, useCallback } from 'react';
import { string } from 'yup';

import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { recipeStepTextFieldStrings } from 'features/recipe/shared/RecipeStepTextField/RecipeStepTextField.constants';
import type { AppRecipeFieldChange } from 'features/recipe/shared/types/appRecipeFieldChange';
import type { ValidatorError } from 'utils/validator';
import { Validator } from 'utils/validator';

const { placeholder, label, errorMessages } = recipeStepTextFieldStrings;

const formValidator = new Validator({
  text: string().required(errorMessages.required),
});

export interface RecipeStepTextFieldProps
  extends Pick<TextFieldProps, 'id' | 'autoFocus'> {
  value?: string;
  showErrors?: boolean;
  hideLabel?: boolean;
  onChange: (change: AppRecipeFieldChange) => void;
}

export const RecipeStepTextField: FC<RecipeStepTextFieldProps> = memo(
  function RecipeStepTextField({
    id,
    autoFocus,
    showErrors = true,
    hideLabel,
    value,
    onChange,
  }) {
    const [errors, setErrors] = useState<ValidatorError | undefined>();

    const handleTextChange = useCallback(
      (text = '') => {
        const currentErrors = formValidator.validate({ text });
        setErrors(currentErrors?.text);
        onChange({ value: text, errors: currentErrors?.text });
      },
      [onChange]
    );

    useEffect(() => {
      handleTextChange(value);
    }, [handleTextChange, value, showErrors]);

    return (
      <TextField
        id={id}
        label={hideLabel ? undefined : label}
        placeholder={placeholder}
        type="text"
        variant="outlined"
        multiline
        maxRows="6"
        fullWidth
        required
        autoFocus={autoFocus}
        value={value}
        error={showErrors && !!errors}
        helperText={
          showErrors &&
          !!errors && (
            <HelperText
              message={errorMessages.required}
              severity={HelperTextSeverity.Critical}
            />
          )
        }
        onChange={(event) => handleTextChange(event.target.value)}
        inputProps={{ 'aria-label': label }}
      />
    );
  }
);
