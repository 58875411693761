import { Button, ButtonStyle, ButtonSize } from '@dropkitchen/pantry-react';
import { Grid, Box } from '@mui/material';
import type { FC, ReactElement } from 'react';
import { memo } from 'react';

import { useAppSelector } from 'app/store/hooks';
import { ButtonWithSkeleton } from 'components/ButtonWithSkeleton/ButtonWithSkeleton';
import { tabsListConstants } from 'components/Tab/TabsList.constants';
import { headerConstants } from 'features/layout/Header.constants';
import { selectRecipeFetching } from 'features/recipe/recipeSlice';
import { RecipePageListSkeleton } from 'features/recipe/shared/RecipePageListSkeleton/RecipePageListSkeleton';
import { RecipeTabContainer } from 'features/recipe/shared/RecipeTabContainer/RecipeTabContainer';
import { recipeTabContainerStyle } from 'features/recipe/shared/RecipeTabContainer/RecipeTabContainer.constants';

export interface RecipeListWithFormContainerProps {
  title: string;
  components: {
    list: ReactElement;
    form: ReactElement | null;
  };
  buttons: {
    add: {
      label: string;
      onClick: () => void;
    };
  };
}

const formTopOffset =
  headerConstants.height + tabsListConstants.tab.condensed.height + 24;

export const RecipePageListWithFormContainer: FC<RecipeListWithFormContainerProps> =
  memo(function RecipePageListWithFormContainer({
    title,
    buttons,
    components,
  }) {
    const isFetching = useAppSelector(selectRecipeFetching);

    return (
      <Grid container columnSpacing={8}>
        <Grid item md={8}>
          <RecipeTabContainer title={{ string: title }}>
            {isFetching ? (
              <RecipePageListSkeleton title={title} />
            ) : (
              components.list
            )}
            <ButtonWithSkeleton
              isLoading={isFetching}
              button={
                <Button
                  label={buttons.add.label}
                  buttonStyle={ButtonStyle.Default}
                  size={ButtonSize.Large}
                  sx={{ mt: 6 }}
                  onClick={buttons.add.onClick}
                />
              }
            />
          </RecipeTabContainer>
        </Grid>
        {components.form && (
          <Grid item md={4}>
            <Box
              sx={{
                ...recipeTabContainerStyle,
                pt: 3,
                position: 'sticky',
                top: formTopOffset,
                overflowY: 'auto',
                maxHeight: `calc(100vh - ${formTopOffset}px)`,
              }}
            >
              {components.form}
            </Box>
          </Grid>
        )}
      </Grid>
    );
  });
