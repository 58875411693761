import {
  Button,
  ButtonSize,
  ButtonStyle,
  PantryColor,
  SearchIcon,
} from '@dropkitchen/pantry-react';
import { Box, TextField, inputBaseClasses } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useRef, useState, memo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import {
  recipesSearchReset,
  recipesSearchTermUpdated,
  selectIsAdvancedSearchPanelOpen,
  selectRecipesAppliedFilters,
  selectRecipesSearchTerm,
} from 'features/recipes/recipesSlice';
import { RecipesAdvancedSearch } from 'features/recipes/search/RecipesAdvancedSearch/RecipesAdvancedSearch';
import {
  recipeSearchConstants,
  recipesSearchStrings,
} from 'features/recipes/search/RecipesSearch.constants';

const { buttons, placeholders } = recipesSearchStrings;
const { width } = recipeSearchConstants;

export const RecipesSearch: FC = memo(function RecipesSearch() {
  const dispatch = useAppDispatch();

  const searchTerm = useAppSelector(selectRecipesSearchTerm);
  const isAdvancedSearchPanelOpen = useAppSelector(
    selectIsAdvancedSearchPanelOpen
  );
  const appliedFilters = useAppSelector(selectRecipesAppliedFilters);

  const ref = useRef<HTMLDivElement | null>(null);
  const [advancedSearchAnchorEl, setAdvancedSearchAnchorEl] =
    useState<Element | null>(null);

  const hasAppliedFilters = !!appliedFilters.length;

  const isClearButtonShowing = !!searchTerm || hasAppliedFilters;

  useEffect(() => {
    setAdvancedSearchAnchorEl(ref.current);
  }, []);

  return (
    <TextField
      ref={ref}
      InputProps={{
        endAdornment: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {isClearButtonShowing && (
              <Button
                label={buttons.clear}
                buttonStyle={ButtonStyle.Subtle}
                size={ButtonSize.Small}
                onClick={() => dispatch(recipesSearchReset())}
                sx={{ flexShrink: 0 }}
              />
            )}
            {isClearButtonShowing && (
              <Box component="span" sx={{ color: PantryColor.TextMuted }}>
                |
              </Box>
            )}
            {advancedSearchAnchorEl && (
              <RecipesAdvancedSearch
                anchorEl={advancedSearchAnchorEl}
                sx={{ width }}
              />
            )}
          </Box>
        ),
        startAdornment: (
          <SearchIcon
            size={24}
            color={PantryColor.IconDefault}
            sx={{ mr: 2 }}
          />
        ),
        style: { paddingLeft: '16px', paddingRight: '16px' },
      }}
      onChange={(e) => {
        dispatch(recipesSearchTermUpdated(e.target.value));
      }}
      placeholder={placeholders.textField}
      sx={{
        backgroundColor: PantryColor.SurfaceDefault,
        width,

        [`& .${inputBaseClasses.root}`]: {
          '& fieldset': {
            ...(isAdvancedSearchPanelOpen && {
              borderColor: PantryColor.FrescoPrimary,
            }),
          },
        },
      }}
      value={searchTerm}
      variant="outlined"
    />
  );
});
