export const capabilityFieldParams = {
  minPlaceholder: 'minValue',
  maxPlaceholder: 'maxValue',
  stepPlaceholder: 'stepValue',
  unitAbbreviationPlaceholder: 'unitAbbreviation',
};

export const capabilityFieldStrings = {
  labels: {
    nominalRadioButton: 'Nominal',
    numericRadioButton: 'Numeric',
    generalCapabilityField: 'Capability',
    presetCapabilityField: 'Preset',
    applianceField: 'Appliance',
    phaseField: 'Phase',
    capabilityTypeRadio: {
      group: 'Choose capability type',
      general: 'General',
      appliancePreset: 'Appliance Preset',
    },
    settingsSectionTitle: 'Settings',
  },
  placeholders: {
    generalCapabilityField: 'Select capability',
    presetCapabilityField: 'Select preset',
    phaseField: 'Select phase',
  },
  errors: {
    numericSetting: {
      min: `Enter a value greater than ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      max: `Enter a value lower than ${capabilityFieldParams.maxPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      minAndMax: `Enter a value between ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder} and ${capabilityFieldParams.maxPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder}`,
      step: `Enter a multiple of ${capabilityFieldParams.stepPlaceholder}`,
      stepOf1: 'Enter a whole number',
      stepWithMin: `Enter a value greater than ${capabilityFieldParams.minPlaceholder}${capabilityFieldParams.unitAbbreviationPlaceholder} in steps of ${capabilityFieldParams.stepPlaceholder}`,
    },
    timeSetting: {
      min: `Enter a value greater than ${capabilityFieldParams.minPlaceholder}`,
      max: `Enter a value lower than ${capabilityFieldParams.maxPlaceholder}`,
      minAndMax: `Enter a value between ${capabilityFieldParams.minPlaceholder} and ${capabilityFieldParams.maxPlaceholder}`,
      step: `Enter a multiple of ${capabilityFieldParams.stepPlaceholder}`,
      stepWithMin: `Enter a value greater than ${capabilityFieldParams.minPlaceholder} in steps of ${capabilityFieldParams.stepPlaceholder}`,
    },
  },
  helpers: {
    attachments: {
      message: 'This capability needs the following attachments',
      optional: 'Optional',
    },
  },
} as const;
