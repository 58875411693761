/* istanbul ignore file */
import appPackageJson from '../../package.json';

export enum AppEnvironment {
  Development = 'development',
  Production = 'production',
  Stage = 'stage',
  Test = 'test',
}

const packageJsonValues = {
  version: (): string => appPackageJson.version,
  appName: (): string => appPackageJson.name,
};

const envVarsGetters = {
  /**
   * Environment where the application is running.
   */
  environment: (): AppEnvironment =>
    process.env.REACT_APP_ENV as AppEnvironment,
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  /**
   * KitchenOS API gateway url
   */
  apiGatewayUrl: (): string => process.env.REACT_APP_API_URL!,
  /**
   * KitchenOS API media url
   */
  apiMediaUrl: (): string => process.env.REACT_APP_API_MEDIA_URL!,
  /**
   * Appliances config url
   */
  configsUrl: (): string => process.env.REACT_APP_CONFIGS_URL!,
  /**
   * Amazon Cognito Region
   */
  region: (): string => process.env.REACT_APP_COGNITO_REGION!,
  /**
   * Amazon Cognito User Pool ID
   */
  userPoolId: (): string => process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  /**
   * Amazon Cognito Web Client ID
   */
  userPoolWebClientId: (): string =>
    process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID!,
  /**
   * Amazon Cognito Web Client ID
   */
  organizationId: (): string => process.env.REACT_APP_ORGANIZATION_ID!,
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

const isEnv = (...args: AppEnvironment[]) => {
  const environment = envVarsGetters.environment();
  return args.includes(environment);
};

const isDevOrTestEnv = () =>
  isEnv(AppEnvironment.Development, AppEnvironment.Test);
const isProdEnv = () => isEnv(AppEnvironment.Production);
const isTestEnv = () => isEnv(AppEnvironment.Test);

const referenceDataApiPageSize = 500;

export const appConfig = {
  ...envVarsGetters,
  ...packageJsonValues,
  isEnv,
  isDevOrTestEnv,
  isProdEnv,
  isTestEnv,
  referenceDataApiPageSize,
};
