import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { BackToTopButton } from 'components/BackToTopButton/BackToTopButton';
import { Header } from 'features/layout/Header';

export const PageLayout: FC = memo(function PageLayout() {
  return (
    <Box pb={13.125}>
      <Header />
      <Outlet />
      <BackToTopButton />
    </Box>
  );
});
