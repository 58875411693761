import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRefEntity } from 'api/types/referenceData/apiRefEntity';
import type { AppCapability } from 'types/appCapability';

export const referenceDataIdToHumanText = (id: ApiEntityId) =>
  id.replace('cckg:', '').replace(/[A-Z]/g, ' $&').toLocaleLowerCase().slice(1);

export type AppRefEntityDisplayName = Record<ApiEntityId, string>;

export const disambiguateTerms = <T extends ApiRefEntity | AppCapability>(
  terms: T[]
): AppRefEntityDisplayName => {
  const dictionary = terms.reduce((names, { name }) => {
    if (!names[name]) {
      names[name] = 0;
    }
    names[name]++;
    return names;
  }, {} as Record<string, number>);

  return terms.reduce((disambiguatedTerms, { id, name }) => {
    let displayName = name;
    if (dictionary[name] > 1) {
      // There are more terms with the same name. Adding ID to disambiguate
      const idText = referenceDataIdToHumanText(id);
      const disambiguationText = idText === name ? '' : ` - ${idText}`;
      displayName = `${name}${disambiguationText}`;
    }
    disambiguatedTerms[id] = displayName;
    return disambiguatedTerms;
  }, {} as AppRefEntityDisplayName);
};
