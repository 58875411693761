import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '@dropkitchen/pantry-react';
import { useTheme } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Dialog } from 'components/Dialog/Dialog';
import { errorDialogStrings } from 'features/error/ErrorDialog.constants';
import { errorDismissed, selectErrors } from 'features/error/errorSlice';

const { title, labels } = errorDialogStrings;

export const ErrorDialog: FC = function ErrorDialog() {
  const dispatch = useAppDispatch();
  const errors = useAppSelector(selectErrors);
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (errors.length) {
      setIsOpen(true);
    }
  }, [errors]);

  useEffect(() => {
    if (isOpen || !errors.length) {
      return;
    }
    /** Wait for the close animation to finish so the error message doesn't disappear from the modal while it's being closed */
    const timeoutId = setTimeout(
      () => dispatch(errorDismissed(0)),
      theme.transitions.duration.leavingScreen
    );
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      hasCloseButton={false}
      onClose={() => {}}
      onSubmit={() => setIsOpen(false)}
      size="sm"
      components={{
        content: errors[0],
        buttons: (
          <Button
            label={labels.okButton}
            type={ButtonType.Submit}
            buttonStyle={ButtonStyle.Fresco}
            size={ButtonSize.Large}
          />
        ),
      }}
    />
  );
};
