import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/store/rootReducer';
import type { AppAppliance } from 'types/appliance/appAppliance';

export interface AppliancesState {
  apiFetchError?: string;
  fetching: boolean;
  appliances: AppAppliance[];
}

export const initialState: AppliancesState = {
  fetching: false,
  appliances: [],
};

export const appliancesSlice = createSlice({
  name: 'appliancesSlice',
  initialState,
  reducers: {
    appliancesFetching(state) {
      state.apiFetchError = undefined;
      state.fetching = true;
    },
    appliancesFetchSucceed(state, { payload }: PayloadAction<AppAppliance[]>) {
      state.fetching = false;
      state.appliances = payload;
    },
    appliancesFetchFailed(state, { payload }: PayloadAction<string>) {
      state.apiFetchError = payload;
      state.fetching = false;
    },
  },
});

export const appliancesFetchRequested = createAction(
  'appliancesSlice/appliancesFetchRequested'
);

export const {
  reducer: appliancesReducer,
  actions: {
    appliancesFetching,
    appliancesFetchSucceed,
    appliancesFetchFailed,
  },
} = appliancesSlice;

export const selectAppliances = (state: RootState): AppAppliance[] =>
  state.appliances.appliances;

export const selectAppliancesFetching = (state: RootState): boolean =>
  state.appliances.fetching;

export const selectShouldFetchAppliances = createSelector(
  selectAppliancesFetching,
  selectAppliances,
  (fetching, appliances) => !fetching && !appliances.length
);
