import type { ApiAplApplianceModule } from 'api/types/appliance/apiAplApplianceModule';
import type { ApiAplCapability } from 'api/types/appliance/apiAplCapability';
import type { ApiAplCapabilityType } from 'api/types/appliance/apiAplCapabilityType';
import type { AppCapability } from 'types/appCapability';
import { fromApiAplCapability } from 'types/appCapability';

export interface AppApplianceModule {
  capabilities: AppCapability[];
}

export const fromApiAplApplianceModule = (
  applianceModule: ApiAplApplianceModule,
  supportedApplianceCapabilityTypes: ApiAplCapabilityType[]
): AppApplianceModule => ({
  capabilities: applianceModule.capabilities
    .filter((capability) =>
      isCapabilityTypeSupported(capability, supportedApplianceCapabilityTypes)
    )
    .map(fromApiAplCapability),
});

const isCapabilityTypeSupported = (
  { type }: ApiAplCapability,
  supportedApplianceCapabilityTypes: ApiAplCapabilityType[]
) => supportedApplianceCapabilityTypes.includes(type);
