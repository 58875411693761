// This rule is triggered false positive because there are import and module in same file
/* eslint-disable import/no-import-module-exports */

import { datadogRum } from '@datadog/browser-rum';
import { configureStore } from '@reduxjs/toolkit';
import type { History } from 'history';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import { rootSaga } from 'app/store/rootSaga';

import { createRootReducer } from './rootReducer';
import type { RootState } from './rootReducer';

interface CreateStoreArgs {
  history: History;
  preloadedState?: RootState;
  /**
   * For testing purposes
   */
  disableSagaMiddleware?: boolean;
}

export const createStore = ({
  history,
  preloadedState,
  disableSagaMiddleware,
}: CreateStoreArgs) => {
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history,
    });

  const rootReducer = createRootReducer(routerReducer);
  const sagaMiddleware = createSagaMiddleware({
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error('Unhandled redux-saga error:', e);
      datadogRum.addError(e);
    },
  });

  const store = configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
        immutableCheck: !process.env.CI,
      }).concat([routerMiddleware, sagaMiddleware]),
  });

  if (!disableSagaMiddleware) {
    sagaMiddleware.run(rootSaga);
  }

  const reduxHistory = createReduxHistory(store);

  return {
    store,
    reduxHistory,
  };
};

export const { store: appStore, reduxHistory: appHistory } = createStore({
  history: createBrowserHistory(),
});

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development' && module.hot) {
  /* eslint-disable */
  module.hot.accept('./rootReducer', () => {
    const newRootReducer =
      require('./rootReducer').createRootReducer(appHistory);
    appStore.replaceReducer(newRootReducer);
  });
  /* eslint-enable */
}

export type AppDispatch = typeof appStore.dispatch;
