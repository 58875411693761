import { Badge, BadgeSize } from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { getStatusColor } from 'components/Badge/Badge.utils';

export interface StatusBadgeProps {
  status: ApiRcpRecipeState;
  text: string;
  sx?: SxProps;
}
export const StatusBadge: FC<StatusBadgeProps> = memo(function StatusBadge({
  status,
  sx,
  text,
}) {
  return (
    <Badge size={BadgeSize.Small} status={getStatusColor(status)} sx={sx}>
      {text}
    </Badge>
  );
});
