import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ApiIngredient } from 'api/types/common/apiIngredient';
import type { ApiIngredientPreparation } from 'api/types/common/apiIngredientPreparation';
import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { RootState } from 'app/store/rootReducer';
import { authSignOutFinished } from 'features/auth/authSlice';
import {
  recipeDiscardChanges,
  recipeFetchRequested,
  recipeFromScratchRequested,
  recipeGetFromTextRequested,
  recipeGetFromUrlRequested,
  recipeReset,
} from 'features/recipe/recipeSlice';

export interface RecipeIngredientFormState {
  ingredient: ApiIngredient | null;
  amount: string;
  unit: ApiQuantityUnit | null;
  ingredientPreparations: ApiIngredientPreparation[];
  index: number | null;
  isUnsaved: boolean;
  isShowing: boolean;
}

export const initialState: RecipeIngredientFormState = {
  ingredient: null,
  amount: '',
  unit: null,
  ingredientPreparations: [],
  index: null,
  isUnsaved: false,
  isShowing: true,
};

const resetStateActions = [
  authSignOutFinished.type,
  recipeReset.type,
  recipeFetchRequested.type,
  recipeFromScratchRequested.type,
  recipeGetFromTextRequested.type,
  recipeGetFromUrlRequested.type,
];
const shouldResetState = (action: Action<string>) =>
  resetStateActions.includes(action.type);

export const recipeIngredientFormSlice = createSlice({
  name: 'recipeIngredientFormSlice',
  initialState,
  reducers: {
    ingredientIndexUpdated(
      state,
      { payload: index }: PayloadAction<number | null>
    ) {
      state.index = index;
    },
    ingredientUpdated(
      state,
      { payload: ingredient }: PayloadAction<ApiIngredient | null>
    ) {
      state.ingredient = ingredient;
      state.isUnsaved = true;
    },
    ingredientAmountUpdated(state, { payload: amount }: PayloadAction<string>) {
      state.amount = amount;
      state.isUnsaved = true;
    },
    ingredientUnitUpdated(
      state,
      { payload: unit }: PayloadAction<ApiQuantityUnit | null>
    ) {
      state.unit = unit;
      state.isUnsaved = true;
    },
    ingredientPreparationsUpdated(
      state,
      { payload: preparations }: PayloadAction<ApiIngredientPreparation[]>
    ) {
      state.ingredientPreparations = preparations;
      state.isUnsaved = true;
    },
    ingredientPreparationMoved(
      state,
      { payload: { from, to } }: PayloadAction<{ from: number; to: number }>
    ) {
      const preparationToMove = state.ingredientPreparations.splice(from, 1);
      state.ingredientPreparations.splice(to, 0, preparationToMove[0]);
    },
    ingredientReset(state) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isShowing, ...resetState } = initialState;
      // Do not reset whether the form is shown or not
      return { ...resetState, isShowing: state.isShowing };
    },
    ingredientFormShown(state, { payload: isShowing }: PayloadAction<boolean>) {
      state.isShowing = isShowing;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(recipeDiscardChanges, () => initialState)
      .addMatcher(shouldResetState, () => initialState);
  },
});

export const {
  reducer: recipeIngredientFormReducer,
  actions: {
    ingredientAmountUpdated,
    ingredientUpdated,
    ingredientPreparationsUpdated,
    ingredientPreparationMoved,
    ingredientIndexUpdated,
    ingredientUnitUpdated,
    ingredientReset,
    ingredientFormShown,
  },
} = recipeIngredientFormSlice;

const selectState = (state: RootState) => state.recipeIngredientForm;

export const selectIndex = (state: RootState) => selectState(state).index;

export const selectIngredient = (state: RootState) =>
  selectState(state).ingredient;

export const selectAmount = (state: RootState) => selectState(state).amount;

export const selectUnit = (state: RootState) => selectState(state).unit;

export const selectPreparations = (state: RootState) =>
  selectState(state).ingredientPreparations;

export const selectIsIngredientUnsaved = (state: RootState) =>
  selectState(state).isUnsaved;

export const selectIsFormShowing = (state: RootState) =>
  selectState(state).isShowing;
