import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { AppAppliance } from 'types/appliance/appAppliance';

export const findApplianceByCapability = (
  capabilityId: ApiEntityId,
  appliances: AppAppliance[]
): AppAppliance | null => {
  const appliance = appliances.find(({ applianceModules }) =>
    applianceModules.some(({ capabilities }) =>
      capabilities.some(({ id }) => id === capabilityId)
    )
  );
  return appliance ?? null;
};
