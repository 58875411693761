export const recipeStepsFormStrings = {
  labels: {
    addedIngredients: 'Added ingredients',
    addTitle: 'Add New Step',
    ingredientsField: 'Ingredients',
    saveButton: 'Save',
    form: 'Add New Step',
    removeIngredientButton: (ingredientName: string) =>
      `Remove ${ingredientName}`,
    addedIngredientsTooltip: 'Click on each ingredient to edit amounts',
    editIngredientAmountCloseButton: 'Close',
  },
  placeholders: {
    ingredientsField: 'Add one or more ingredients',
  },
  testIds: {
    addedIngredientsInfoIcon: 'info-icon',
  },
  helperText: {
    editIngredientAmount: 'Press enter to confirm setting',
  },
} as const;
