import type { ApiRcpRecipeIngredient } from 'api/types/recipe/apiRcpRecipeIngredient';
import type { ApiRcpIngredientQuantityWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpIngredientQuantityWithRefs';
import { fromAppRecipeIngredientQuantity } from 'api/types/recipe/recipeWithRefs/apiRcpIngredientQuantityWithRefs';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';

export interface ApiRcpRecipeIngredientWithRefs
  extends Pick<ApiRcpRecipeIngredient, 'sourceText'> {
  referenceIngredientId: ApiRefId;
  referencePreparationIds?: ApiRefId[];
  quantity: ApiRcpIngredientQuantityWithRefs;
}

export function fromAppRecipeIngredient({
  id,
  preparations,
  quantity,
}: AppRecipeIngredient): ApiRcpRecipeIngredientWithRefs {
  return {
    referenceIngredientId: id,
    referencePreparationIds: preparations?.map((preparation) => preparation.id),
    quantity: fromAppRecipeIngredientQuantity(quantity),
  };
}
