import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { RootState } from 'app/store/rootReducer';
import type { ReferenceDataFetchActionPayload } from 'features/referenceData/types/referenceDataFetchActionPayload';
import type { ReferenceDataFetchActionResult } from 'features/referenceData/types/referenceDataFetchActionResult';
import type { ReferenceDataFetchingActionPayload } from 'features/referenceData/types/referenceDataFetchingActionPayload';
import type { ReferenceDataStateByLocale } from 'features/referenceData/types/referenceDataStateByLocale';

export type TagsById = Record<ApiRefId, ApiTag>;

export interface TagsState {
  fetchError: ReferenceDataStateByLocale<string>;
  fetching: ReferenceDataStateByLocale<boolean>;
  generalTags: ReferenceDataStateByLocale<ApiTag[]>;
  applianceTags: ReferenceDataStateByLocale<TagsById>;
}

export const initialState: TagsState = {
  fetching: {},
  fetchError: {},
  generalTags: {},
  applianceTags: {},
};

export const tagsSlice = createSlice({
  name: 'tagsSlice',
  initialState,
  reducers: {
    tagsFetchSucceed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<
        ReferenceDataFetchActionResult<{
          general: ApiTag[];
          appliances: TagsById;
        }>
      >
    ) {
      state.generalTags[locale] = data.general;
      state.applianceTags[locale] = data.appliances;
      state.fetching[locale] = false;
      state.fetchError[locale] = undefined;
    },
    tagsFetching(
      state,
      { payload: { locale } }: PayloadAction<ReferenceDataFetchingActionPayload>
    ) {
      state.fetching[locale] = true;
      state.fetchError[locale] = undefined;
    },
    tagsFetchFailed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<ReferenceDataFetchActionResult<string>>
    ) {
      state.fetching[locale] = false;
      state.fetchError[locale] = data;
    },
  },
});

export const tagsFetchRequested = createAction<ReferenceDataFetchActionPayload>(
  'tagsSlice/tagsFetchRequested'
);

export const {
  reducer: tagsReducer,
  actions: { tagsFetchSucceed, tagsFetching, tagsFetchFailed },
} = tagsSlice;

const selectTagsState = (state: RootState): TagsState =>
  state.referenceData.tags;

export const selectGeneralTags =
  (locale: ApiLocale) =>
  (state: RootState): ApiTag[] | undefined =>
    selectTagsState(state).generalTags[locale];

export const selectApplianceTags =
  (locale: ApiLocale) =>
  (state: RootState): TagsById | undefined =>
    selectTagsState(state).applianceTags[locale];

export const selectTagsFetching =
  (locale: ApiLocale) =>
  (state: RootState): boolean =>
    !!selectTagsState(state).fetching[locale];

export const selectTagsFetchError =
  (locale: ApiLocale) =>
  (state: RootState): string | undefined =>
    selectTagsState(state).fetchError[locale];

export const selectShouldFetchGeneralTags = (locale: ApiLocale) =>
  createSelector(
    selectGeneralTags(locale),
    selectTagsFetching(locale),
    (tags, fetching) => !fetching && !tags
  );

export const selectShouldFetchApplianceTags = (locale: ApiLocale) =>
  createSelector(
    selectApplianceTags(locale),
    selectTagsFetching(locale),
    (tags, fetching) => !fetching && !tags
  );

export const selectShouldFetchTags = (locale: ApiLocale) =>
  createSelector(
    selectShouldFetchGeneralTags(locale),
    selectShouldFetchApplianceTags(locale),
    (shouldFetchGeneralTags, shouldFetchApplianceTags) => {
      return shouldFetchGeneralTags || shouldFetchApplianceTags;
    }
  );
