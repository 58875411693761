import { Auth } from 'aws-amplify';

import { authConstants } from 'api/auth.constants';
import type { ApiResponse, ErrorResponse } from 'api/types';

export enum AmplifyAuthErrorCode {
  UserNotFoundException = 'UserNotFoundException',
  NotAuthorizedException = 'NotAuthorizedException',
}

export interface AmplifyUser {
  username: string;
  attributes: {
    email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    given_name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    family_name: string;
  };
}

export interface AmplifyAuthError {
  code: string;
  name?: string;
  message?: string;
}

const getAuthErrorResponse = (error: AmplifyAuthError): ErrorResponse => {
  if (
    error.code === AmplifyAuthErrorCode.NotAuthorizedException ||
    error.code === AmplifyAuthErrorCode.UserNotFoundException
  ) {
    return {
      ok: false,
      details: { message: authConstants.errorMessages.signIn },
    };
  }
  return {
    ok: false,
    details: {
      message: error.message ?? authConstants.errorMessages.unknown,
    },
  };
};

export const apiGetUserIsAuthenticated = async () => {
  try {
    await Auth.currentUserPoolUser();
    return { ok: true, data: undefined } as ApiResponse<undefined>;
  } catch (error) {
    return getAuthErrorResponse(error as AmplifyAuthError);
  }
};

interface ApiAuthSignInRequest {
  username: string;
  password: string;
}

export const apiAuthSignIn = async ({
  username,
  password,
}: ApiAuthSignInRequest) => {
  try {
    await Auth.signIn({ username, password });
    return { ok: true, data: undefined } as ApiResponse<undefined>;
  } catch (error) {
    return getAuthErrorResponse(error as AmplifyAuthError);
  }
};

export const apiAuthSignOut = async () => {
  try {
    await Auth.signOut();
    return { ok: true, data: undefined } as ApiResponse<undefined>;
  } catch (error) {
    return getAuthErrorResponse(error as AmplifyAuthError);
  }
};
