import type { ApiIngredientPreparation } from 'api/types/common/apiIngredientPreparation';
import type { ApiRcpRecipeIngredient } from 'api/types/recipe/apiRcpRecipeIngredient';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';
import { fromApiRcpRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';

export interface AppRecipeIngredient {
  id: ApiRefId;
  name: string;
  preparations?: ApiIngredientPreparation[];
  quantity: AppRecipeIngredientQuantity;
}

export function fromApiRcpRecipeIngredient({
  referenceIngredient,
  referencePreparations,
  quantity,
}: ApiRcpRecipeIngredient): AppRecipeIngredient {
  return {
    id: referenceIngredient.id,
    name: referenceIngredient.name,
    preparations: referencePreparations?.map((preparation) => ({
      id: preparation.id,
      name: preparation.name,
    })),
    quantity: fromApiRcpRecipeIngredientQuantity(quantity),
  };
}
