import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

import type { ApiIngredient } from 'api/types/common/apiIngredient';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { RootState } from 'app/store/rootReducer';
import type { ReferenceDataFetchActionPayload } from 'features/referenceData/types/referenceDataFetchActionPayload';
import type { ReferenceDataFetchActionResult } from 'features/referenceData/types/referenceDataFetchActionResult';
import type { ReferenceDataFetchingActionPayload } from 'features/referenceData/types/referenceDataFetchingActionPayload';
import type { ReferenceDataStateByLocale } from 'features/referenceData/types/referenceDataStateByLocale';

export interface IngredientsState {
  fetchError: ReferenceDataStateByLocale<string>;
  fetching: ReferenceDataStateByLocale<boolean>;
  ingredients: ReferenceDataStateByLocale<ApiIngredient[]>;
}

export const initialState: IngredientsState = {
  fetching: {},
  fetchError: {},
  ingredients: {},
};

export const ingredientsSlice = createSlice({
  name: 'ingredientsSlice',
  initialState,
  reducers: {
    ingredientsFetchSucceed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<ReferenceDataFetchActionResult<ApiIngredient[]>>
    ) {
      state.ingredients[locale] = data;
      state.fetching[locale] = false;
      state.fetchError[locale] = undefined;
    },
    ingredientsFetching(
      state,
      { payload: { locale } }: PayloadAction<ReferenceDataFetchingActionPayload>
    ) {
      state.fetching[locale] = true;
      state.fetchError[locale] = undefined;
    },
    ingredientsFetchFailed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<ReferenceDataFetchActionResult<string>>
    ) {
      state.fetching[locale] = false;
      state.fetchError[locale] = data;
    },
  },
});

export const ingredientsFetchRequested =
  createAction<ReferenceDataFetchActionPayload>(
    'ingredientsSlice/ingredientsFetchRequested'
  );

export const {
  reducer: ingredientsReducer,
  actions: {
    ingredientsFetchSucceed,
    ingredientsFetching,
    ingredientsFetchFailed,
  },
} = ingredientsSlice;

const selectIngredientsState = (state: RootState): IngredientsState =>
  state.referenceData.ingredients;

export const selectIngredients =
  (locale: ApiLocale) =>
  (state: RootState): ApiIngredient[] | undefined =>
    selectIngredientsState(state).ingredients[locale];

export const selectIngredientsFetching =
  (locale: ApiLocale) =>
  (state: RootState): boolean =>
    !!selectIngredientsState(state).fetching[locale];

export const selectIngredientsFetchError =
  (locale: ApiLocale) =>
  (state: RootState): string | undefined =>
    selectIngredientsState(state).fetchError[locale];

export const selectShouldFetchIngredients = (locale: ApiLocale) =>
  createSelector(
    selectIngredients(locale),
    selectIngredientsFetching(locale),
    (ingredients, fetching) => !fetching && !ingredients
  );

export const selectIngredient = (id: string, locale: ApiLocale) =>
  createSelector(selectIngredients(locale), (ingredients) =>
    ingredients?.find((ingredient) => ingredient.id === id)
  );
