export const recipesListParams = {
  recipeName: 'recipeName',
} as const;

export const recipesListConstants = {
  image: {
    width: 56,
    height: 56,
  },
  table: {
    ariaLabel: 'recipes list',
    skeletonAriaLabel: 'list loader',
    rowHeight: 92,
    emptyCell: '--',
    maxApplianceTagNameLength: 27,
    columns: {
      mainInfo: {
        sortable: false,
        minWidth: 200,
        flex: 1,
      },
      status: {
        sortable: false,
        width: 90,
      },
      locale: {
        sortable: false,
        width: 90,
      },
      translations: {
        sortable: false,
        width: 160,
      },
      appliances: {
        sortable: false,
        width: 200,
      },
      id: {
        sortable: false,
        width: 100,
      },
      modifiedAt: {
        sortable: false,
        width: 200,
        headerAlign: 'right',
        align: 'right',
      },
    },
    actions: {
      changeStatus: 'Status',
      delete: {
        ariaLabel: 'Delete recipe',
        dialog: {
          title: `Are you sure you want to delete ${recipesListParams.recipeName}?`,
          cancelButton: 'No, cancel',
          draft: {
            body: 'This cannot be undone.',
            confirmButton: 'Yes, delete',
          },
          published: {
            body: 'This recipe is published and may be saved or being cooked by users currently.',
            confirmButton: 'Yes, unpublish & delete',
          },
        },
      },
      translate: {
        label: 'Translate',
        noLocales: 'No available locales',
      },
      translations: {
        retryButton: 'Retry',
      },
    },
  },
} as const;

export const recipesListStrings = {
  emptyList: {
    title: 'Start adding recipes',
    text: 'You do not have any recipes added yet in your team. You can start by creating yours.',
  },
  emptySearch: {
    title: 'No recipes found',
    text: "Sorry we couldn't find the recipe you're looking for. Please try editing your search criteria.",
  },
  headers: {
    mainInfo: 'Recipe / Author',
    status: 'Status',
    locale: 'Locale',
    translations: 'Translations',
    appliances: 'Appliance(s)',
    id: 'ID',
    modifiedAt: 'Modified',
  },
  dates: {
    today: (time: string) => `Today @ ${time}`,
    yesterday: 'Yesterday',
  },
};
