import { generatePath } from 'react-router-dom';

import { createApiFn, fetchJson } from 'api/fetchJson';
import { apiGetListImagesUrl, apiImagesUrl, apiPathParams } from 'api/paths';
import type { ApiRequestFn, ApiResponse } from 'api/types';
import { HttpMethod } from 'api/types';
import type { ApiMedListImages } from 'api/types/media/apiMedListImages';

export enum MediaResourceType {
  Recipes = 'recipes',
}

export enum MediaImageType {
  Hero = 'hero',
}

export interface ApiPostImageRequest {
  resourceType: MediaResourceType;
  resourceId: string;
  imageType: MediaImageType;
  image: File;
}

export const postImage = async ({
  resourceType,
  resourceId,
  imageType,
  image,
}: ApiPostImageRequest) => {
  return new Promise<ApiResponse<undefined>>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(
        fetchJson<undefined>({
          httpMethod: HttpMethod.Post,
          url: `${apiImagesUrl}/${resourceType}/${resourceId}/${imageType}`,
          body: reader.result as ArrayBuffer,
          contentType: image.type,
        })
      );
    };
    reader.readAsArrayBuffer(image);
  });
};
export const apiPostImage: ApiRequestFn<undefined, ApiPostImageRequest> =
  createApiFn(postImage);

export interface ApiGetListImagesRequest {
  resourceType: MediaResourceType;
  resourceId: string;
}
export const apiGetListImages = async ({
  resourceType,
  resourceId,
}: ApiGetListImagesRequest) =>
  fetchJson<ApiMedListImages>({
    httpMethod: HttpMethod.Get,
    url: generatePath(apiGetListImagesUrl, {
      [apiPathParams.resourceType]: resourceType,
      [apiPathParams.resourceId]: resourceId,
    }),
  });
