import {
  PantryColor,
  PantryCornerRadius,
  PantryTypography,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { ListItemProps } from '@mui/material';
import {
  ListItemIcon,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import type { MouseEventHandler, ReactNode } from 'react';
import { forwardRef, memo } from 'react';

export interface ListRowProps extends ListItemProps {
  // eslint-disable-next-line react/no-unused-prop-types
  icon?: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  onClick?: MouseEventHandler;
  // eslint-disable-next-line react/no-unused-prop-types
  title: string;
}

// eslint-disable-next-line react/display-name
export const ListRow = memo(
  forwardRef<HTMLLIElement, ListRowProps>(function ListRow(
    { icon, onClick, title, sx, ...props },
    ref
  ) {
    return (
      <ListItem
        {...props}
        alignItems="center"
        disableGutters
        sx={sxCompose(
          {
            backgroundColor: PantryColor.SurfaceMuted,
            border: '1px solid',
            borderColor: PantryColor.BorderDefault,
            borderRadius: PantryCornerRadius.Small,
            p: 0,
            overflow: 'hidden',

            '&:hover': {
              borderColor: PantryColor.FrescoPrimary,
            },
          },
          sx
        )}
        ref={ref}
      >
        <ListItemButton sx={{ px: 5, py: 2.5 }} onClick={onClick}>
          {icon && (
            <ListItemIcon sx={{ minWidth: '16px', mr: 2 }}>{icon}</ListItemIcon>
          )}
          <Typography
            color={PantryColor.TextDefault}
            variant={PantryTypography.Body2}
          >
            {title}
          </Typography>
        </ListItemButton>
      </ListItem>
    );
  })
);
