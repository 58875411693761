import { TabList as MuiTabList } from '@mui/lab';
import type { FC, ReactNode } from 'react';
import { memo, useEffect, useRef } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { tabsListConstants } from 'components/Tab/TabsList.constants';
import {
  selectTabsListSticking,
  tabsSticked,
} from 'components/Tab/tabsListSlice';

const { stickPoint, animationDuration } = tabsListConstants;

export const hasStickinessChanged = (
  tabsPosition: number,
  stickTo: number,
  sticking: boolean
): { sticking: boolean; changed: boolean } => {
  if (tabsPosition <= stickTo + stickPoint.scrollingDownOffset && !sticking) {
    return { sticking: true, changed: true };
  }
  if (tabsPosition >= stickTo + stickPoint.scrollingUpOffset && sticking) {
    return { sticking: false, changed: true };
  }
  /** Scrolling within stick and unstick points does not change the stickiness state */
  return { sticking, changed: false };
};

export interface TabListProps {
  children: ReactNode;
  /** Distance from top of the viewport to which the tabs will stick */
  stickTo?: number;
}

export const TabsList: FC<TabListProps> = memo(function TabsList({
  children,
  stickTo,
}) {
  const dispatch = useAppDispatch();
  const tabsRef = useRef<HTMLButtonElement | null>(null);
  const sticking = useAppSelector(selectTabsListSticking);
  const timeoutRef = useRef<number | undefined>();

  useEffect(() => {
    if (stickTo === undefined) {
      return;
    }

    const updateIsSticking = () => {
      if (timeoutRef.current) {
        return;
      }

      timeoutRef.current = window.setTimeout(() => {
        const stickinessChange = hasStickinessChanged(
          tabsRef?.current?.getBoundingClientRect()?.top as number,
          stickTo,
          sticking
        );
        if (stickinessChange.changed) {
          dispatch(tabsSticked(stickinessChange.sticking));
        }

        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }, 100);
    };

    window.addEventListener('scroll', updateIsSticking);
    return () => window.removeEventListener('scroll', updateIsSticking);
  }, [dispatch, stickTo, sticking]);

  useEffect(() => {
    return () => {
      dispatch(tabsSticked(false));
    };
  }, [dispatch]);

  return (
    <MuiTabList
      ref={tabsRef}
      variant="fullWidth"
      sx={{
        position: stickTo !== undefined ? 'sticky' : 'relative',
        top: stickTo !== undefined ? `${stickTo}px` : 'unset',
        zIndex: 2,
        boxShadow: sticking ? '0px 12px 20px rgba(0, 0, 0, 0.07)' : 'none',
        transition: `box-shadow ${animationDuration} ease-in-out`,
      }}
      TabIndicatorProps={{
        /** Remove selected tab indicator */
        sx: { background: 'none' },
      }}
    >
      {children}
    </MuiTabList>
  );
});
