import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetIngredients } from 'api/ingredients';
import type { ApiList, ApiResponse } from 'api/types';
import type { ApiIngredient } from 'api/types/common/apiIngredient';
import { appConfig } from 'config/config';
import { errorOccurred } from 'features/error/errorSlice';
import {
  ingredientsFetchFailed,
  ingredientsFetching,
  ingredientsFetchRequested,
  ingredientsFetchSucceed,
  selectShouldFetchIngredients,
} from 'features/referenceData/ingredients/ingredientsSlice';
import type { ReferenceDataFetchActionPayload } from 'features/referenceData/types/referenceDataFetchActionPayload';

export const apiFetchIngredientsSaga = createApiRequestSaga(apiGetIngredients);

export function* fetchIngredients({
  payload: { locale },
}: PayloadAction<ReferenceDataFetchActionPayload>) {
  const shouldFetch = (yield select(
    selectShouldFetchIngredients(locale)
  )) as ReturnType<typeof selectShouldFetchIngredients>;
  if (!shouldFetch) {
    return;
  }

  yield put(ingredientsFetching({ locale }));

  let lastItem = 0;
  let hasMore = true;
  const data: ApiIngredient[] = [];
  while (hasMore) {
    const response = (yield call(apiFetchIngredientsSaga, {
      from: lastItem,
      locale,
    })) as ApiResponse<ApiList<ApiIngredient>>;

    if (!response.ok) {
      yield put(
        ingredientsFetchFailed({ locale, data: response.details.message })
      );
      yield put(errorOccurred(response.details.message));
      return;
    }

    data.push(...response.data.items);
    lastItem += appConfig.referenceDataApiPageSize;
    hasMore = lastItem < response.data.total;
  }

  yield put(ingredientsFetchSucceed({ locale, data }));
}

function* ingredientsFetchWatcher() {
  yield takeEvery(ingredientsFetchRequested, fetchIngredients);
}

export const ingredientsSagas = [ingredientsFetchWatcher];
