import { generatePath } from 'react-router-dom';

import { createApiFn, fetchJson } from 'api/fetchJson';
import {
  apiAppliancesUrl,
  apiGetApplianceUrl,
  apiPathParams,
  configAppliancesPath,
} from 'api/paths';
import type { ApiRequestFn } from 'api/types';
import { HttpMethod } from 'api/types';
import type { ApiAplAppliance } from 'api/types/appliance/apiAplAppliance';
import type { ApiAplApplianceList } from 'api/types/appliance/apiAplApplianceList';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';

export interface ApplianceConfig {
  deviceTags: {
    deviceRefId: string;
    tags: {
      tagId: ApiRefId;
      tagName: string;
    }[];
  }[];
}
export const getApplianceConfig = async () =>
  fetchJson<ApplianceConfig>({
    httpMethod: HttpMethod.Get,
    url: configAppliancesPath,
  });
export const apiGetApplianceConfig: ApiRequestFn<ApplianceConfig> =
  createApiFn(getApplianceConfig);

/**
 * Get an appliance by id
 */
export const getAppliance = async (applianceId: string) =>
  fetchJson<ApiAplAppliance>({
    httpMethod: HttpMethod.Get,
    url: generatePath(apiGetApplianceUrl, {
      [apiPathParams.applianceId]: applianceId,
    }),
  });
export const apiGetAppliance: ApiRequestFn<ApiAplAppliance, string> =
  createApiFn(getAppliance);

export const getAppliances = async () =>
  fetchJson<ApiAplApplianceList>({
    httpMethod: HttpMethod.Get,
    url: generatePath(apiAppliancesUrl),
  });
export const apiGetAppliances: ApiRequestFn<ApiAplApplianceList> =
  createApiFn(getAppliances);
