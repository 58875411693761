import type { ApiRcpRecipeIngredientQuantity } from 'api/types/recipe/apiRcpRecipeIngredientQuantity';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';

export interface ApiRcpIngredientQuantityWithRefs
  extends Pick<ApiRcpRecipeIngredientQuantity, 'amount'> {
  referenceUnitId: ApiRefId;
}

export function fromAppRecipeIngredientQuantity({
  amount,
  unit,
}: AppRecipeIngredientQuantity): ApiRcpIngredientQuantityWithRefs {
  return {
    amount: amount ?? undefined,
    referenceUnitId: unit.id,
  };
}
