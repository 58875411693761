import { createApiFn, fetchJson } from 'api/fetchJson';
import { apiPreparationsUrl } from 'api/paths';
import type { ApiList, ApiRequestFn } from 'api/types';
import { HttpMethod } from 'api/types';
import type { ApiIngredientPreparation } from 'api/types/common/apiIngredientPreparation';
import type { ApiRefLocalizedDataRequest } from 'api/types/referenceData/apiRefLocalizedDataRequest';
import { appConfig } from 'config/config';

export const getPreparations = async ({ locale }: ApiRefLocalizedDataRequest) =>
  fetchJson<ApiList<ApiIngredientPreparation>>({
    httpMethod: HttpMethod.Post,
    url: `${apiPreparationsUrl}?size=${appConfig.referenceDataApiPageSize}`,
    body: { locale },
  });

export const apiGetPreparations: ApiRequestFn<
  ApiList<ApiIngredientPreparation>,
  ApiRefLocalizedDataRequest
> = createApiFn(getPreparations);
