import { createApiFn, fetchJson } from 'api/fetchJson';
import { apiIngredientsUrl } from 'api/paths';
import type { ApiList, ApiRequestFn } from 'api/types';
import { HttpMethod } from 'api/types';
import type { ApiIngredient } from 'api/types/common/apiIngredient';
import type { ApiRefLocalizedDataRequest } from 'api/types/referenceData/apiRefLocalizedDataRequest';
import { appConfig } from 'config/config';

interface ApiGetIngredientsRequest extends ApiRefLocalizedDataRequest {
  from: number;
}
export const getIngredients = async ({
  from,
  locale,
}: ApiGetIngredientsRequest) =>
  fetchJson<ApiList<ApiIngredient>>({
    httpMethod: HttpMethod.Get,
    url: `${apiIngredientsUrl}?size=${appConfig.referenceDataApiPageSize}&from=${from}`,
    headers: { 'Accept-Language': locale },
  });

export const apiGetIngredients: ApiRequestFn<
  ApiList<ApiIngredient>,
  ApiGetIngredientsRequest
> = createApiFn(getIngredients);
