import { generatePath } from 'react-router-dom';

import type { RecipeType } from 'app/routes/constants';
import { appRecipeRoutes, appRouteParams } from 'app/routes/constants';
import type { RecipeTabName } from 'features/recipe/RecipePage.constants';

export const generateRecipeRoute = ({
  id,
  tab,
}: {
  id?: string;
  tab?: RecipeTabName;
} = {}): string => {
  const { root, create, edit } = appRecipeRoutes;
  const { recipeId, recipeTab } = appRouteParams;
  const route = id
    ? `/${root}/${tab ? edit.tab : edit.root}`
    : `/${root}/${tab ? create.tab : create.root}`;
  return generatePath(route, { [recipeId]: id, [recipeTab]: tab });
};

export const generateRecipeListRoute = ({
  type,
}: {
  type: RecipeType;
}): string => {
  const { root, list } = appRecipeRoutes;
  return `/${root}/${list[type]}`;
};
