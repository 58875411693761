export const appRouteParams = {
  recipeId: 'recipeId',
  recipeTab: 'tab',
  recipeLocale: 'recipeLocale',
};

export enum RecipeType {
  Core = 'core',
  Translation = 'translation',
}

export const appRecipeRoutes = {
  root: `recipes`,
  list: {
    [RecipeType.Core]: RecipeType.Core,
    [RecipeType.Translation]: RecipeType.Translation,
  },
  create: {
    root: `create`,
    tab: `create/:${appRouteParams.recipeTab}`,
    default: `create/`,
  },
  edit: {
    root: `:${appRouteParams.recipeId}`,
    tab: `:${appRouteParams.recipeId}/:${appRouteParams.recipeTab}`,
  },
  translate: {
    root: `translate/:${appRouteParams.recipeId}/:${appRouteParams.recipeLocale}`,
  },
};
