import { PantryTypography, PantryColor } from '@dropkitchen/pantry-react';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import type { FC } from 'react';
import { useMemo, memo, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { CapabilityAttachments } from 'components/CapabilityField/CapabilityAttachments';
import { capabilityFieldStrings } from 'components/CapabilityField/CapabilityField.constants';
import { CapabilitySettingField } from 'components/CapabilityField/CapabilitySettingField';
import {
  areDependenciesMet,
  generateCapabilitySettingFieldDependsOn,
} from 'components/CapabilityField/CapabilitySettingFieldDependencies';
import {
  selectAppliances,
  selectAppliancesFetching,
} from 'features/appliances/appliancesSlice';
import { selectRecipeLocale } from 'features/recipe/recipeSlice';
import {
  selectAppliance,
  selectCapability,
  selectCapabilityType,
  stepApplianceUpdated,
  stepCapabilityUpdated,
  stepCapabilityTypeUpdated,
  stepSettingsUpdated,
  stepSettingsDependenciesUpdated,
  selectPhase,
  stepPhaseUpdated,
} from 'features/recipe/steps/form/recipeStepsFormSlice';
import {
  selectCapabilities,
  selectCapabilitiesFetching,
} from 'features/referenceData/capabilities/capabilitiesSlice';
import { disambiguateTerms } from 'features/referenceData/referenceData.utils';
import type { AppCapability } from 'types/appCapability';
import { AppCapabilityType } from 'types/appCapability';
import type { AppCapabilitySettingValue } from 'types/appCapabilitySettingValue';

const { labels, placeholders } = capabilityFieldStrings;

export const CapabilityField: FC = memo(function CapabilityField() {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const locale = useAppSelector(selectRecipeLocale);
  const generalCapabilities = useAppSelector(selectCapabilities(locale));
  const appliances = useAppSelector(selectAppliances);

  const isFetchingAppliances = useAppSelector(selectAppliancesFetching);
  const isFetchingCapabilities = useAppSelector(
    selectCapabilitiesFetching(locale)
  );

  const selectedCapabilityType = useAppSelector(selectCapabilityType);
  const selectedCapability = useAppSelector(selectCapability);
  const selectedAppliance = useAppSelector(selectAppliance);
  const selectedPhase = useAppSelector(selectPhase);

  const [availableCapabilities, setAvailableCapabilities] = useState<
    AppCapability[]
  >([]);

  const disambiguatedCapabilities = useMemo(
    () => disambiguateTerms(generalCapabilities || []),
    [generalCapabilities]
  );

  useEffect(() => {
    if (!generalCapabilities?.length && !appliances.length) {
      return;
    }

    const defaultAppliance = selectedAppliance ?? appliances[0];
    const isPreset =
      selectedCapabilityType === AppCapabilityType.AppliancePreset;
    const appliance = !isPreset ? null : defaultAppliance;
    dispatch(stepApplianceUpdated(appliance ?? null));

    /** @todo Remove hardcoded appliance module once we have a UI solution to handle multiple modules {@link https://frescocooks.atlassian.net/browse/PIE-890} */
    const capabilities = isPreset
      ? appliance?.applianceModules[0].capabilities
      : generalCapabilities;
    setAvailableCapabilities(capabilities || []);
  }, [appliances, generalCapabilities, dispatch, selectedCapabilityType, selectedAppliance]);

  useEffect(() => {
    // Set default value for phase
    if (selectedCapability && !selectedPhase) {
      dispatch(stepPhaseUpdated(selectedCapability.allowedPhases[0] ?? null));
    }

    dispatch(
      stepSettingsDependenciesUpdated(selectedCapability?.allowedSettings || [])
    );
  }, [dispatch, selectedCapability, selectedPhase]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="capability-type-label">
            {labels.capabilityTypeRadio.group}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="capability-type-label"
            name="capability-type"
            value={selectedCapabilityType}
            onChange={(event) => {
              dispatch(
                stepCapabilityTypeUpdated(
                  event.target.value as AppCapabilityType
                )
              );
              dispatch(stepCapabilityUpdated(null));
              dispatch(stepSettingsUpdated([]));
            }}
          >
            <FormControlLabel
              value={AppCapabilityType.General}
              control={<Radio />}
              label={labels.capabilityTypeRadio.general}
            />
            <FormControlLabel
              value={AppCapabilityType.AppliancePreset}
              control={<Radio />}
              label={labels.capabilityTypeRadio.appliancePreset}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {selectedCapabilityType === AppCapabilityType.AppliancePreset && (
        <Grid item xs={12}>
          <Autocomplete
            id="appliance-selector"
            value={selectedAppliance}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, appliance) => {
              dispatch(stepApplianceUpdated(appliance));
              /** @todo Remove hardcoded appliance module once we have a UI solution to handle multiple modules {@link https://frescocooks.atlassian.net/browse/PIE-890} */
              const capabilities = appliance
                ? appliance.applianceModules[0].capabilities
                : [];
              setAvailableCapabilities(capabilities);
              dispatch(stepCapabilityUpdated(null));
              dispatch(stepSettingsUpdated([]));
            }}
            loading={isFetchingAppliances}
            options={appliances}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={labels.applianceField}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
          />
        </Grid>
      )}
      {(selectedAppliance ||
        selectedCapabilityType === AppCapabilityType.General) && (
        <Grid item xs={12}>
          <Autocomplete
            id="step-selected-capability"
            value={selectedCapability}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, selectedOption) => {
              dispatch(stepCapabilityUpdated(selectedOption));
              const settingsWithDefaultValues = selectedOption
                ? selectedOption.allowedSettings
                    .filter(({ defaultValue }) => defaultValue)
                    .map(({ id, name, defaultValue, dependsOnSetting }) => ({
                      id,
                      name,
                      value: defaultValue as AppCapabilitySettingValue,
                      dependsOnSetting,
                    }))
                : [];

              const selectedSettings = settingsWithDefaultValues.filter(
                ({ dependsOnSetting }) => {
                  if (!dependsOnSetting) {
                    return true;
                  }
                  const dependencies =
                    generateCapabilitySettingFieldDependsOn(dependsOnSetting);
                  return (
                    !dependencies ||
                    areDependenciesMet(dependencies, settingsWithDefaultValues)
                  );
                }
              );
              dispatch(stepSettingsUpdated(selectedSettings));
            }}
            options={availableCapabilities}
            loading={isFetchingCapabilities || isFetchingAppliances}
            getOptionLabel={(option) =>
              disambiguatedCapabilities[option.id] ?? option.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  selectedCapabilityType === AppCapabilityType.General
                    ? placeholders.generalCapabilityField
                    : placeholders.presetCapabilityField
                }
                label={
                  selectedCapabilityType === AppCapabilityType.General
                    ? labels.generalCapabilityField
                    : labels.presetCapabilityField
                }
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {disambiguatedCapabilities[option.id] ?? option.name}
              </li>
            )}
          />
        </Grid>
      )}
      {!!selectedCapability?.attachments?.length && (
        <Grid item xs={12} style={{ paddingTop: '8px' }}>
          <CapabilityAttachments attachments={selectedCapability.attachments} />
        </Grid>
      )}
      {selectedCapability && (
        <>
          <Grid item xs={12}>
            <Autocomplete
              id="phase-selector"
              value={selectedPhase}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, phase) => {
                dispatch(stepPhaseUpdated(phase));
              }}
              loading={isFetchingCapabilities}
              options={selectedCapability.allowedPhases}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={labels.phaseField}
                  placeholder={placeholders.phaseField}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Typography
                  variant={PantryTypography.Body1}
                  sx={{
                    pb: 2,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    color: PantryColor.FrescoPrimary,
                  }}
                >
                  {labels.settingsSectionTitle}
                </Typography>
              </Grid>
              {selectedCapability.allowedSettings.map((setting) => (
                <CapabilitySettingField
                  setting={setting}
                  key={`${selectedCapability.id}-${setting.id}`}
                />
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
});
