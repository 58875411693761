import { Amplify } from 'aws-amplify';
import type { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { appConfig } from 'config/config';

export function* amplifyInitSaga(): SagaIterator<void> {
  const region = (yield call(appConfig.region)) as string;
  const userPoolId = (yield call(appConfig.userPoolId)) as string;
  const userPoolWebClientId = (yield call(
    appConfig.userPoolWebClientId
  )) as string;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/unbound-method
  yield call([Amplify, Amplify.configure], {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Auth: {
      region,
      userPoolId,
      userPoolWebClientId,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  });
}
