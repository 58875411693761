import {
  Button,
  ButtonSize,
  ButtonStyle,
  PantryColor,
  PantryTypography,
  Skeleton,
  SkeletonVariant,
} from '@dropkitchen/pantry-react';
import { TabContext, TabPanel } from '@mui/lab';
import type { Theme } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';
import type { SxProps } from '@mui/system';
import includes from 'lodash/includes';
import type { FC } from 'react';
import { useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';
import { push } from 'redux-first-history';

import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { RecipeType } from 'app/routes/constants';
import {
  generateRecipeListRoute,
  generateRecipeRoute,
} from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { stylesCenterContent } from 'app/theme';
import { ButtonWithSkeleton } from 'components/ButtonWithSkeleton/ButtonWithSkeleton';
import { Tab } from 'components/Tab/Tab';
import { TabsList } from 'components/Tab/TabsList';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { appliancesFetchRequested } from 'features/appliances/appliancesSlice';
import { DetailLayout } from 'features/layout/DetailLayout';
import { headerConstants } from 'features/layout/Header.constants';
import { mediaStorage } from 'features/media/mediaStorage';
import { AutoSaveNotifications } from 'features/recipe/AutoSaveNotifications';
import { LeaveWithUnsavedChangesDialog } from 'features/recipe/LeaveWithUnsavedChangesDialog';
import { PublishConfirmationDialog } from 'features/recipe/PublishConfirmationDialog/PublishConfirmationDialog';
import { PublishedRecipeUpdatedDialog } from 'features/recipe/PublishedRecipeUpdatedDialog/PublishedRecipeUpdatedDialog';
import {
  RecipeTabName,
  recipePageStrings,
} from 'features/recipe/RecipePage.constants';
import { RecipeIngredients } from 'features/recipe/ingredients/list/RecipeIngredients';
import { RecipeBasicInformation } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation';
import {
  recipeSaveBeforeLeavingRequested,
  recipeFetchRequested,
  recipePublishRequested,
  recipeSaveRequested,
  recipeUnpublishRequested,
  selectRecipe,
  selectRecipeFetching,
  selectRecipeHasUnsavedChanges,
  selectRecipeLocale,
  selectRecipePublishing,
  selectRecipeSaving,
  selectRecipeUnpublishing,
} from 'features/recipe/recipeSlice';
import { RecipeReview } from 'features/recipe/review/RecipeReview';
import { RecipeSteps } from 'features/recipe/steps/list/RecipeSteps';
import { capabilitiesFetchRequested } from 'features/referenceData/capabilities/capabilitiesSlice';
import { ingredientsFetchRequested } from 'features/referenceData/ingredients/ingredientsSlice';
import { preparationsFetchRequested } from 'features/referenceData/preparations/preparationsSlice';
import { tagsFetchRequested } from 'features/referenceData/tags/tagsSlice';
import { useLeavePageWithUnsavedChanges } from 'hooks/useLeavePageWithUnsavedChanges';
import { usePreserveScrollPosition } from 'hooks/usePreserveScrollPosition';
import { AppFeature } from 'types/appFeature';

const tabPanelStyle: SxProps<Theme> = { px: 6, py: 10 };

const { labels, ariaLabels } = recipePageStrings;

export const RecipePage: FC = memo(function RecipePage() {
  const dispatch = useAppDispatch();
  const { recipeId, ...restRouteParams } = useParams<{
    recipeId: ApiRcpRecipeId;
    tab: RecipeTabName;
  }>();

  const isSaving = useAppSelector(selectRecipeSaving);
  const hasChanges = useAppSelector(selectRecipeHasUnsavedChanges);
  const isFetching = useAppSelector(selectRecipeFetching);
  const isPublishing = useAppSelector(selectRecipePublishing);
  const isUnpublishing = useAppSelector(selectRecipeUnpublishing);
  const recipe = useAppSelector(selectRecipe);
  const locale = useAppSelector(selectRecipeLocale);
  const isCoreRecipe = !recipe?.forkedFromId;

  const localeInformationText = `${
    isCoreRecipe ? labels.coreRecipe : labels.translationRecipe
  }: ${locale}`;

  const tab =
    restRouteParams.tab && includes(RecipeTabName, restRouteParams.tab)
      ? restRouteParams.tab
      : RecipeTabName.Information;

  const tabPaths: Record<RecipeTabName, string> = {
    [RecipeTabName.Information]: generateRecipeRoute({
      id: recipeId,
      tab: RecipeTabName.Information,
    }),
    [RecipeTabName.Ingredients]: generateRecipeRoute({
      id: recipeId,
      tab: RecipeTabName.Ingredients,
    }),
    [RecipeTabName.Steps]: generateRecipeRoute({
      id: recipeId,
      tab: RecipeTabName.Steps,
    }),
    [RecipeTabName.Review]: generateRecipeRoute({
      id: recipeId,
      tab: RecipeTabName.Review,
    }),
  };

  const handleBeforeUnload = () => {
    if (isEdit) {
      dispatch(recipeSaveBeforeLeavingRequested(recipeId));
    }
  };

  const { showPrompt, confirmNavigation, cancelNavigation } =
    useLeavePageWithUnsavedChanges({
      isEnabled: hasChanges,
      excludedPaths: Object.values(tabPaths),
      onBeforeUnload: handleBeforeUnload,
    });

  usePreserveScrollPosition(tab);

  const isEdit = !!recipeId;

  useEffect(() => {
    if (!isEdit && !locale) {
      dispatch(push(generateRecipeListRoute({ type: RecipeType.Core })));
    }
  }, [dispatch, isEdit, locale]);

  useEffect(() => {
    if (isEdit) {
      dispatch(recipeFetchRequested(recipeId));
    }
    /** Clear the media storage when unmounting this component - keep the uploader clean */
    return () => mediaStorage.clear();
  }, [dispatch, isEdit, recipeId]);

  /**
   * Retrieve all reference data needed for comboboxes beforehand, so they will be loaded
   * and user won't have to wait.
   */
  useEffect(() => {
    /**
     * Locale can be undefined until {@link https://frescocooks.atlassian.net/browse/PIE-895} is done.
     * Adding this validation so it doesn't trigger requests with an undefined locale.
     */
    if (!locale) {
      return;
    }
    dispatch(ingredientsFetchRequested({ locale }));
    dispatch(preparationsFetchRequested({ locale }));
    dispatch(capabilitiesFetchRequested({ locale }));
    dispatch(tagsFetchRequested({ locale }));
    dispatch(appliancesFetchRequested());
  }, [dispatch, locale]);

  const handleSaveRecipe = () => {
    dispatch(recipeSaveRequested(recipeId));
  };

  const getSaveButtonText = () =>
    hasChanges ? labels.saveButton : labels.savedButton;

  return (
    <>
      <Grid container sx={{ ...stylesCenterContent, pt: 10, pb: 4, mb: 4 }}>
        <ToggleableFeature
          requires={AppFeature.TranslationManagement}
          components={{ whenDisabled: null }}
        >
          {isFetching ? (
            <Box aria-label={ariaLabels.localeInfo}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ height: 16, width: 70 }}
              />
            </Box>
          ) : (
            <Typography
              variant={PantryTypography.Overline}
              color={PantryColor.TextSubtle}
            >
              {localeInformationText.toUpperCase()}
            </Typography>
          )}
        </ToggleableFeature>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={8}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {isFetching ? (
              <Box aria-label={ariaLabels.title}>
                <Skeleton
                  variant={SkeletonVariant.Small}
                  sx={{ width: 400, height: 34 }}
                />
              </Box>
            ) : (
              <Typography
                variant={PantryTypography.H6}
                color={PantryColor.TextDefault}
              >
                {isEdit ? `Edit "${recipe.name}"` : 'Create a recipe'}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 4,
            }}
          >
            {isEdit && (
              <AutoSaveNotifications saving={isSaving} changed={hasChanges} />
            )}
            <ButtonWithSkeleton
              isLoading={isFetching}
              button={
                <Button
                  label={getSaveButtonText()}
                  buttonStyle={ButtonStyle.Default}
                  size={ButtonSize.Large}
                  onClick={handleSaveRecipe}
                  loading={isSaving}
                  disabled={!hasChanges}
                />
              }
            />
            {isEdit && recipe.state === ApiRcpRecipeState.Draft && (
              <ButtonWithSkeleton
                isLoading={isFetching}
                button={
                  <Button
                    label={labels.publishButton}
                    buttonStyle={ButtonStyle.Emphasis}
                    size={ButtonSize.Large}
                    onClick={() => dispatch(recipePublishRequested(recipeId))}
                    loading={isPublishing}
                  />
                }
              />
            )}
            {isEdit && recipe.state === ApiRcpRecipeState.Published && (
              <ButtonWithSkeleton
                isLoading={isFetching}
                button={
                  <Button
                    label={labels.unpublishButton}
                    buttonStyle={ButtonStyle.Emphasis}
                    size={ButtonSize.Large}
                    onClick={() => dispatch(recipeUnpublishRequested(recipeId))}
                    loading={isUnpublishing}
                  />
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <DetailLayout>
        <TabContext value={tab}>
          <TabsList stickTo={headerConstants.height}>
            <Tab
              label="Basic Information"
              subLabel="Enter information about this recipe"
              value={RecipeTabName.Information}
              position={1}
              to={tabPaths[RecipeTabName.Information]}
            />
            <Tab
              label="Ingredients"
              subLabel="Add ingredients and how to prepare them"
              value={RecipeTabName.Ingredients}
              position={2}
              to={tabPaths[RecipeTabName.Ingredients]}
            />
            <Tab
              label="Steps"
              subLabel="Add steps to be taken in cooking this dish"
              value={RecipeTabName.Steps}
              position={3}
              to={tabPaths[RecipeTabName.Steps]}
            />
            <Tab
              label="Review"
              subLabel="Review and publish this recipe"
              value={RecipeTabName.Review}
              position={4}
              to={tabPaths[RecipeTabName.Review]}
            />
          </TabsList>
          <TabPanel sx={tabPanelStyle} value={RecipeTabName.Information}>
            <RecipeBasicInformation />
          </TabPanel>
          <TabPanel sx={tabPanelStyle} value={RecipeTabName.Ingredients}>
            <RecipeIngredients />
          </TabPanel>
          <TabPanel sx={tabPanelStyle} value={RecipeTabName.Steps}>
            <RecipeSteps />
          </TabPanel>
          <TabPanel sx={tabPanelStyle} value={RecipeTabName.Review}>
            <RecipeReview />
          </TabPanel>
        </TabContext>
      </DetailLayout>
      {showPrompt && (
        <LeaveWithUnsavedChangesDialog
          onCancel={cancelNavigation}
          onConfirm={confirmNavigation}
        />
      )}
      <PublishConfirmationDialog />
      <PublishedRecipeUpdatedDialog />
    </>
  );
});
