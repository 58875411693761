import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';

import { routes } from 'app/routes/routes';
import { theme } from 'app/theme';
import { AuthWrapper } from 'features/auth/AuthWrapper';
import { ErrorDialog } from 'features/error/ErrorDialog';

export function App() {
  const router = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthWrapper>{router}</AuthWrapper>
      <ErrorDialog />
    </ThemeProvider>
  );
}
