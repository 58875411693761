import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { RootState } from 'app/store/rootReducer';
import type { ReferenceDataFetchActionPayload } from 'features/referenceData/types/referenceDataFetchActionPayload';
import type { ReferenceDataFetchActionResult } from 'features/referenceData/types/referenceDataFetchActionResult';
import type { ReferenceDataFetchingActionPayload } from 'features/referenceData/types/referenceDataFetchingActionPayload';
import type { ReferenceDataStateByLocale } from 'features/referenceData/types/referenceDataStateByLocale';
import type { AppCapability } from 'types/appCapability';

export interface CapabilitiesState {
  fetchError: ReferenceDataStateByLocale<string>;
  fetching: ReferenceDataStateByLocale<boolean>;
  capabilities: ReferenceDataStateByLocale<AppCapability[]>;
}

export const initialState: CapabilitiesState = {
  fetching: {},
  fetchError: {},
  capabilities: {},
};

export const capabilitiesSlice = createSlice({
  name: 'capabilitiesSlice',
  initialState,
  reducers: {
    capabilitiesFetchSucceed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<ReferenceDataFetchActionResult<AppCapability[]>>
    ) {
      state.capabilities[locale] = data;
      state.fetching[locale] = false;
      state.fetchError[locale] = undefined;
    },
    capabilitiesFetching(
      state,
      { payload: { locale } }: PayloadAction<ReferenceDataFetchingActionPayload>
    ) {
      state.fetching[locale] = true;
      state.fetchError[locale] = undefined;
    },
    capabilitiesFetchFailed(
      state,
      {
        payload: { locale, data },
      }: PayloadAction<ReferenceDataFetchActionResult<string>>
    ) {
      state.fetching[locale] = false;
      state.fetchError[locale] = data;
    },
  },
});

export const capabilitiesFetchRequested =
  createAction<ReferenceDataFetchActionPayload>(
    'capabilitiesSlice/capabilitiesFetchRequested'
  );

export const {
  reducer: capabilitiesReducer,
  actions: {
    capabilitiesFetchSucceed,
    capabilitiesFetching,
    capabilitiesFetchFailed,
  },
} = capabilitiesSlice;

const selectCapabilitiesState = (state: RootState): CapabilitiesState =>
  state.referenceData.capabilities;

export const selectCapabilities =
  (locale: ApiLocale) =>
  (state: RootState): AppCapability[] | undefined =>
    selectCapabilitiesState(state).capabilities[locale];

export const selectCapabilitiesFetching =
  (locale: ApiLocale) =>
  (state: RootState): boolean =>
    !!selectCapabilitiesState(state).fetching[locale];

export const selectCapabilitiesFetchError =
  (locale: ApiLocale) =>
  (state: RootState): string | undefined =>
    selectCapabilitiesState(state).fetchError[locale];

export const selectShouldFetchCapabilities = (locale: ApiLocale) =>
  createSelector(
    selectCapabilities(locale),
    selectCapabilitiesFetching(locale),
    (capabilities, fetching) => !fetching && !capabilities
  );

export const selectCapability = (id: string, locale: ApiLocale) =>
  createSelector(selectCapabilities(locale), (capabilities) => {
    return capabilities?.find((capability) => capability.id === id);
  });
