import { BadgeStatus } from '@dropkitchen/pantry-react';

import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';

export const getStatusColor = (status: ApiRcpRecipeState) => {
  switch (status) {
    case ApiRcpRecipeState.Draft:
      return BadgeStatus.InteractiveYellow;
    case ApiRcpRecipeState.Published:
      return BadgeStatus.SurfaceAccentEmphasis;
    default:
      return BadgeStatus.SurfaceSubtle;
  }
};
