import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { ApiRcpRecipeIngredientQuantity } from 'api/types/recipe/apiRcpRecipeIngredientQuantity';

export interface AppRecipeIngredientQuantity {
  amount: number | null;
  text?: string;
  unit: ApiQuantityUnit;
}

export function fromApiRcpRecipeIngredientQuantity({
  amount,
  text,
  referenceUnit,
}: ApiRcpRecipeIngredientQuantity): AppRecipeIngredientQuantity {
  return {
    amount: amount ?? null,
    text,
    unit: {
      id: referenceUnit.id,
      name: referenceUnit.name,
      abbreviation: referenceUnit.abbreviation,
    },
  };
}
