import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  CloseIcon,
  PantryColor,
  PantryCornerRadius,
} from '@dropkitchen/pantry-react';
import { Box, InputAdornment, TextField } from '@mui/material';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import { useRef, memo, useState, useMemo } from 'react';
import { useClickAway } from 'react-use';

import { useAppSelector } from 'app/store/hooks';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { getIngredientAmountError } from 'features/recipe/ingredients/form/recipeIngredientForm.utils';
import { selectIngredientAmountUsedByOtherSteps } from 'features/recipe/recipeSlice';
import { getQuantityAsText } from 'features/recipe/review/ingredients/recipeReviewIngredients.utils';
import { recipeStepsFormStrings } from 'features/recipe/steps/form/RecipeStepsForm.constants';
import { AppKeyCode } from 'types/appKeyCode';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';

export interface RecipeStepsFormIngredientQuantityProps {
  ingredientIdx: number;
  ingredient: AppRecipeIngredient;
  quantity: AppRecipeIngredientQuantity;
  selectedStepIndex: number | null;
  onAccept: (amount: string) => void;
  onClose: () => void;
}

const { labels, helperText } = recipeStepsFormStrings;

export const RecipeStepsFormIngredientQuantity: FC<RecipeStepsFormIngredientQuantityProps> =
  memo(function RecipeStepsFormIngredientQuantity({
    ingredient,
    quantity,
    ingredientIdx,
    selectedStepIndex,
    onAccept,
    onClose,
  }) {
    const usedByOtherSteps = useAppSelector(
      useMemo(
        () => selectIngredientAmountUsedByOtherSteps(selectedStepIndex),
        [selectedStepIndex]
      )
    );
    const [amount, setAmount] = useState<string>(`${quantity.amount ?? ''}`);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const ref = useRef(null);
    useClickAway(ref, onClose, ['mouseup']);

    const remainingAmount =
      (ingredient.quantity.amount || 0) -
      (usedByOtherSteps[ingredientIdx] || 0);

    return (
      <Box
        ref={ref}
        sx={{
          border: `1px solid`,
          borderColor: PantryColor.BorderSubtle,
          boxShadow: `0px 2px 40px rgba(0, 0, 0, 0.1)`,
          borderRadius: PantryCornerRadius.Small,
          px: 4,
          pb: 4,
          pt: 10,
          position: 'relative',
          mb: 2,
        }}
      >
        <Button
          hideLabel
          buttonStyle={ButtonStyle.Subtle}
          size={ButtonSize.Small}
          leadingIcon={CloseIcon}
          onClick={() => onClose()}
          label={labels.editIngredientAmountCloseButton}
          type={ButtonType.Button}
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(2),
            color: theme.palette.grey[500],
          })}
        />
        <TextField
          type="text"
          label={`Edit "${ingredient.name}"`}
          id="quantity"
          variant="outlined"
          fullWidth
          autoFocus
          value={amount}
          error={!!errorMessage}
          helperText={
            errorMessage ? (
              <HelperText
                message={errorMessage}
                severity={HelperTextSeverity.Critical}
              />
            ) : (
              <HelperText
                message={helperText.editIngredientAmount}
                severity={HelperTextSeverity.Information}
              />
            )
          }
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{`/ ${getQuantityAsText({
                amount: remainingAmount,
                unit: quantity.unit,
              })}`}</InputAdornment>
            ),
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setErrorMessage(
              getIngredientAmountError({
                amount: event.target.value,
                max: remainingAmount,
              })
            );
            setAmount(event.target.value);
          }}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === AppKeyCode.Enter && !errorMessage) {
              event.preventDefault();
              onAccept(amount);
              onClose();
            }
          }}
        />
      </Box>
    );
  });
