import type { FC } from 'react';
import { memo } from 'react';

import { recipeReviewStrings } from 'features/recipe/review/RecipeReview.constants';
import { RecipeReviewEmptyValue } from 'features/recipe/review/shared/RecipeReviewEmptyValue/RecipeReviewEmptyValue';
import { RecipeReviewListContainer } from 'features/recipe/review/shared/RecipeReviewListContainer/RecipeReviewListContainer';
import { recipeReviewStepsStrings } from 'features/recipe/review/steps/RecipeReviewSteps.constants';
import { RecipeStep } from 'features/recipe/steps/list/RecipeStep';
import type { AppLink } from 'types/appLink';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';

const { sections } = recipeReviewStrings;

export interface RecipeReviewStepsProps {
  steps: AppRecipeStep[];
  emptyLink?: AppLink;
}

export const RecipeReviewSteps: FC<RecipeReviewStepsProps> = memo(
  function RecipeReviewSteps({ steps, emptyLink }) {
    return (
      <RecipeReviewListContainer
        components={{
          title: sections.steps,
          list: (
            <>
              {!steps.length && (
                <RecipeReviewEmptyValue
                  emptyLink={emptyLink}
                  message={recipeReviewStepsStrings.messages.empty}
                />
              )}
              {steps.map((step, index) => (
                <RecipeStep
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  step={step}
                  stepIndex={index}
                  totalSteps={steps.length}
                  sx={{ mb: 6 }}
                />
              ))}
            </>
          ),
        }}
      />
    );
  }
);
