import { TextField, Autocomplete } from '@mui/material';
import { memo } from 'react';
import type { FC } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { TimeField } from 'components/timeField/TimeField';
import { selectConfigsAuthor } from 'features/configs/configsSlice';
import { recipeBasicInformationStrings } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import { RecipeBasicInformationFormContainer } from 'features/recipe/recipeBasicInformation/RecipeBasicInformationFormContainer';
import type { AppRecipeFieldUpdateKeys } from 'features/recipe/recipeSlice';
import {
  selectRecipe,
  recipeFieldUpdated,
  selectRecipeErrors,
  selectRecipeSubmitted,
} from 'features/recipe/recipeSlice';
import { RecipeDescriptionField } from 'features/recipe/shared/RecipeDescriptionField/RecipeDescriptionField';
import { RecipeNameField } from 'features/recipe/shared/RecipeNameField/RecipeNameField';
import {
  selectApplianceTags,
  selectTagsFetching,
} from 'features/referenceData/tags/tagsSlice';

const { ariaLabel, labels, placeholders, hintMessages } =
  recipeBasicInformationStrings;

export const RecipeBasicInformationForm: FC = memo(
  function RecipeBasicInformationForm() {
    const dispatch = useAppDispatch();

    const recipe = useAppSelector(selectRecipe);
    const errors = useAppSelector(selectRecipeErrors);
    const submitted = useAppSelector(selectRecipeSubmitted);
    const applianceTagsList = Object.values(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      useAppSelector(selectApplianceTags(recipe.locale!)) || {}
    );
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const isLoadingTags = useAppSelector(selectTagsFetching(recipe.locale!));
    const {
      name,
      description,
      author,
      prepTime,
      cookTime,
      totalTime,
      applianceReferenceTags,
      serves,
    } = recipe;
    const authorData = useAppSelector(selectConfigsAuthor);

    const hasError = (field: AppRecipeFieldUpdateKeys): boolean =>
      submitted && !!errors[field];

    const gerErrorMessage = (field: AppRecipeFieldUpdateKeys): string =>
      Object.values(errors[field])[0];

    return (
      <RecipeBasicInformationFormContainer
        ariaLabel={ariaLabel.form}
        components={{
          recipeName: (
            <RecipeNameField
              id="recipe-name"
              showErrors={hasError('name')}
              value={name}
              onChange={(change) => {
                dispatch(
                  recipeFieldUpdated({ key: 'name', value: change.value })
                );
              }}
            />
          ),

          description: (
            <RecipeDescriptionField
              id="description"
              value={description}
              onChange={(change) => {
                dispatch(
                  recipeFieldUpdated({
                    key: 'description',
                    value: change.value,
                  })
                );
              }}
            />
          ),

          author: (
            <TextField
              id="author"
              label={labels.authorField}
              type="text"
              placeholder={placeholders.authorField}
              variant="outlined"
              fullWidth
              value={author.name}
              required
              error={hasError('author')}
              helperText={
                hasError('author') ? (
                  <HelperText
                    message={gerErrorMessage('author')}
                    severity={HelperTextSeverity.Critical}
                  />
                ) : (
                  <HelperText
                    message={hintMessages.authorField}
                    severity={HelperTextSeverity.Neutral}
                  />
                )
              }
              onChange={(event) =>
                dispatch(
                  recipeFieldUpdated({
                    key: 'author',
                    value: { ...authorData, name: event.target.value },
                  })
                )
              }
            />
          ),

          applianceTags: (
            <Autocomplete
              multiple
              id="appliance-tags"
              value={applianceReferenceTags || []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              loading={isLoadingTags}
              disableCloseOnSelect
              onChange={(_, options) =>
                dispatch(
                  recipeFieldUpdated({
                    key: 'applianceReferenceTags',
                    value: options,
                  })
                )
              }
              options={applianceTagsList}
              getOptionLabel={(tag) => tag.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={labels.applianceTagsField}
                  placeholder={placeholders.applianceTagsField}
                />
              )}
              renderOption={(props, tag) => (
                <li {...props} key={tag.id}>
                  {tag.name}
                </li>
              )}
            />
          ),

          totalTime: (
            <TimeField
              label={labels.totalTimeField}
              placeholder={placeholders.totalTimeField}
              fullWidth
              variant="outlined"
              required
              onChange={(value) =>
                dispatch(recipeFieldUpdated({ key: 'totalTime', value }))
              }
              errors={hasError('totalTime') ? errors.totalTime : undefined}
              showSeconds={false}
              value={totalTime}
            />
          ),

          prepTime: (
            <TimeField
              label={labels.prepTimeField}
              placeholder={placeholders.prepTimeField}
              fullWidth
              variant="outlined"
              required
              onChange={(value) =>
                dispatch(recipeFieldUpdated({ key: 'prepTime', value }))
              }
              errors={hasError('prepTime') ? errors.prepTime : undefined}
              showSeconds={false}
              value={prepTime}
            />
          ),

          cookTime: (
            <TimeField
              label={labels.cookTimeField}
              placeholder={placeholders.cookingTimeField}
              fullWidth
              variant="outlined"
              required
              onChange={(value) =>
                dispatch(recipeFieldUpdated({ key: 'cookTime', value }))
              }
              errors={hasError('cookTime') ? errors.cookTime : undefined}
              showSeconds={false}
              value={cookTime}
            />
          ),

          serves: (
            <TextField
              id="serves"
              label={labels.servesField}
              type="number"
              placeholder={placeholders.servesField}
              variant="outlined"
              fullWidth
              required
              error={hasError('serves')}
              value={serves || ''}
              helperText={
                hasError('serves') && (
                  <HelperText
                    message={gerErrorMessage('serves')}
                    severity={HelperTextSeverity.Critical}
                  />
                )
              }
              onChange={(event) => {
                const value = parseInt(event.target.value);
                dispatch(
                  recipeFieldUpdated({
                    key: 'serves',
                    value,
                  })
                );
              }}
              inputProps={{ min: 1 }}
            />
          ),
        }}
      />
    );
  }
);
