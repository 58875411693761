import {
  recipeIngredientAmountConstants,
  recipeIngredientFormStrings,
} from 'features/recipe/ingredients/form/RecipeIngredientForm.constants';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';

const { errors } = recipeIngredientFormStrings;

const {
  fractionsSeparator,
  precision: defaultPrecision,
  pattern,
} = recipeIngredientAmountConstants;

const fractionToNumber = (value: string) => {
  const fraction = value.split(fractionsSeparator);
  const number = +fraction[0] / +fraction[1];
  return !Number.isFinite(number) ? 0 : number;
};

export const toNumber = (
  value: string | null,
  { precision }: { precision: number } = {
    precision: defaultPrecision,
  }
): number | null => {
  if (!value) {
    return null;
  }
  const fractionSeparatorIndex = value.indexOf(fractionsSeparator);
  if (fractionSeparatorIndex >= 0) {
    return +fractionToNumber(value).toFixed(precision);
  }
  return Math.trunc(Number(value) * 10 ** precision) / 10 ** precision;
};

interface GetIngredientAmountErrorParams {
  amount: string;
  max?: number;
  usedQuantity?: AppRecipeIngredientQuantity;
}
export const getIngredientAmountError = ({
  amount,
  max,
  usedQuantity,
}: GetIngredientAmountErrorParams): string | null => {
  if (!!amount && !pattern.test(amount)) {
    return errors.quantity.notNumber;
  }
  const value = toNumber(amount);
  if (value === null) {
    return null;
  }
  if (value === 0) {
    return errors.quantity.notZero;
  }
  if (max !== undefined && value > max) {
    return errors.quantity.higherThanMax(max);
  }
  const usedAmount = usedQuantity?.amount;
  if (usedAmount && value < usedAmount) {
    return errors.quantity.lowerThanUsed(usedQuantity);
  }
  return null;
};
