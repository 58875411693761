import type { ApiAplCapability } from 'api/types/appliance/apiAplCapability';
import type { ApiAplCapabilityAttachment } from 'api/types/appliance/apiAplCapabilityAttachment';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRefCapability } from 'api/types/referenceData/apiRefCapability';
import { isApiRefId } from 'api/types/referenceData/apiRefId';
import type { AppCapabilityAllowedPhase } from 'types/appCapabilityAllowedPhase';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import {
  fromApiRefCapabilityAllowedSetting,
  fromApiAplCapabilityAllowedSetting,
} from 'types/appCapabilityAllowedSetting';

export interface AppCapability {
  id: ApiEntityId;
  name: string;
  type: AppCapabilityType;
  allowedSettings: AppCapabilityAllowedSetting[];
  attachments?: ApiAplCapabilityAttachment[];
  allowedPhases: AppCapabilityAllowedPhase[];
}

export enum AppCapabilityType {
  General = 'General',
  AppliancePreset = 'AppliancePreset',
}

export const getCapabilityType = (capabilityId: ApiEntityId) =>
  isApiRefId(capabilityId)
    ? AppCapabilityType.General
    : AppCapabilityType.AppliancePreset;

export const fromApiAplCapability = ({
  id,
  name,
  settings,
  attachments,
  allowedPhases,
}: ApiAplCapability): AppCapability => ({
  id,
  name,
  allowedSettings: settings.map(fromApiAplCapabilityAllowedSetting),
  allowedPhases: allowedPhases ?? [],
  type: getCapabilityType(id),
  attachments,
});

export const fromApiRefCapability = ({
  id,
  name,
  allowedSettings,
  allowedPhases,
}: ApiRefCapability): AppCapability => {
  return {
    id,
    name,
    allowedSettings: allowedSettings.map(fromApiRefCapabilityAllowedSetting),
    allowedPhases,
    type: getCapabilityType(id),
  };
};
