import {
  Button,
  ButtonSize,
  ButtonStyle,
  PantryColor,
  PantryTypography,
  RefreshIcon,
  Tooltip,
  TooltipPosition,
} from '@dropkitchen/pantry-react';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid';
import type { FC, SyntheticEvent } from 'react';
import { useState, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';

import type { ApiDateISO8601 } from 'api/types/common/apiDateISO8601';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import type { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { RecipeType } from 'app/routes/constants';
import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { LocaleStatusBadge } from 'components/Badge/LocaleStatusBadge';
import { RecipeStatusBadge } from 'components/Badge/RecipeStatusBadge';
import { ImageFit } from 'components/Image/Image';
import type { TableColumn, TableRow } from 'components/Table/Table';
import { Table } from 'components/Table/Table';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { selectConfigsIsFeatureEnabled } from 'features/configs/configsSlice';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { RecipeImage } from 'features/recipe/shared/RecipeImage/RecipeImage';
import { EmptyRecipes } from 'features/recipes/list/EmptyRecipes';
import {
  recipesListConstants,
  recipesListStrings,
} from 'features/recipes/list/RecipesList.constants';
import {
  RecipesListTranslateAction,
  RecipesListChangeStatusAction,
  RecipesListDeleteAction,
} from 'features/recipes/list/RecipesListActions';
import { RecipesListPlaceholderImage } from 'features/recipes/list/RecipesListPlaceholderImage';
import {
  RecipesListApplianceSkeleton,
  RecipesListDateSkeleton,
  RecipesListIdSkeleton,
  RecipesListSkeleton,
  RecipesListMainInfoSkeleton,
  RecipesListStateSkeleton,
  RecipesListTranslationsSkeleton,
  RecipesListLocaleSkeleton,
} from 'features/recipes/list/RecipesListSkeleton';
import {
  recipesFetchRequested,
  recipesPaginated,
  selectRecipes,
  selectRecipesFetching,
  selectRowsLoading,
  selectRecipesPage,
  selectRecipesRowCount,
  selectRecipesRowsPerPage,
  selectRecipesSearchTerm,
  selectRecipeTranslations,
  recipeTranslationsFetchRequested,
  selectRecipeType,
  selectRecipesAppliedFilters,
} from 'features/recipes/recipesSlice';
import { RecipesSearch } from 'features/recipes/search/RecipesSearch';
import { appDisplayCodeByLocale } from 'types/appDisplayCodeByLocale';
import { AppFeature } from 'types/appFeature';
import { formatDate, fromISOToDate, isToday, isYesterday } from 'utils/date';
import { generateRecipeHeroImageSource } from 'utils/image';

const { image, table } = recipesListConstants;
const { headers, dates: dateStrings } = recipesListStrings;

interface RecipeMainInfoCellProps {
  id: ApiRcpRecipeId;
  name: string;
  author: string;
  to: string;
}

const RecipeMainInfoCell: FC<RecipeMainInfoCellProps> = memo(
  function RecipeMainInfoCell({ id, name, author, to }) {
    const { width, height } = image;
    /**
     * Request double the required size for the image so it has some room to be
     * adjusted to cover its container without loosing quality due to stretching.
     */
    const maxStretchingRatio = 2;
    const { src } = generateRecipeHeroImageSource({
      recipeId: id,
      width: width * maxStretchingRatio,
      height: height * maxStretchingRatio,
    });

    return (
      <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <MuiLink component={Link} to={to}>
          <RecipeImage
            recipeId={id}
            src={src}
            width={`${width}px`}
            height={`${height}px`}
            alt={name}
            bypassCache
            boxShadow="none"
            fit={ImageFit.Cover}
            error={{
              component: (
                <RecipesListPlaceholderImage width={width} height={height} />
              ),
            }}
          />
        </MuiLink>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <MuiLink
            component={Link}
            to={to}
            sx={{ color: PantryColor.TextDefault }}
          >
            <Typography variant={PantryTypography.Body1SemiBold}>
              {name}
            </Typography>
          </MuiLink>
          <Typography
            variant={PantryTypography.Body2}
            sx={{ color: PantryColor.TextSubtle }}
          >
            by&nbsp;
            <MuiLink
              component={Link}
              to={to}
              sx={{ color: PantryColor.TextDefault }}
            >
              {author}
            </MuiLink>
          </Typography>
        </Box>
      </Box>
    );
  }
);

export interface RecipeTranslationCell {
  id: ApiRcpRecipeId;
  locale: ApiLocale;
  state: ApiRcpRecipeState;
}

export interface RecipeRow extends TableRow {
  name: string;
  state: ApiRcpRecipeState;
  appliances?: ApiTag[];
  author: string;
  modifiedAt: string;
  imageSource: string;
  translations?: RecipeTranslationCell[];
  locale: ApiLocale;
  forkedFromId?: ApiRcpRecipeId;
}

export const generateApplianceTooltip = (tags: ApiTag[]) =>
  tags.reduce(
    (appliances, { name }, index) =>
      index === 0 ? `${name}` : `${appliances}, ${name}`,
    ''
  );

export const RecipesList: FC = function RecipesList() {
  const dispatch = useAppDispatch();
  const recipes = useAppSelector(selectRecipes);
  const page = useAppSelector(selectRecipesPage);
  const rowsPerPage = useAppSelector(selectRecipesRowsPerPage);
  const loading = useAppSelector(selectRecipesFetching);
  const rowCount = useAppSelector(selectRecipesRowCount);
  const rowsLoading = useAppSelector(selectRowsLoading);
  const recipeTranslations = useAppSelector(selectRecipeTranslations);
  const searchTerm = useAppSelector(selectRecipesSearchTerm);
  const recipeType = useAppSelector(selectRecipeType);
  const appliedFilters = useAppSelector(selectRecipesAppliedFilters);
  const isTranslationManagementEnabled = useAppSelector(
    selectConfigsIsFeatureEnabled(AppFeature.TranslationManagement)
  );

  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  const formatRecipeDate = (date: Date): string => {
    if (isToday(date)) {
      return dateStrings.today(formatDate(date, 'HH:MMa'));
    }
    if (isYesterday(date)) {
      return dateStrings.yesterday;
    }
    return formatDate(date, 'd MMM, yyyy');
  };

  useEffect(() => {
    dispatch(recipesFetchRequested());
  }, [dispatch, searchTerm, appliedFilters, page, rowsPerPage]);

  const columns: (TableColumn | null)[] = [
    {
      field: 'recipe-main-info',
      headerName: headers.mainInfo,
      ...table.columns.mainInfo,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListMainInfoSkeleton />;
        }
        return (
          <RecipeMainInfoCell
            id={row.id}
            name={row.name}
            author={row.author}
            to={generateRecipeRoute({
              id: row.id,
              tab: RecipeTabName.Information,
            })}
          />
        );
      },
    },
    {
      field: 'state',
      headerName: headers.status,
      ...table.columns.status,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListStateSkeleton />;
        }
        return <RecipeStatusBadge status={row.state} />;
      },
    },
    isTranslationManagementEnabled
      ? {
          field: 'locale',
          headerName: headers.locale,
          ...table.columns.locale,
          renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
            if (rowsLoading[row.id]) {
              return <RecipesListLocaleSkeleton />;
            }
            return appDisplayCodeByLocale[row.locale];
          },
        }
      : null,
    isTranslationManagementEnabled && recipeType === RecipeType.Core
      ? {
          field: 'translations',
          headerName: headers.translations,
          ...table.columns.translations,
          renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
            const { fetching, apiError, translations } =
              recipeTranslations[row.id] || {};
            if (rowsLoading[row.id] || fetching) {
              return <RecipesListTranslationsSkeleton />;
            }
            if (apiError) {
              return (
                <Button
                  label={table.actions.translations.retryButton}
                  size={ButtonSize.Small}
                  buttonStyle={ButtonStyle.Default}
                  leadingIcon={RefreshIcon}
                  onClick={() =>
                    dispatch(
                      recipeTranslationsFetchRequested({ recipeId: row.id })
                    )
                  }
                />
              );
            }
            if (!translations?.length) {
              return table.emptyCell;
            }
            return (
              <Box sx={{ display: 'flex', flexFlow: 'wrap', gap: 1 }}>
                {translations.map(({ id, state, locale }) => (
                  <LocaleStatusBadge
                    key={id}
                    status={state}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    locale={locale!}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    recipeId={id!}
                  />
                ))}
              </Box>
            );
          },
        }
      : null,
    {
      field: 'appliances',
      headerName: headers.appliances,
      ...table.columns.appliances,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListApplianceSkeleton />;
        }
        if (!row.appliances?.length) {
          return table.emptyCell;
        }
        const firstAppliance = row.appliances[0];
        if (
          row.appliances.length === 1 &&
          firstAppliance.name.length <= table.maxApplianceTagNameLength
        ) {
          return (
            <Typography variant={PantryTypography.Body2}>
              {firstAppliance.name}
            </Typography>
          );
        }
        return (
          <Tooltip
            message={generateApplianceTooltip(row.appliances)}
            hideArrow
            placement={TooltipPosition.BottomStart}
          >
            <Typography
              variant={PantryTypography.Body2}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '180px',
              }}
            >
              <Box component="span">{firstAppliance.name}</Box>
              <Box component="span">{row.appliances.length > 1 && ' ...'}</Box>
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'id',
      headerName: headers.id,
      ...table.columns.id,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListIdSkeleton />;
        }
        const idLink = (
          <MuiLink
            component={Link}
            to={generateRecipeRoute({
              id: row.id,
              tab: RecipeTabName.Information,
            })}
          >
            <Typography
              variant={PantryTypography.Body2}
              sx={{
                color: PantryColor.TextDefault,
              }}
            >
              {row.id}
            </Typography>
          </MuiLink>
        );
        if (hoveredRowId !== row.id || recipeType === RecipeType.Translation) {
          return idLink;
        }
        return (
          <ToggleableFeature
            requires={AppFeature.TranslationManagement}
            components={{ whenDisabled: idLink }}
          >
            <RecipesListChangeStatusAction
              row={row}
              onAction={() => setHoveredRowId(null)}
            />
          </ToggleableFeature>
        );
      },
    },
    {
      field: 'modifiedAt',
      headerName: headers.modifiedAt,
      ...table.columns.modifiedAt,
      renderCell: ({ row }: GridRenderCellParams<undefined, RecipeRow>) => {
        if (rowsLoading[row.id]) {
          return <RecipesListDateSkeleton />;
        }
        if (hoveredRowId !== row.id) {
          return (
            <Typography variant={PantryTypography.Body2}>
              {row.modifiedAt}
            </Typography>
          );
        }
        if (recipeType === RecipeType.Translation) {
          return (
            <>
              <RecipesListChangeStatusAction
                row={row}
                onAction={() => setHoveredRowId(null)}
              />
              <RecipesListDeleteAction
                row={row}
                onAction={() => setHoveredRowId(null)}
              />
            </>
          );
        }
        return (
          <>
            <ToggleableFeature
              requires={AppFeature.TranslationManagement}
              components={{
                whenDisabled: (
                  <RecipesListChangeStatusAction
                    row={row}
                    onAction={() => setHoveredRowId(null)}
                  />
                ),
              }}
            >
              <RecipesListTranslateAction
                row={row}
                existingTranslations={recipeTranslations[row.id]?.translations}
                onAction={() => setHoveredRowId(null)}
              />
            </ToggleableFeature>
            <RecipesListDeleteAction
              row={row}
              onAction={() => setHoveredRowId(null)}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ mb: 8 }}>
        <RecipesSearch />
      </Box>
      {!recipes?.length && !loading ? (
        <EmptyRecipes />
      ) : (
        <Table
          ariaLabel={table.ariaLabel}
          rowHeight={table.rowHeight}
          columns={columns.filter(
            (column): column is TableColumn => column !== null
          )}
          rows={recipes.map(
            ({
              id,
              name,
              author,
              state,
              modifiedAt,
              applianceReferenceTags,
              locale,
              forkedFromId,
            }) => ({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              id: id!,
              name,
              state,
              modifiedAt: formatRecipeDate(
                fromISOToDate(modifiedAt as ApiDateISO8601)
              ),
              author: author.name,
              appliances: applianceReferenceTags,
              locale,
              forkedFromId,
            })
          )}
          page={page}
          rowsPerPage={rowsPerPage}
          loading={loading}
          rowCount={rowCount}
          checkboxSelection
          components={{
            loadingOverlay: RecipesListSkeleton,
          }}
          componentsProps={{
            row: {
              onMouseEnter: (event: SyntheticEvent<HTMLElement>) => {
                event.preventDefault();
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                setHoveredRowId(event.currentTarget.dataset.id!);
              },
              onMouseLeave: (event: SyntheticEvent<HTMLElement>) => {
                event.preventDefault();
                setHoveredRowId(null);
              },
            },
          }}
          onPaginationChange={(event) => dispatch(recipesPaginated(event))}
        />
      )}
    </>
  );
};
