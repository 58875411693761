import type { ApiRcpRecipeStep } from 'api/types/recipe/apiRcpRecipeStep';
import { fromAppCapabilitySettingValue } from 'api/types/recipe/recipeWithRefs/apiRcpCapabilitySettingValueWithRefs';
import type { ApiRcpCapabilityWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpCapabilityWithRefs';
import type { ApiRcpRecipeStepIngredientWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeStepIngredientWithRefs';
import { fromAppRecipeStepIngredient } from 'api/types/recipe/recipeWithRefs/apiRcpRecipeStepIngredientWithRefs';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';

export interface ApiRcpRecipeStepWithRefs
  extends Pick<ApiRcpRecipeStep, 'sourceText' | 'text'> {
  ingredients?: ApiRcpRecipeStepIngredientWithRefs[];
  capability?: ApiRcpCapabilityWithRefs;
}

export function fromAppRecipeStep({
  text,
  ingredients,
  capability,
}: AppRecipeStep): ApiRcpRecipeStepWithRefs {
  return {
    text,
    ingredients: ingredients
      ? ingredients.map(fromAppRecipeStepIngredient)
      : undefined,
    capability: capability && {
      referenceCapabilityId: capability.id,
      settings: capability.settings?.map((setting) => ({
        referenceSettingId: setting.id,
        value: fromAppCapabilitySettingValue(setting.value),
      })),
      ...(capability.phase && { phaseId: capability.phase?.id }),
    },
  };
}
