import { ApiCapabilitySettingType } from 'api/types/common/apiCapabilitySettingType';
import type { ApiRcpCapabilitySettingValueNominalWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpCapabilitySettingValueNominalWithRefs';
import type { ApiRcpCapabilitySettingValueNumericWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpCapabilitySettingValueNumericWithRefs';
import type { ApiRefCapabilitySettingValueBoolean } from 'api/types/referenceData/apiRefCapabilitySettingValueBoolean';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import type { AppCapabilitySettingValue } from 'types/appCapabilitySettingValue';
import { fromAppTimeToSeconds } from 'utils/convertTimes';

export type ApiRcpCapabilitySettingValueWithRefs =
  | ApiRefCapabilitySettingValueBoolean
  | ApiRcpCapabilitySettingValueNumericWithRefs
  | ApiRcpCapabilitySettingValueNominalWithRefs;

export function fromAppCapabilitySettingValue(
  value: AppCapabilitySettingValue
): ApiRcpCapabilitySettingValueWithRefs {
  const { type } = value;
  if (type === AppCapabilitySettingType.Numeric) {
    return {
      type: ApiCapabilitySettingType.Numeric,
      value: value.value,
      referenceUnitId: value.referenceUnit.id,
    };
  }
  if (type === AppCapabilitySettingType.Time) {
    return {
      type: ApiCapabilitySettingType.Numeric,
      value: fromAppTimeToSeconds(value.value),
      referenceUnitId: value.referenceUnit.id,
    };
  }
  if (type === AppCapabilitySettingType.Nominal) {
    return {
      type: ApiCapabilitySettingType.Nominal,
      referenceValueId: value.referenceValue.id,
    };
  }
  return {
    type: ApiCapabilitySettingType.Boolean,
    value: value.value,
  };
}
