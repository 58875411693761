export const recipeReviewBasicInformationStrings = {
  emptyRequiredFields: {
    message: 'Empty required field',
    links: {
      recipeNameField: 'Click here to add a recipe name',
      authorField: 'Click here to add a recipe author',
      servesField: 'Click here to add servings',
      totalTimeField: 'Click here to add total time',
      prepTimeField: 'Click here to add prep time',
      cookTimeField: 'Click here to add cooking time',
    },
  },
} as const;
