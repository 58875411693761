import isEmpty from 'lodash/isEmpty';

import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type { AppCapabilityAllowedSettingValueNumeric } from 'types/appCapabilityAllowedSettingValueNumeric';
import type { AppCapabilitySetting } from 'types/appCapabilitySetting';
import type {
  AppCapabilityAllowedSettingDependency,
  AppCapabilityAllowedSettingDependencyAllowedValues,
} from 'types/appCapabilitySettingDependency';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import { fromAppTimeToSeconds } from 'utils/convertTimes';

export type CapabilitySettingFieldDependsOn = Record<
  ApiEntityId,
  AppCapabilityAllowedSettingDependencyAllowedValues
>;

export type CapabilitySettingFieldDependents = Record<
  ApiEntityId,
  {
    setting: AppCapabilityAllowedSetting;
    dependency: AppCapabilityAllowedSettingDependencyAllowedValues;
  }
>;

export type CapabilitySettingFieldDependencies = {
  dependents: Record<ApiEntityId, CapabilitySettingFieldDependents>;
  dependsOn: Record<ApiEntityId, CapabilitySettingFieldDependsOn>;
};

export const generateCapabilitySettingFieldDependsOn = (
  settings?: AppCapabilityAllowedSettingDependency[]
): CapabilitySettingFieldDependsOn | undefined => {
  if (!settings?.length) {
    return undefined;
  }
  return settings.reduce<CapabilitySettingFieldDependsOn>(
    (dependencies, dependency) => {
      return {
        ...dependencies,
        [dependency.referenceSettingId]: dependency.allowedValues,
      };
    },
    {}
  );
};

export const areDependenciesMet = (
  dependencies: CapabilitySettingFieldDependsOn,
  settings: AppCapabilitySetting[]
) =>
  isEmpty(dependencies) ||
  settings.some(({ id, value }) => {
    const type =
      value.type === AppCapabilitySettingType.Time
        ? AppCapabilitySettingType.Numeric
        : value.type;
    const dependency = dependencies[id]?.[type];
    if (dependency === undefined) {
      return false;
    }
    if (value.type === AppCapabilitySettingType.Nominal) {
      return (dependency as ApiEntityId[]).includes(value.referenceValue.id);
    }
    if (value.type === AppCapabilitySettingType.Boolean) {
      return (dependency as boolean) === value.value;
    }
    return (dependency as AppCapabilityAllowedSettingValueNumeric[]).some(
      ({ min, max, step, unit }) => {
        const numericValue =
          value.type === AppCapabilitySettingType.Time
            ? fromAppTimeToSeconds(value.value)
            : value.value;
        if (unit.id !== value.referenceUnit.id) {
          return false;
        }
        if (min !== undefined && numericValue < min) {
          return false;
        }
        if (max !== undefined && numericValue > max) {
          return false;
        }
        if (step !== undefined && !Number.isInteger(numericValue / step)) {
          return false;
        }
        return true;
      }
    );
  });
