import { fetchJson } from 'api/fetchJson';
import { configPath } from 'api/paths';
import { HttpMethod } from 'api/types';
import type { ApiAplCapabilityType } from 'api/types/appliance/apiAplCapabilityType';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { AppFeature } from 'types/appFeature';
import type { AppRecipeAuthor } from 'types/recipe/appRecipeAuthor';
import { getCurrentTime } from 'utils/getCurrentTime';

export type AppConfigAuthor = Pick<AppRecipeAuthor, 'image' | 'url'>;

export interface AppConfig {
  locales: ApiLocale[];
  author: AppConfigAuthor;
  features: Record<AppFeature, boolean>;
  supportedApplianceCapabilityTypes: ApiAplCapabilityType[];
}

export const apiGetConfig = async () =>
  fetchJson<AppConfig>({
    httpMethod: HttpMethod.Get,
    url: `${configPath}?t=${getCurrentTime()}`,
    isAnonymous: true,
  });
