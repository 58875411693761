import type { ApiDateISO8601 } from 'api/types/common/apiDateISO8601';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiRcpRecipe } from 'api/types/recipe/apiRcpRecipe';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import type { ApiRusRecipe } from 'api/types/recipeUnderstanding/apiRusRecipe';
import type { TagsById } from 'features/referenceData/tags/tagsSlice';
import type { AppTime } from 'types/appTime';
import type { AppRecipeAuthor } from 'types/recipe/appRecipeAuthor';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import { fromApiRcpRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';
import { fromApiRcpRecipeStep } from 'types/recipe/appRecipeStep';
import { fromISOToAppTime, noTime } from 'utils/convertTimes';

export interface AppRecipe {
  id?: ApiRcpRecipeId;
  locale?: ApiLocale;
  ingredients: AppRecipeIngredient[];
  name: string;
  description?: string;
  author: AppRecipeAuthor;
  prepTime: AppTime;
  cookTime: AppTime;
  totalTime: AppTime;
  state: ApiRcpRecipeState;
  steps: AppRecipeStep[];
  createdAt?: ApiDateISO8601;
  modifiedAt?: ApiDateISO8601;
  applianceReferenceTags?: ApiTag[];
  generalTags?: ApiTag[];
  serves: number;
  forkedFromId?: ApiRcpRecipeId;
}

export function fromApiRcpRecipe(
  {
    id,
    description,
    prepTime,
    cookTime,
    totalTime,
    ingredients,
    name,
    steps,
    state,
    createdAt,
    modifiedAt,
    author,
    referenceTags,
    serves,
    locale,
    forkedFromId,
  }: ApiRcpRecipe,
  applianceTags: TagsById
): AppRecipe {
  return {
    description,
    prepTime: prepTime
      ? fromISOToAppTime(prepTime, { removeZeros: true })
      : noTime,
    cookTime: cookTime
      ? fromISOToAppTime(cookTime, { removeZeros: true })
      : noTime,
    totalTime: totalTime
      ? fromISOToAppTime(totalTime, { removeZeros: true })
      : noTime,
    id,
    ingredients: ingredients.map((ingredient) =>
      fromApiRcpRecipeIngredient(ingredient)
    ),
    name,
    steps: steps.map((apiStep) =>
      fromApiRcpRecipeStep({ apiStep, recipeIngredients: ingredients })
    ),
    state,
    createdAt,
    modifiedAt,
    author: {
      name: author?.name || '',
      image: author?.image || '',
      url: author?.url || '',
    },
    applianceReferenceTags: referenceTags?.filter(
      (tag) => !!applianceTags[tag.id]
    ),
    generalTags: referenceTags?.filter((tag) => !applianceTags[tag.id]),
    serves,
    locale,
    forkedFromId,
  };
}

export function fromApiRusRecipe(
  {
    description,
    prepTime,
    cookTime,
    totalTime,
    ingredients,
    name,
    steps,
    referenceTags,
    author,
    serves,
  }: ApiRusRecipe,
  { applianceTags, locale }: { applianceTags: TagsById; locale: ApiLocale }
): AppRecipe {
  return {
    locale,
    description,
    prepTime: prepTime
      ? fromISOToAppTime(prepTime, { removeZeros: true })
      : noTime,
    cookTime: cookTime
      ? fromISOToAppTime(cookTime, { removeZeros: true })
      : noTime,
    totalTime: totalTime
      ? fromISOToAppTime(totalTime, { removeZeros: true })
      : noTime,
    ingredients: ingredients.map((ingredient) =>
      fromApiRcpRecipeIngredient(ingredient)
    ),
    name,
    steps: steps.map((apiStep) =>
      fromApiRcpRecipeStep({ apiStep, recipeIngredients: ingredients })
    ),
    state: ApiRcpRecipeState.Draft,
    applianceReferenceTags: referenceTags?.filter(
      (tag) => !!applianceTags[tag.id]
    ),
    generalTags: referenceTags?.filter((tag) => !applianceTags[tag.id]),
    author: {
      name: author?.name || '',
      image: author?.image || '',
      url: author?.url || '',
    },
    serves,
  };
}
