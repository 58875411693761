import type { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetPreparations } from 'api/preparations';
import type { ApiList, ApiResponse } from 'api/types';
import type { ApiIngredientPreparation } from 'api/types/common/apiIngredientPreparation';
import { errorOccurred } from 'features/error/errorSlice';
import {
  preparationsFetchFailed,
  preparationsFetching,
  preparationsFetchRequested,
  preparationsFetchSucceed,
  selectShouldFetchPreparations,
} from 'features/referenceData/preparations/preparationsSlice';
import type { ReferenceDataFetchActionPayload } from 'features/referenceData/types/referenceDataFetchActionPayload';

export const apiFetchPreparationsSaga =
  createApiRequestSaga(apiGetPreparations);

export function* fetchPreparations({
  payload: { locale },
}: PayloadAction<ReferenceDataFetchActionPayload>) {
  const shouldFetch = (yield select(
    selectShouldFetchPreparations(locale)
  )) as boolean;
  if (!shouldFetch) {
    return;
  }

  yield put(preparationsFetching({ locale }));
  const response = (yield call(apiFetchPreparationsSaga, {
    locale,
  })) as ApiResponse<ApiList<ApiIngredientPreparation>>;
  if (!response.ok) {
    yield put(
      preparationsFetchFailed({ locale, data: response.details.message })
    );
    yield put(errorOccurred(response.details.message));
    return;
  }
  yield put(preparationsFetchSucceed({ locale, data: response.data.items }));
}

function* preparationsFetchWatcher() {
  yield takeEvery(preparationsFetchRequested, fetchPreparations);
}

export const preparationsSagas = [preparationsFetchWatcher];
