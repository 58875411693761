import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRcpRecipeStepCapabilitySetting } from 'api/types/recipe/apiRcpRecipeStepCapabilitySetting';
import type { ApiRefCapabilitySettingValueNumeric } from 'api/types/referenceData/apiRefCapabilitySettingValueNumeric';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import type { AppCapabilitySettingValue } from 'types/appCapabilitySettingValue';
import { isTimeSettingId } from 'types/appCapabilitySettings.utils';
import { fromSecondsToAppTime } from 'utils/convertTimes';

export interface AppCapabilitySetting {
  id: ApiEntityId;
  name: string;
  value: AppCapabilitySettingValue;
}

export function fromApiRcpRecipeStepCapabilitySetting({
  referenceSetting,
  value,
}: ApiRcpRecipeStepCapabilitySetting): AppCapabilitySetting {
  const newValue = isTimeSettingId(referenceSetting.id)
    ? {
        referenceUnit: (value as ApiRefCapabilitySettingValueNumeric)
          .referenceUnit,
        type: AppCapabilitySettingType.Time,
        value: fromSecondsToAppTime(
          (value as ApiRefCapabilitySettingValueNumeric).value,
          { removeZeros: true }
        ),
      }
    : value;

  return {
    id: referenceSetting.id,
    name: referenceSetting.name,
    value: newValue as AppCapabilitySettingValue,
  };
}
