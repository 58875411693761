import type { FC } from 'react';
import { memo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { publishConfirmationDialogStrings } from 'features/recipe/PublishConfirmationDialog/PublishConfirmationDialog.constants';
import {
  recipePublishCanceled,
  recipePublishConfirmed,
  selectRecipePublishConfirmationRequired,
} from 'features/recipe/recipeSlice';

const { title, body, confirmButton, cancelButton } =
  publishConfirmationDialogStrings;

export const PublishConfirmationDialog: FC = memo(
  function PublishConfirmationDialog() {
    const dispatch = useAppDispatch();

    const isConfirmationRequired = useAppSelector(
      selectRecipePublishConfirmationRequired
    );

    return (
      <ConfirmationDialog
        isOpen={isConfirmationRequired}
        text={{ title, body, confirmButton, cancelButton }}
        onClose={(hasConfirmed) =>
          dispatch(
            hasConfirmed ? recipePublishConfirmed() : recipePublishCanceled()
          )
        }
      />
    );
  }
);
