import {
  ChevronLeftIcon,
  ChevronRightIcon,
  FirstPageIcon,
  LastPageIcon,
  PantryColor,
} from '@dropkitchen/pantry-react';
import {
  Box,
  IconButton,
  iconButtonClasses,
  TablePagination as MuiTablePagination,
  tablePaginationClasses,
} from '@mui/material';
import type { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageSizeSelector,
} from '@mui/x-data-grid';
import { memo } from 'react';

import type { TableProps } from 'components/Table/Table';
import { tableDefaults } from 'components/Table/Table.constants';

const { ariaLabels, firstPage } = tableDefaults;

const TablePaginationActions = memo(function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) {
  const pageCount = () => Math.ceil(count / rowsPerPage) - 1;

  return (
    <Box sx={{ flexShrink: 0, ml: 5 }}>
      <IconButton
        onClick={(e) => onPageChange(e, firstPage)}
        disabled={page === firstPage}
        aria-label={ariaLabels.firstPage}
      >
        <FirstPageIcon size={24} />
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, page - 1)}
        disabled={page === firstPage}
        aria-label={ariaLabels.previousPage}
      >
        <ChevronLeftIcon size={24} />
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, page + 1)}
        disabled={page >= pageCount()}
        aria-label={ariaLabels.nextPage}
      >
        <ChevronRightIcon size={24} />
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, Math.max(firstPage, pageCount()))}
        disabled={page >= pageCount()}
        aria-label={ariaLabels.lastPage}
      >
        <LastPageIcon size={24} />
      </IconButton>
    </Box>
  );
});

export type TablePaginationProps = Pick<
  TableProps,
  'rowCount' | 'rowsPerPageOptions'
>;

export const TablePagination = function TablePagination({
  rowsPerPageOptions,
  rowCount,
}: TablePaginationProps) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const rowsPerPage = useGridSelector(apiRef, gridPageSizeSelector);
  const pageCount = () =>
    Math.max(Math.ceil(rowCount / rowsPerPage) - 1, firstPage);

  return (
    <MuiTablePagination
      component="div"
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      page={Math.min(page, pageCount())}
      count={rowCount}
      onPageChange={(_e, value) => apiRef.current.setPage(value)}
      onRowsPerPageChange={(event) =>
        apiRef.current.setPageSize(+event.target.value)
      }
      ActionsComponent={TablePaginationActions}
      sx={{
        [`&.${tablePaginationClasses.root}`]: {
          width: '100%',
          color: PantryColor.TextDefault,

          [`& .${tablePaginationClasses.toolbar}`]: {
            display: 'grid',
            gridTemplateColumns: 'auto auto 1fr auto auto',
            gridTemplateAreas:
              '"rows-per-page-label rows-per-page-selector space page-label page-selector"',
            pl: 2,
          },

          [`& .${tablePaginationClasses.spacer}`]: {
            gridArea: 'space',
          },

          [`& .${tablePaginationClasses.selectLabel}`]: {
            color: PantryColor.TextSubtle,
            gridArea: 'rows-per-page-label',
          },

          [`& .${tablePaginationClasses.select}`]: {
            gridArea: 'rows-per-page-selector',
            border: '1px solid',
            borderColor: PantryColor.BorderSubtle,
            borderRadius: 0.5,
          },

          [`& .${tablePaginationClasses.displayedRows}`]: {
            color: PantryColor.TextSubtle,
            gridArea: 'page-label',
          },

          [`& .${tablePaginationClasses.actions}`]: {
            gridArea: 'page-selector',
          },

          [`& .${iconButtonClasses.root}`]: {
            color: PantryColor.IconDefault,

            [`&.${iconButtonClasses.disabled}`]: {
              color: PantryColor.IconMuted,
            },
          },
        },
      }}
    />
  );
};
