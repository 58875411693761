import {
  Button,
  ButtonSize,
  ButtonStyle,
  CloseIcon,
  PantryColor,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import type { Breakpoint } from '@mui/material';
import {
  Box,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogActions as MuiDialogActions,
} from '@mui/material';
import type { FC, ReactNode, SyntheticEvent } from 'react';
import { memo } from 'react';

import { SurfaceType, WithSurface } from 'components/WithSurface/WithSurface';

/**
 * Extends from the ones listed for the onClose function of MUI Dialog
 * Check {@link https://mui.com/material-ui/api/dialog/#props}
 */
export enum DialogCloseReason {
  EscapeKeyDown = 'escapeKeyDown',
  BackdropClick = 'backdropClick',
  CloseButtonClick = 'closeButtonClick',
}

export interface DialogProps {
  components: { content?: ReactNode; buttons: ReactNode };
  isOpen: boolean;
  hasCloseButton?: boolean;
  onClose: (reason: DialogCloseReason) => void;
  onSubmit: (event: SyntheticEvent) => void;
  size?: Breakpoint;
  text?: string;
  title: string;
}

export const Dialog: FC<DialogProps> = memo(function Dialog({
  components: { content, buttons },
  isOpen,
  hasCloseButton = true,
  onClose,
  onSubmit,
  size = 'xs',
  text,
  title,
}) {
  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <WithSurface surface={SurfaceType.Light}>
      <MuiDialog
        open={isOpen}
        onClose={(_e, reason) => onClose(reason as DialogCloseReason)}
        fullWidth
        maxWidth={size}
      >
        <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
          <MuiDialogTitle
            sx={{
              typography: PantryTypography.Subtitle1,
              px: 6,
              py: 6,
              color: PantryColor.TextDefault,
            }}
          >
            <Box sx={{ display: 'flex', gap: 7 }}>
              {title}
              {hasCloseButton && (
                <Box>
                  <Button
                    hideLabel
                    buttonStyle={ButtonStyle.Subtle}
                    size={ButtonSize.Medium}
                    leadingIcon={CloseIcon}
                    onClick={() => onClose(DialogCloseReason.CloseButtonClick)}
                    label="Close"
                    sx={(theme) => ({
                      position: 'absolute',
                      right: theme.spacing(4),
                      top: theme.spacing(4),
                      color: theme.palette.grey[500],
                    })}
                  />
                </Box>
              )}
            </Box>
          </MuiDialogTitle>
          <MuiDialogContent
            sx={{ py: 6, px: 6, color: PantryColor.TextDefault }}
          >
            {text && <MuiDialogContentText>{text}</MuiDialogContentText>}
            {content && (
              <Box component="div" sx={{ pt: 2 }}>
                {content}
              </Box>
            )}
          </MuiDialogContent>
          <Box
            component="hr"
            sx={{
              mx: 6,
              my: 0,
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: PantryColor.BorderDefault,
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
            }}
          />
          <MuiDialogActions sx={{ px: 6, pt: 8, pb: 6 }}>
            {buttons}
          </MuiDialogActions>
        </Box>
      </MuiDialog>
    </WithSurface>
  );
});
