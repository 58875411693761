import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  CloseIcon,
  DeleteIcon,
  PantryColor,
  PantryTypography,
  DragHandleIcon,
} from '@dropkitchen/pantry-react';
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import type { ChangeEvent, FC, SyntheticEvent } from 'react';
import { useCallback, useMemo, useState, useEffect, memo } from 'react';

import type { ApiIngredient } from 'api/types/common/apiIngredient';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import type { DropResult } from 'components/DragAndDrop/DragZone';
import { DragZone } from 'components/DragAndDrop/DragZone';
import { Draggable } from 'components/DragAndDrop/Draggable';
import { ErrorHelperText } from 'components/ErrorHelperText/ErrorHelperText';
import { ListRow } from 'components/ListRow/ListRow';
import { recipeIngredientFormStrings } from 'features/recipe/ingredients/form/RecipeIngredientForm.constants';
import {
  getIngredientAmountError,
  toNumber,
} from 'features/recipe/ingredients/form/recipeIngredientForm.utils';
import {
  selectIngredient as selectFormIngredient,
  selectAmount as selectFormAmount,
  selectIndex as selectFormIndex,
  selectPreparations as selectFormPreparations,
  selectUnit as selectFormUnit,
  ingredientUpdated,
  ingredientPreparationsUpdated,
  ingredientUnitUpdated,
  ingredientAmountUpdated,
  ingredientReset,
  selectIsIngredientUnsaved,
  selectIsFormShowing,
  ingredientPreparationMoved,
} from 'features/recipe/ingredients/form/recipeIngredientFormSlice';
import {
  recipeIngredientAdded,
  recipeIngredientUpdated,
  selectRecipeIngredient,
  selectRecipeIngredientUsage,
  selectRecipeLocale,
} from 'features/recipe/recipeSlice';
import {
  selectIngredients,
  selectIngredient,
  selectIngredientsFetching,
} from 'features/referenceData/ingredients/ingredientsSlice';
import {
  selectPreparations,
  selectPreparationsFetching,
} from 'features/referenceData/preparations/preparationsSlice';
import { disambiguateTerms } from 'features/referenceData/referenceData.utils';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';

interface RecipeIngredientFormProps {
  onCancel: () => void;
}

const { labels, messages } = recipeIngredientFormStrings;

export const RecipeIngredientForm: FC<RecipeIngredientFormProps> = memo(
  function RecipeIngredientForm({ onCancel }) {
    const dispatch = useAppDispatch();

    const locale = useAppSelector(selectRecipeLocale);
    const ingredients = useAppSelector(selectIngredients(locale));
    const preparations = useAppSelector(selectPreparations(locale));
    const selectedIngredientIndex = useAppSelector(selectFormIndex);
    const isFormShowing = useAppSelector(selectIsFormShowing);
    const ingredientUsage = useAppSelector(
      selectRecipeIngredientUsage(selectedIngredientIndex)
    );

    const selectedRecipeIngredient = useAppSelector(
      selectRecipeIngredient(selectedIngredientIndex)
    );

    const selectIngredientMemoized = useMemo(
      () => selectIngredient(selectedRecipeIngredient?.id || '', locale),
      [selectedRecipeIngredient, locale]
    );
    const selectedIngredient = useAppSelector(selectIngredientMemoized);
    const isLoadingIngredients = useAppSelector(
      selectIngredientsFetching(locale)
    );
    const isLoadingPreparations = useAppSelector(
      selectPreparationsFetching(locale)
    );

    const ingredient = useAppSelector(selectFormIngredient);
    const amount = useAppSelector(selectFormAmount);
    const unit = useAppSelector(selectFormUnit);
    const ingredientPreparations = useAppSelector(selectFormPreparations);
    const hasChanges = useAppSelector(selectIsIngredientUnsaved);

    const [quantityError, setQuantityError] = useState<string | null>(null);

    const hasError = !!quantityError;

    useEffect(() => {
      if (ingredients?.length && selectedIngredient) {
        dispatch(ingredientUpdated(selectedIngredient));
      }
    }, [dispatch, ingredients, selectedIngredient]);

    useEffect(() => {
      // Only load from recipe slice store if there is selected ingredient and there is no changes
      if (selectedIngredientIndex !== null && !hasChanges) {
        dispatch(ingredientUpdated(selectedIngredient ?? null));
        dispatch(
          ingredientAmountUpdated(
            selectedRecipeIngredient?.quantity.amount
              ? `${selectedRecipeIngredient.quantity.amount}`
              : ''
          )
        );
        dispatch(
          ingredientUnitUpdated(selectedRecipeIngredient?.quantity.unit ?? null)
        );
        dispatch(
          ingredientPreparationsUpdated(
            selectedRecipeIngredient?.preparations ?? []
          )
        );
      }
    }, [
      dispatch,
      selectedRecipeIngredient,
      selectedIngredient,
      selectedIngredientIndex,
      hasChanges,
    ]);

    useEffect(() => {
      setQuantityError(null);
    }, [selectedIngredientIndex]);

    useEffect(() => {
      // If we switch tabs, we need to set the errors when we come back,
      // but only if we have typed anything in the quantity field,
      // because we accept null as quantity
      if (amount) {
        setQuantityError(
          getIngredientAmountError({
            amount,
            usedQuantity: ingredientUsage?.used.quantity,
          })
        );
      }
    }, [amount, ingredientUsage]);

    const disambiguatedIngredients = useMemo(
      () => disambiguateTerms(ingredients ?? []),
      [ingredients]
    );

    const disambiguatedUnits = useMemo(
      () =>
        ingredient?.allowedUnits
          ? disambiguateTerms(ingredient.allowedUnits)
          : {},
      [ingredient]
    );

    const disambiguatedPreparations = useMemo(
      () => disambiguateTerms(preparations ?? []),
      [preparations]
    );

    const resetForm = useCallback(() => {
      setQuantityError(null);
      dispatch(ingredientReset());
    }, [dispatch]);

    const handleIngredientChange = (
      _e: SyntheticEvent,
      value: ApiIngredient | null
    ) => {
      dispatch(ingredientUpdated(value));
      // Reset other form fields as all are dependant from the ingredient
      dispatch(ingredientAmountUpdated(''));
      dispatch(ingredientUnitUpdated(null));
      dispatch(ingredientPreparationsUpdated([]));
    };

    const handleRemovePreparation = (preparationId: ApiRefId) => {
      dispatch(
        ingredientPreparationsUpdated(
          ingredientPreparations.filter(
            (preparation) => preparation.id !== preparationId
          )
        )
      );
    };

    const handleSubmit = (e: SyntheticEvent) => {
      e.preventDefault();

      if (!ingredient || !unit || hasError) {
        return;
      }

      const newIngredient: AppRecipeIngredient = {
        id: ingredient.id,
        name: ingredient.name,
        quantity: {
          amount: toNumber(amount),
          unit,
        },
        ...(ingredientPreparations.length > 0 && {
          preparations: ingredientPreparations,
        }),
      };

      if (selectedIngredientIndex !== null) {
        dispatch(
          recipeIngredientUpdated({
            index: selectedIngredientIndex,
            ingredient: newIngredient,
          })
        );
        return;
      }

      dispatch(recipeIngredientAdded(newIngredient));

      // Clear the form to add a new ingredient
      resetForm();
    };

    const handleCancel = () => {
      resetForm();
      onCancel();
    };

    const handleDropPreparation = ({ from, to }: DropResult) => {
      dispatch(ingredientPreparationMoved({ from, to }));
    };

    const isSaveEnabled = ingredient && unit && !hasError;

    const getFormTitle = () =>
      selectedIngredientIndex === null
        ? labels.formAdd
        : labels.formEdit(selectedRecipeIngredient?.name ?? '');

    if (!isFormShowing) {
      return (
        <Typography
          variant={PantryTypography.Body1}
          color={PantryColor.TextSubtle}
          align="center"
          sx={{ mt: 3 }}
        >
          {messages.noIngredientSelected}
        </Typography>
      );
    }

    return (
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        aria-label={getFormTitle()}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
          }}
        >
          <Typography
            variant={PantryTypography.Body1SemiBold}
            color={PantryColor.TextDefault}
          >
            {getFormTitle()}
          </Typography>
          <Button
            hideLabel
            buttonStyle={ButtonStyle.Subtle}
            size={ButtonSize.Medium}
            leadingIcon={CloseIcon}
            onClick={handleCancel}
            label="Close"
          />
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Autocomplete
              data-testid="ingredient"
              value={ingredient}
              onChange={handleIngredientChange}
              options={ingredients || []}
              getOptionLabel={(value) =>
                disambiguatedIngredients[value.id] ?? value.name
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              loading={isLoadingIngredients}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  required
                  variant="outlined"
                  label={labels.ingredientField}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} direction="row">
              <Grid item xs={6}>
                <TextField
                  type="text"
                  label="Quantity"
                  id="quantity"
                  variant="outlined"
                  fullWidth
                  value={amount}
                  error={!!quantityError}
                  helperText={
                    !!quantityError && (
                      <ErrorHelperText message={quantityError} />
                    )
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setQuantityError(
                      getIngredientAmountError({
                        amount: event.target.value,
                        usedQuantity: ingredientUsage?.used.quantity,
                      })
                    );
                    dispatch(ingredientAmountUpdated(event.target.value));
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  data-testid="units"
                  options={ingredient?.allowedUnits || []}
                  getOptionLabel={(value) =>
                    disambiguatedUnits[value.id] ?? value.name
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={unit}
                  onChange={(_e, value) => {
                    dispatch(ingredientUnitUpdated(value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      label="Unit"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              data-testid="preparations"
              options={preparations || []}
              getOptionLabel={(preparation) =>
                disambiguatedPreparations[preparation.id] ?? preparation.name
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={ingredientPreparations}
              loading={isLoadingPreparations}
              onChange={(_event, newValue) => {
                dispatch(ingredientPreparationsUpdated(newValue));
              }}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Preparations"
                />
              )}
              renderOption={(props, option, { selected }) => {
                return (
                  <Box
                    component="li"
                    {...props}
                    key={option.id}
                    sx={{
                      typography: PantryTypography.Body2,
                      color: PantryColor.TextDefault,
                      pl: 0,
                    }}
                  >
                    <Checkbox checked={selected} />
                    {disambiguatedPreparations[option.id] ?? option.name}
                  </Box>
                );
              }}
              renderTags={() => null}
            />
          </Grid>
          {!!ingredientPreparations.length && (
            <Grid item xs={12}>
              <Typography
                variant={PantryTypography.Body2SemiBold}
                color={PantryColor.FrescoPrimary}
              >
                {labels.addedPreparations}
              </Typography>
              <Divider
                sx={{ borderColor: PantryColor.BorderSubtle, mb: 3, mt: 2 }}
              />
              <DragZone
                dragZoneId="preparations-list"
                onDrop={handleDropPreparation}
                componentProps={{ placeholder: { sx: { pb: 2 } } }}
              >
                {ingredientPreparations.map(({ name, id }, index) => {
                  return (
                    <Draggable
                      key={id}
                      draggableId={id}
                      index={index}
                      renderContent={({ dragging, ...props }) => (
                        <ListRow
                          title={name}
                          icon={
                            <DragHandleIcon
                              size={16}
                              color={PantryColor.IconDefault}
                            />
                          }
                          secondaryAction={
                            <Button
                              buttonStyle={ButtonStyle.Subtle}
                              hideLabel
                              label={labels.removePreparationButton(name)}
                              leadingIcon={DeletePreparationIcon}
                              onClick={() => handleRemovePreparation(id)}
                              size={ButtonSize.Small}
                              sx={{ mr: 2 }}
                            />
                          }
                          sx={{
                            mb: 2,
                            ...(dragging && { transform: 'rotate(3deg)' }),
                          }}
                          {...props}
                        />
                      )}
                    />
                  );
                })}
              </DragZone>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type={ButtonType.Submit}
              label="Save"
              buttonStyle={ButtonStyle.Emphasis}
              size={ButtonSize.Large}
              fullWidth
              disabled={!isSaveEnabled}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
);

const DeletePreparationIcon: FC = memo(function DeletePreparationIcon() {
  return <DeleteIcon size={16} color={PantryColor.IconDefault} />;
});
