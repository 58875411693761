import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createAction, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import { RecipeType } from 'app/routes/constants';
import type { RootState } from 'app/store/rootReducer';
import type { TablePaginationEvent } from 'components/Table/Table';
import { tableDefaults } from 'components/Table/Table.constants';
import { authSignOutFinished } from 'features/auth/authSlice';
import { selectConfigsFeatures } from 'features/configs/configsSlice';
import type {
  DeleteRecipePayload,
  ForkRecipesPayload,
} from 'features/recipes/recipesSagas';
import {
  RecipesAdvancedSearchKey,
  validateRecipesAdvancedSearchFilter,
  getRecipesAdvancedSearchFilterDefaultValue,
  recipesAdvancedSearchFilterOperators,
} from 'features/recipes/search/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.constants';
import type {
  RecipesAdvancedSearchFilterErrors,
  RecipesAdvancedSearchFilters,
} from 'features/recipes/search/RecipesAdvancedSearch/RecipesAdvancedSearchFilter/RecipesAdvancedSearchFilter.types';
import { AppFeature } from 'types/appFeature';
import type { AppRecipe } from 'types/recipe/appRecipe';
import type { AppRecipes } from 'types/search/appRecipes';

export interface RecipeTranslations {
  [recipeId: ApiRcpRecipeId]: {
    translations?: AppRecipe[];
    apiError?: boolean;
    fetching?: boolean;
  };
}

export interface RecipeFilters {
  keys: { key: RecipesAdvancedSearchKey; requires?: AppFeature }[];
  current: RecipesAdvancedSearchFilters[];
  applied: RecipesAdvancedSearchFilters[];
  hasApplied?: boolean;
  errors: RecipesAdvancedSearchFilterErrors[];
}

export interface RecipesState {
  apiError?: string;
  fetching: boolean;
  page: number;
  recipes: AppRecipe[];
  recipeType: RecipeType;
  rowCount: number;
  rowsPerPage: number;
  searchTerm: string;
  rowsLoading: { [key: string]: boolean };
  recipeTranslations: RecipeTranslations;
  filters: RecipeFilters;
  isAdvancedSearchPanelOpen: boolean;
}

export const initialState: RecipesState = {
  fetching: false,
  page: tableDefaults.firstPage,
  recipes: [],
  recipeType: RecipeType.Core,
  rowCount: 0,
  rowsPerPage: tableDefaults.rowsPerPage,
  searchTerm: '',
  rowsLoading: {},
  recipeTranslations: {},
  filters: {
    keys: [
      /** @todo add State once {@link https://frescocooks.atlassian.net/browse/CD-83} is done */
      { key: RecipesAdvancedSearchKey.ApplianceTags },
      { key: RecipesAdvancedSearchKey.GeneralTags },
      {
        key: RecipesAdvancedSearchKey.Locale,
        requires: AppFeature.TranslationManagement,
      },
    ],
    current: [],
    applied: [],
    errors: [],
  },
  isAdvancedSearchPanelOpen: false,
};

const recipesSlice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    recipesPaginated(
      state,
      { payload: { page, rowsPerPage } }: PayloadAction<TablePaginationEvent>
    ) {
      state.page = page;
      state.rowsPerPage = rowsPerPage;
    },
    recipesSearchTermUpdated(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
    recipesFetching(state) {
      state.apiError = undefined;
      state.fetching = true;
    },
    recipesFetchSucceed(state, { payload }: PayloadAction<AppRecipes>) {
      state.fetching = false;
      state.recipes = payload.recipes;
      state.rowCount = payload.total;
    },
    recipesFetchFailed(state, { payload }: PayloadAction<string>) {
      state.apiError = payload;
      state.fetching = false;
    },
    recipesBatchUpdateRequested(
      state,
      { payload: recipeIds }: PayloadAction<ApiRcpRecipeId[]>
    ) {
      recipeIds.forEach((id) => {
        state.rowsLoading[id] = true;
      });
    },
    recipesBatchUpdateFinished(
      state,
      { payload: recipeIds }: PayloadAction<ApiRcpRecipeId[]>
    ) {
      recipeIds.forEach((id) => delete state.rowsLoading[id]);
    },
    recipeTypeChanged(
      state,
      { payload: recipeType }: PayloadAction<RecipeType>
    ) {
      state.recipeType = recipeType;
      state.fetching = true;
    },
    recipesAdvancedSearchPanelOpened(state: RecipesState) {
      state.isAdvancedSearchPanelOpen = true;
    },
    recipesAdvancedSearchPanelClosed(state: RecipesState) {
      state.isAdvancedSearchPanelOpen = false;
    },
    recipesFilterAdded(state: RecipesState) {
      delete state.filters.hasApplied;

      const availableKeys = getAvailableFilterKeys(
        state.filters.current,
        state.filters.keys.map(({ key }) => key)
      );
      if (!availableKeys.length) {
        return;
      }
      const key = availableKeys[0];
      const operator = recipesAdvancedSearchFilterOperators[key][0];
      const value = getRecipesAdvancedSearchFilterDefaultValue(operator);
      const filter = { key, operator, value } as RecipesAdvancedSearchFilters;
      state.filters.current.push(filter);
      state.filters.errors.push(validateRecipesAdvancedSearchFilter(filter));
    },
    recipesFilterUpdated(
      state: RecipesState,
      {
        payload: { index, filter },
      }: PayloadAction<{
        index: number;
        filter: Partial<RecipesAdvancedSearchFilters>;
      }>
    ) {
      delete state.filters.hasApplied;

      if (
        filter.key === undefined &&
        filter.operator === undefined &&
        filter.value === undefined
      ) {
        throw new Error(
          'You have to provide at least one of the following properties: key, operator or value'
        );
      }
      const { key } = filter;
      if (key !== undefined) {
        const operator =
          key !== null ? recipesAdvancedSearchFilterOperators[key][0] : null;
        const value = getRecipesAdvancedSearchFilterDefaultValue(operator);
        state.filters.current[index] = {
          key,
          operator,
          value,
        } as RecipesAdvancedSearchFilters;
      }
      const { operator } = filter;
      if (operator !== undefined) {
        state.filters.current[index].operator = operator;
        state.filters.current[index].value =
          getRecipesAdvancedSearchFilterDefaultValue(operator);
      }
      const { value } = filter;
      if (value !== undefined) {
        state.filters.current[index].value = value;
      }
      state.filters.errors[index] = validateRecipesAdvancedSearchFilter(
        state.filters.current[index]
      );
    },
    recipesFilterDeleted(
      state: RecipesState,
      { payload: index }: PayloadAction<number>
    ) {
      delete state.filters.hasApplied;

      state.filters.current.splice(index, 1);
      state.filters.errors.splice(index, 1);
    },
    recipesFiltersApplied(state: RecipesState) {
      state.filters.hasApplied = true;

      if (state.filters.errors.some((error) => !isEmpty(error))) {
        return;
      }

      state.filters.applied = state.filters.current;
      state.isAdvancedSearchPanelOpen = false;
    },
    recipesCurrentFiltersReset(state: RecipesState) {
      state.filters.current = state.filters.applied;
      state.filters.errors = state.filters.current.map(() => ({}));
    },
    recipesSearchReset(state: RecipesState) {
      const { filters, searchTerm } = initialState;
      state.filters = filters;
      state.searchTerm = searchTerm;
    },
    recipeTranslationsFetching(
      state,
      { payload: { recipeId } }: PayloadAction<{ recipeId: ApiRcpRecipeId }>
    ) {
      state.recipeTranslations[recipeId] = { fetching: true };
    },
    recipeTranslationsFetchSucceed(
      state,
      {
        payload: { recipeId, translations },
      }: PayloadAction<{
        recipeId: ApiRcpRecipeId;
        translations: AppRecipe[];
      }>
    ) {
      state.recipeTranslations[recipeId] = { translations };
    },
    recipeTranslationsFetchFailed(
      state,
      { payload: { recipeId } }: PayloadAction<{ recipeId: ApiRcpRecipeId }>
    ) {
      state.recipeTranslations[recipeId] = { apiError: true };
    },
    recipesTranslateRequested(
      state,
      { payload: { recipeId } }: PayloadAction<ForkRecipesPayload>
    ) {
      state.rowsLoading[recipeId] = true;
    },
    recipesTranslateFinished(
      state,
      { payload: recipeId }: PayloadAction<string>
    ) {
      state.rowsLoading[recipeId] = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authSignOutFinished, () => initialState)
      .addMatcher(shouldResetPage, (state: RecipesState) => {
        state.page = initialState.page;
      });
  },
});

export const recipesFetchRequested = createAction(
  'recipesSlice/recipesFetchRequested'
);

export const recipesPublishRequested = createAction<ApiRcpRecipeId[]>(
  'recipesSlice/recipesPublishRequested'
);

export const recipesUnpublishRequested = createAction<ApiRcpRecipeId[]>(
  'recipesSlice/recipesUnpublishRequested'
);

export const recipesDeleteRequested = createAction<DeleteRecipePayload[]>(
  'recipesSlice/recipesDeleteRequested'
);

export const recipeTranslationsFetchRequested = createAction<{
  recipeId: ApiRcpRecipeId;
}>('recipesSlice/recipeTranslationsFetchRequested');

export const {
  reducer: recipesReducer,
  actions: {
    recipesPaginated,
    recipesSearchTermUpdated,
    recipesFetching,
    recipesFetchSucceed,
    recipesFetchFailed,
    recipesBatchUpdateRequested,
    recipesBatchUpdateFinished,
    recipeTypeChanged,
    recipesAdvancedSearchPanelOpened,
    recipesAdvancedSearchPanelClosed,
    recipesFilterAdded,
    recipesFilterUpdated,
    recipesFilterDeleted,
    recipesFiltersApplied,
    recipesCurrentFiltersReset,
    recipesSearchReset,
    recipeTranslationsFetching,
    recipeTranslationsFetchSucceed,
    recipeTranslationsFetchFailed,
    recipesTranslateRequested,
    recipesTranslateFinished,
  },
} = recipesSlice;

const selectRecipesState = (state: RootState): RecipesState => state.recipes;

export const selectRecipes = (state: RootState): AppRecipe[] =>
  selectRecipesState(state).recipes;

export const selectRecipesPage = (state: RootState): number =>
  selectRecipesState(state).page;

export const selectRecipesRowsPerPage = (state: RootState): number =>
  selectRecipesState(state).rowsPerPage;

export const selectRecipesRowCount = (state: RootState): number =>
  selectRecipesState(state).rowCount;

export const selectRecipesSearchTerm = (state: RootState): string =>
  selectRecipesState(state).searchTerm;

export const selectRecipesFetching = (state: RootState): boolean =>
  selectRecipesState(state).fetching;

export const selectRecipeType = (state: RootState): RecipeType =>
  selectRecipesState(state).recipeType;

export const selectRowsLoading = (
  state: RootState
): { [key: string]: boolean } => selectRecipesState(state).rowsLoading;

export const selectIsAdvancedSearchPanelOpen = (state: RootState) =>
  selectRecipesState(state).isAdvancedSearchPanelOpen;

export const selectRecipesFilterKeys = createSelector(
  selectRecipesState,
  selectConfigsFeatures,
  ({ filters }, features) =>
    filters.keys
      .filter(
        ({ requires }) => requires === undefined || !!features?.[requires]
      )
      .map(({ key }) => key)
);

export const selectRecipesCurrentFilters = (state: RootState) =>
  selectRecipesState(state).filters.current;

export const selectRecipesAppliedFilters = (state: RootState) =>
  selectRecipesState(state).filters.applied;

export const selectRecipesFilterErrors = (state: RootState) =>
  selectRecipesState(state).filters.errors;

export const selectRecipesFilterErrorsByIndex = (index: number) =>
  createSelector(selectRecipesFilterErrors, (errors) => errors[index]);

export const selectRecipesHasAppliedFilters = (state: RootState) =>
  selectRecipesState(state).filters.hasApplied;

const getAvailableFilterKeys = (
  selectedFilters: RecipesAdvancedSearchFilters[],
  keys: RecipesAdvancedSearchKey[]
): RecipesAdvancedSearchKey[] =>
  keys.filter(
    (key) => !selectedFilters.some((selected) => key === selected.key)
  );

export const selectAvailableRecipesFilterKeys = createSelector(
  selectRecipesCurrentFilters,
  selectRecipesFilterKeys,
  getAvailableFilterKeys
);

export const selectRecipeTranslations = (
  state: RootState
): RecipeTranslations => selectRecipesState(state).recipeTranslations;

export const selectRecipeTranslationByLocale =
  (recipeId: ApiRcpRecipeId, locale: ApiLocale) =>
  (state: RootState): AppRecipe | undefined =>
    selectRecipesState(state).recipeTranslations[recipeId]?.translations?.find(
      (translation) => translation.locale === locale
    );

export const shouldResetPage = (action: Action<string>): boolean =>
  [
    recipesSearchTermUpdated.type,
    recipesFiltersApplied.type,
    recipesSearchReset.type,
    recipeTypeChanged.type,
  ].includes(action.type);
