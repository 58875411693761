import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '@dropkitchen/pantry-react';
import { Grid } from '@mui/material';
import type { FC } from 'react';
import { useEffect, memo, useState } from 'react';
import { push } from 'redux-first-history';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Dialog } from 'components/Dialog/Dialog';
import { recipeFromScratchDialogStrings } from 'features/RecipeFromScratchDialog/RecipeFromScratchDialog.constants';
import { selectConfigsLocales } from 'features/configs/configsSlice';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { recipeFromScratchRequested } from 'features/recipe/recipeSlice';
import { RecipeLocaleField } from 'features/recipe/shared/RecipeLocaleField/RecipeLocaleField';

export type RecipeFromScratchDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const { titles, buttons } = recipeFromScratchDialogStrings;

export const RecipeFromScratchDialog: FC<RecipeFromScratchDialogProps> = memo(
  function RecipeFromScratchDialog({ isOpen, onClose }) {
    const dispatch = useAppDispatch();

    const supportedLocales = useAppSelector(selectConfigsLocales);
    const [locale, setLocale] = useState<ApiLocale | null>(
      supportedLocales?.[0] ?? null
    );

    useEffect(() => {
      setLocale(supportedLocales?.[0] ?? null);
    }, [supportedLocales]);

    const handleClose = () => {
      setLocale(supportedLocales?.[0] ?? null);
      onClose();
    };

    const handleSubmit = () => {
      if (!locale) {
        // This shouldn't happen as the button is disabled if there isn't a locale
        return;
      }
      dispatch(recipeFromScratchRequested(locale));
      dispatch(push(generateRecipeRoute({ tab: RecipeTabName.Information })));
    };

    return (
      <Dialog
        title={titles.addFromScratch}
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={handleSubmit}
        size="sm"
        components={{
          content: (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <RecipeLocaleField
                  onChange={(event) => setLocale(event)}
                  value={locale}
                  autoFocus
                />
              </Grid>
            </Grid>
          ),
          buttons: (
            <>
              <Button
                size={ButtonSize.Large}
                label={buttons.cancel}
                buttonStyle={ButtonStyle.Subtle}
                onClick={() => handleClose()}
              />
              <Button
                type={ButtonType.Submit}
                size={ButtonSize.Large}
                label={buttons.next}
                buttonStyle={ButtonStyle.Emphasis}
                disabled={!locale}
              />
            </>
          ),
        }}
      />
    );
  }
);
