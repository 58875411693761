import type { ApiError } from 'api/types/common/apiError';

export enum HttpMethod {
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum HttpError {
  /**
   * Error name for DOMException thrown when an API request is aborted.
   * Check {@link https://developer.mozilla.org/en-US/docs/Web/API/DOMException#error_names}
   */
  AbortError = 'AbortError',
  NetworkError = 'NetworkError',
}

export type ApiResponse<TResponse> = SuccessResponse<TResponse> | ErrorResponse;

export type ApiList<T> = { items: T[]; total: number };

export interface SuccessResponse<T> {
  data: T;
  ok: true;
}

export interface ErrorResponse {
  ok: false;
  details: ErrorResponseDetails;
  httpStatus?: number;
}

export interface ErrorResponseDetails {
  /**
   * Error text summary
   */
  message: string;
  /**
   * KitchenOS error response
   */
  platformError?: ApiError;
}

export type ApiRequestFn<
  TResponse,
  TRequest = undefined
> = TRequest extends undefined
  ? (signal: AbortSignal) => Promise<ApiResponse<TResponse>>
  : (signal: AbortSignal, request: TRequest) => Promise<ApiResponse<TResponse>>;

export type ApiRequestFnResponseType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TApiRequestFn extends ApiRequestFn<any, any>
> = Awaited<ReturnType<TApiRequestFn>>;

export enum CustomHeader {
  OrganizationId = 'x-kitchenos-organization-id',
}
