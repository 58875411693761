import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { App } from 'app/App';
import { appHistory, appStore } from 'app/store/store';
import { appConfig } from 'config/config';

import reportWebVitals from './reportWebVitals';

if (!appConfig.isDevOrTestEnv()) {
  datadogRum.init({
    applicationId: '1c5b5346-8191-48e6-bbf6-567b4d9e44b2',
    clientToken: 'pub9edd7885705f3ad9d4ba9b52ecd3edd9',
    env: appConfig.environment(),
    version: appConfig.version(),
    site: 'datadoghq.com',
    service: appConfig.appName(),
    defaultPrivacyLevel: 'allow',
    sampleRate: 100,
    replaySampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [appConfig.apiGatewayUrl()],
  });

  datadogRum.startSessionReplayRecording();

  datadogLogs.onReady(() => {
    datadogLogs.init({
      clientToken: 'pub9b2e64827d1d6c6751f88b275167e082',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: appConfig.appName(),
      silentMultipleInit: true,
      env: appConfig.environment(),
    });
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <Router history={appHistory}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
