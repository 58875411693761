export enum ApiErrorType {
  Application = 'ApplicationError',
  InvalidOperation = 'InvalidOperationError',
  NotFound = 'NotFoundError',
  Validation = 'ValidationError',
  NonCompliant = 'NonCompliantError',
  Permission = 'PermissionError',
}

export type ApiError =
  | ApiApplicationError
  | ApiInvalidOperationError
  | ApiNotFoundError
  | ApiValidationError
  | ApiPermissionError;

/**
 * An error indicating that the request could not be processed due to an internal application error.
 */
export interface ApiApplicationError {
  error: ApiApplicationErrorDetails;
}

export interface ApiApplicationErrorDetails {
  message: string;
  type: ApiErrorType.Application;
  /**
   * A unique identifier for the request. This can be used to correlate a request with a response.
   */
  correlationId: string;
}

/**
 * An error indicating that the requested operation is not valid for the requested resource.
 */
export interface ApiInvalidOperationError {
  error: ApiInvalidOperationErrorDetails;
}

export interface ApiInvalidOperationErrorDetails {
  message: string;
  type: ApiErrorType.InvalidOperation;
  /**
   * A unique identifier for the request. This can be used to correlate a request with a response.
   */
  correlationId: string;
}

/**
 * An error indicating that the requested resource was not found.
 */
export interface ApiNotFoundError {
  error: ApiNotFoundErrorDetails;
}

export interface ApiNotFoundErrorDetails {
  message: string;
  type: ApiErrorType.NotFound;
  /**
   * A unique identifier for the request. This can be used to correlate a request with a response.
   */
  correlationId: string;
}

/**
 * An error indicating that the request parameters are invalid.
 */
export interface ApiValidationError {
  error: ApiValidationErrorDetails;
}

export interface ApiValidationErrorDetails {
  message: ApiValidationErrorDetailsMessage[];
  type: ApiErrorType.Validation;
  /**
   * A unique identifier for the request. This can be used to correlate a request with a response.
   */
  correlationId: string;
}

export interface ApiValidationErrorDetailsMessage {
  /**
   * The path to the invalid value. The path is an array combining keys and indexes.
   */
  path: (string | number)[];
  detail: string;
}

export interface ApiPermissionError {
  error: ApiPermissionErrorDetails;
}

export interface ApiPermissionErrorDetails {
  message: string;
  type: ApiErrorType.Permission;
  /**
   * A unique identifier for the request. This can be used to correlate a request with a response.
   */
  correlationId: string;
}
