import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '@dropkitchen/pantry-react';
import { Grid, TextField } from '@mui/material';
import type { FC } from 'react';
import { useEffect, memo, useState } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Dialog } from 'components/Dialog/Dialog';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { recipeFromUrlDialogStrings } from 'features/RecipeFromUrlDialog/RecipeFromUrlDialog.constants';
import { selectConfigsLocales } from 'features/configs/configsSlice';
import {
  recipeGetFromUrlRequested,
  selectRecipeFetching,
} from 'features/recipe/recipeSlice';
import { RecipeLocaleField } from 'features/recipe/shared/RecipeLocaleField/RecipeLocaleField';
import { AppFeature } from 'types/appFeature';

export type RecipeFromUrlDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const { titles, labels, buttons } = recipeFromUrlDialogStrings;

export const RecipeFromUrlDialog: FC<RecipeFromUrlDialogProps> = memo(
  function RecipeFromUrlDialog({ isOpen, onClose }) {
    const dispatch = useAppDispatch();

    const isFetching = useAppSelector(selectRecipeFetching);
    const supportedLocales = useAppSelector(selectConfigsLocales);

    const [url, setUrl] = useState<string>('');
    const [locale, setLocale] = useState<ApiLocale | null>(
      supportedLocales?.[0] ?? null
    );

    useEffect(() => {
      setLocale(supportedLocales?.[0] ?? null);
    }, [supportedLocales]);

    const handleClose = () => {
      setUrl('');
      setLocale(supportedLocales?.[0] ?? null);
      onClose();
    };

    const handleSubmit = () => {
      if (!locale) {
        // This shouldn't happen as the button is disabled if there isn't a locale
        return;
      }
      dispatch(recipeGetFromUrlRequested({ url, locale }));
    };

    return (
      <Dialog
        title={titles.addFromUrl}
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={handleSubmit}
        size="sm"
        components={{
          content: (
            <Grid container spacing={6}>
              <ToggleableFeature
                requires={AppFeature.TranslationManagement}
                components={{ whenDisabled: null }}
              >
                <Grid item xs={12}>
                  <RecipeLocaleField
                    onChange={(event) => setLocale(event)}
                    value={locale}
                    autoFocus
                  />
                </Grid>
              </ToggleableFeature>
              <Grid item xs={12}>
                <TextField
                  value={url}
                  type="url"
                  InputLabelProps={{ shrink: true }}
                  required
                  placeholder="https:// Enter URL here"
                  onChange={(event) => setUrl(event.target.value)}
                  label={labels.urlField}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ),
          buttons: (
            <>
              <Button
                size={ButtonSize.Large}
                label={buttons.cancel}
                buttonStyle={ButtonStyle.Subtle}
                onClick={() => handleClose()}
              />
              <Button
                type={ButtonType.Submit}
                size={ButtonSize.Large}
                label={buttons.next}
                buttonStyle={ButtonStyle.Emphasis}
                loading={isFetching}
                disabled={!url || !locale}
              />
            </>
          ),
        }}
      />
    );
  }
);
