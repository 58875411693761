import {
  PantryColor,
  pantryOnLightTheme,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import { Box, CircularProgress, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { Image } from 'components/Image/Image';
import { recipeMediaStrings } from 'features/recipe/recipeBasicInformation/recipeMedia/RecipeMedia.constants';

const { messages, image } = recipeMediaStrings;

export interface RecipeMediaPreviewProps {
  src: string;
  uploading?: boolean;
  onClick?: () => void;
}

export const RecipeMediaPreview: FC<RecipeMediaPreviewProps> = memo(
  function RecipeMediaPreview({ uploading, src, onClick }) {
    return (
      <Box
        sx={{
          position: 'relative',
          cursor: onClick ? 'pointer' : 'auto',
        }}
        onClick={() => onClick?.()}
      >
        <Image
          src={src}
          error={{
            component: <NoImagesPlaceholder />,
          }}
          alt="Hero image"
          width="auto"
          height="auto"
          bypassCache
          maxHeight={`${image.maxHeight}px`}
          maxWidth={`${image.maxWidth}px`}
          retryPlaceholder={
            <Typography
              variant={PantryTypography.Body2}
              color={PantryColor.TextMuted}
              textAlign="center"
            >
              {messages.retrying}
            </Typography>
          }
        />
        {uploading && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: `${
                pantryOnLightTheme.palette[PantryColor.SurfaceEmphasis]
              }40`,
              borderRadius: 1,
            }}
          >
            <CircularProgress sx={{ color: PantryColor.IconContrast }} />
          </Box>
        )}
      </Box>
    );
  }
);

export const NoImagesPlaceholder: FC = memo(function NoImagesPlaceholder() {
  return (
    <Typography
      variant={PantryTypography.Body2}
      color={PantryColor.TextMuted}
      textAlign="center"
    >
      {messages.noFiles}
    </Typography>
  );
});
