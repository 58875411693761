import {
  PantryTypography,
  PantryColor,
  Skeleton,
  SkeletonVariant,
} from '@dropkitchen/pantry-react';
import { Box, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import {
  recipeTabContainerConstants,
  recipeTabContainerStyle,
} from 'features/recipe/shared/RecipeTabContainer/RecipeTabContainer.constants';

export interface RecipeTabContainerProps {
  title?: {
    component?: ReactNode;
    string?: string;
  };
  children: ReactNode;
}

const { ariaLabels } = recipeTabContainerConstants;

export const RecipeTabContainer: FC<RecipeTabContainerProps> = memo(
  function RecipeTabContainer({ title, children }) {
    return (
      <Box sx={recipeTabContainerStyle}>
        <Box sx={{ mb: 6 }}>
          {title?.component}
          {title?.string && (
            <Typography
              variant={PantryTypography.Subtitle1}
              color={PantryColor.TextDefault}
            >
              {title.string}
            </Typography>
          )}
          {!title && (
            <Box aria-label={ariaLabels.titleSkeleton}>
              <Skeleton
                variant={SkeletonVariant.Small}
                sx={{ width: 150, height: 28 }}
              />
            </Box>
          )}
        </Box>
        {children}
      </Box>
    );
  }
);
