import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import type { ApplianceConfig } from 'api/appliances';
import { apiGetApplianceConfig } from 'api/appliances';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { apiGetTags } from 'api/tags';
import type { ApiList, ApiResponse, SuccessResponse } from 'api/types';
import type { ApiTag } from 'api/types/common/apiTag';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import { errorOccurred } from 'features/error/errorSlice';
import type { TagsById } from 'features/referenceData/tags/tagsSlice';
import {
  tagsFetchRequested,
  selectShouldFetchApplianceTags,
  selectShouldFetchGeneralTags,
  tagsFetchFailed,
  tagsFetching,
  tagsFetchSucceed,
} from 'features/referenceData/tags/tagsSlice';
import type { ReferenceDataFetchActionPayload } from 'features/referenceData/types/referenceDataFetchActionPayload';

export const apiFetchTagsSaga = createApiRequestSaga(apiGetTags);
export const apiGetApplianceConfigSaga = createApiRequestSaga(
  apiGetApplianceConfig
);

export function* fetchTags({
  payload: { locale },
}: PayloadAction<ReferenceDataFetchActionPayload>) {
  const shouldFetchGeneralTags = (yield select(
    selectShouldFetchGeneralTags(locale)
  )) as boolean;

  const shouldFetchApplianceTags = (yield select(
    selectShouldFetchApplianceTags(locale)
  )) as boolean;

  if (!shouldFetchGeneralTags && !shouldFetchApplianceTags) {
    return;
  }

  yield put(tagsFetching({ locale }));
  const [responseTags, responseApplianceConfig] = (yield all([
    call(apiFetchTagsSaga, { locale }),
    call(apiGetApplianceConfigSaga),
  ])) as (ApiResponse<ApiList<ApiTag>> | ApiResponse<ApplianceConfig>)[];

  if (!responseTags.ok) {
    yield put(tagsFetchFailed({ locale, data: responseTags.details.message }));
    yield put(errorOccurred(responseTags.details.message));
    return;
  }

  if (!responseApplianceConfig.ok) {
    yield put(
      tagsFetchFailed({ locale, data: responseApplianceConfig.details.message })
    );
    yield put(errorOccurred(responseApplianceConfig.details.message));
    return;
  }

  const applianceTags: TagsById = (
    responseApplianceConfig as SuccessResponse<ApplianceConfig>
  ).data.deviceTags.reduce<TagsById>((acc, current) => {
    current.tags.forEach((tag) => {
      acc[tag.tagId] = fromApplianceConfigTagToAppTag(tag);
    });
    return acc;
  }, {});

  const generalTags: ApiTag[] = (
    responseTags as SuccessResponse<ApiList<ApiTag>>
  ).data.items.filter((tag) => !applianceTags[tag.id]);

  yield put(
    tagsFetchSucceed({
      locale,
      data: { general: generalTags, appliances: applianceTags },
    })
  );
}

const fromApplianceConfigTagToAppTag = (tag: {
  tagId: ApiRefId;
  tagName: string;
}): ApiTag => ({ id: tag.tagId, name: tag.tagName });

function* tagsFetchWatcher() {
  yield takeEvery(tagsFetchRequested, fetchTags);
}

export const tagsSagas = [tagsFetchWatcher];
