import {
  ExpandLessIcon,
  ExpandMoreIcon,
  PantryColor,
  PantryCornerRadius,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import {
  buttonClasses,
  menuClasses,
  menuItemClasses,
  paperClasses,
  Button,
  Menu as MuiMenu,
} from '@mui/material';
import type { FC, ReactNode, SyntheticEvent } from 'react';
import { memo, useId, useState } from 'react';

export interface MenuButtonProps {
  label: string;
  children: ReactNode;
  minWidth?: string;
}

export const MenuButton: FC<MenuButtonProps> = memo(function MenuButton({
  label,
  children,
  minWidth,
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const buttonId = useId();
  const menuId = useId();

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={isOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={(event: SyntheticEvent<HTMLElement>) => {
          setAnchorElement(event.currentTarget);
          setIsOpen(true);
        }}
        endIcon={
          isOpen ? <ExpandLessIcon size={16} /> : <ExpandMoreIcon size={16} />
        }
        sx={{
          [`&.${buttonClasses.root}`]: {
            typography: PantryTypography.Body1,
            px: 4,
            py: 6,
            height: '34px',
            backgroundColor: PantryColor.SurfaceDefault,
            color: PantryColor.TextDefault,
            border: '1px solid',
            borderColor: PantryColor.BorderDefault,
            borderRadius: PantryCornerRadius.Small,
            textTransform: 'none',
          },
        }}
      >
        {label}
      </Button>
      <MuiMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
        anchorEl={anchorElement}
        open={isOpen}
        onClose={() => {
          setAnchorElement(null);
          setIsOpen(false);
        }}
        onClick={() => setIsOpen(false)}
        sx={{
          [`& .${paperClasses.root}`]: {
            borderRadius: 1,
            marginTop: 1,
            color: PantryColor.TextDefault,
            boxShadow: '0px 2px 40px rgba(0, 0, 0, 0.1)',
            minWidth,
            [`& .${menuClasses.list}`]: {
              paddingTop: 2,
              paddingBottom: 2,
            },
            [`& .${menuItemClasses.root}`]: {
              typography: PantryTypography.Body2,
              paddingTop: 2,
              paddingBottom: 2,
            },
          },
        }}
      >
        {children}
      </MuiMenu>
    </>
  );
});
