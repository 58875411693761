import { PantryColor } from '@dropkitchen/pantry-react';
import { Autocomplete, TextField, InputLabel } from '@mui/material';
import { memo, useMemo } from 'react';

import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Chip } from 'components/Chip/Chip';
import { Image } from 'components/Image/Image';
import { ReadOnlyField } from 'components/ReadOnlyField/ReadOnlyField';
import { mediaStorage } from 'features/media/mediaStorage';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { recipeBasicInformationStrings } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import {
  recipeFieldUpdated,
  selectRecipe,
  selectRecipeLocale,
} from 'features/recipe/recipeSlice';
import { recipeReviewStrings } from 'features/recipe/review/RecipeReview.constants';
import { recipeReviewBasicInformationStrings } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformation.constants';
import { RecipeReviewBasicInformationContainer } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformationContainer';
import {
  RecipeReviewEmptyValue,
  RecipeReviewEmptyValueType,
} from 'features/recipe/review/shared/RecipeReviewEmptyValue/RecipeReviewEmptyValue';
import { recipeDescriptionFieldStrings } from 'features/recipe/shared/RecipeDescriptionField/RecipeDescriptionField.constants';
import { disambiguateTerms } from 'features/referenceData/referenceData.utils';
import {
  selectGeneralTags,
  selectTagsFetching,
} from 'features/referenceData/tags/tagsSlice';
import { fromAppTimeToSentence } from 'utils/convertTimes';
import { generateRecipeHeroImageSource, heroImageConstants } from 'utils/image';
import { hasTime } from 'utils/validateTimes';

const { labels, placeholders: fieldPlaceholders } =
  recipeBasicInformationStrings;
const { placeholders, errors } = recipeReviewStrings;
const { emptyRequiredFields } = recipeReviewBasicInformationStrings;

export const RecipeReviewBasicInformation = memo(
  function RecipeReviewBasicInformation() {
    const dispatch = useAppDispatch();

    const recipe = useAppSelector(selectRecipe);
    const locale = useAppSelector(selectRecipeLocale);
    const tags = useAppSelector(selectGeneralTags(locale));
    const loadingTags = useAppSelector(selectTagsFetching(locale));

    const { generalTags } = recipe;
    const existingImage = generateRecipeHeroImageSource({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      recipeId: recipe.id!,
      width: heroImageConstants.width,
    });
    const currentImage = mediaStorage.get()?.preview;

    const disambiguatedTags = useMemo(
      () => disambiguateTerms(tags || []),
      [tags]
    );

    return (
      <RecipeReviewBasicInformationContainer
        components={{
          image: (
            <Image
              src={currentImage || (recipe.id && existingImage.src) || ''}
              alt={recipe.name}
              bypassCache
              error={{ text: errors.image }}
              width={`${heroImageConstants.width}px`}
              height={`${heroImageConstants.height}px`}
            />
          ),

          descriptionField: (
            <ReadOnlyField
              label={recipeDescriptionFieldStrings.label}
              value={recipe.description || placeholders.noValue}
            />
          ),

          authorField: (
            <ReadOnlyField
              label={labels.authorField}
              value={
                recipe.author.name || (
                  <RecipeReviewEmptyValue
                    type={RecipeReviewEmptyValueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={{
                      to: generateRecipeRoute({
                        id: recipe.id,
                        tab: RecipeTabName.Information,
                      }),
                      text: emptyRequiredFields.links.authorField,
                    }}
                  />
                )
              }
            />
          ),

          totalTimeField: (
            <ReadOnlyField
              label={labels.totalTimeField}
              value={
                hasTime(recipe.totalTime) ? (
                  fromAppTimeToSentence(recipe.totalTime)
                ) : (
                  <RecipeReviewEmptyValue
                    type={RecipeReviewEmptyValueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={{
                      to: generateRecipeRoute({
                        id: recipe.id,
                        tab: RecipeTabName.Information,
                      }),
                      text: emptyRequiredFields.links.totalTimeField,
                    }}
                  />
                )
              }
            />
          ),

          prepTimeField: (
            <ReadOnlyField
              label={labels.prepTimeField}
              value={
                hasTime(recipe.prepTime) ? (
                  fromAppTimeToSentence(recipe.prepTime)
                ) : (
                  <RecipeReviewEmptyValue
                    type={RecipeReviewEmptyValueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={{
                      to: generateRecipeRoute({
                        id: recipe.id,
                        tab: RecipeTabName.Information,
                      }),
                      text: emptyRequiredFields.links.prepTimeField,
                    }}
                  />
                )
              }
            />
          ),

          cookTimeField: (
            <ReadOnlyField
              label={labels.cookTimeField}
              value={
                hasTime(recipe.cookTime) ? (
                  fromAppTimeToSentence(recipe.cookTime)
                ) : (
                  <RecipeReviewEmptyValue
                    type={RecipeReviewEmptyValueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={{
                      to: generateRecipeRoute({
                        id: recipe.id,
                        tab: RecipeTabName.Information,
                      }),
                      text: emptyRequiredFields.links.cookTimeField,
                    }}
                  />
                )
              }
            />
          ),

          servesField: (
            <ReadOnlyField
              label={labels.servesField}
              value={
                recipe.serves || (
                  <RecipeReviewEmptyValue
                    type={RecipeReviewEmptyValueType.Critical}
                    message={emptyRequiredFields.message}
                    emptyLink={{
                      to: generateRecipeRoute({
                        id: recipe.id,
                        tab: RecipeTabName.Information,
                      }),
                      text: emptyRequiredFields.links.servesField,
                    }}
                  />
                )
              }
            />
          ),

          applianceTagsField: (
            <ReadOnlyField
              label={labels.applianceTagsField}
              value={
                recipe.applianceReferenceTags?.length
                  ? recipe.applianceReferenceTags.map((tag) => (
                      <Chip label={tag.name} key={tag.id} />
                    ))
                  : placeholders.noValue
              }
            />
          ),

          generalTagsField: (
            <Autocomplete
              multiple
              id="tags"
              value={generalTags || []}
              loading={loadingTags}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableCloseOnSelect
              onChange={(_, options) =>
                dispatch(
                  recipeFieldUpdated({
                    key: 'generalTags',
                    value: options,
                  })
                )
              }
              options={tags || []}
              getOptionLabel={(tag) => disambiguatedTags[tag.id] ?? tag.name}
              renderInput={(params) => (
                <>
                  <InputLabel
                    htmlFor="tags"
                    sx={{
                      fontSize: '10px',
                      lineHeight: '13px',
                      textTransform: 'uppercase',
                      color: PantryColor.TextSubtle,
                      mb: 2,
                      mt: 0,
                      display: 'block',
                    }}
                  >
                    {labels.tagsField}
                  </InputLabel>
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      !generalTags || generalTags.length === 0
                        ? fieldPlaceholders.tagsField
                        : ''
                    }
                  />
                </>
              )}
              renderOption={(props, tag) => (
                <li {...props} key={tag.id}>
                  {disambiguatedTags[tag.id] ?? tag.name}
                </li>
              )}
            />
          ),
        }}
      />
    );
  }
);
