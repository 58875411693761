import { capabilitiesSagas } from 'features/referenceData/capabilities/capabilitiesSagas';
import { ingredientsSagas } from 'features/referenceData/ingredients/ingredientsSagas';
import { preparationsSagas } from 'features/referenceData/preparations/preparationsSagas';
import { tagsSagas } from 'features/referenceData/tags/tagsSagas';

export const referenceDataRestartableSagas = [
  ...ingredientsSagas,
  ...preparationsSagas,
  ...capabilitiesSagas,
  ...tagsSagas,
];
