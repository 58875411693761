export const recipeStepParams = {
  stepNumber: 'stepNumber',
} as const;

export const recipeStepsStrings = {
  title: 'Steps',
  buttons: {
    add: 'Add Step(s)',
  },
  messages: {
    empty: 'Your steps info will appear here',
  },
  deleteStepDialog: {
    title: `Delete "Step ${recipeStepParams.stepNumber}"`,
    body: 'Are you sure you want to delete this step?',
    confirmButton: 'Yes, delete',
    cancelButton: 'Cancel',
  },
} as const;
