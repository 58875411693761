import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import type { ApiLocale } from 'api/types/common/apiLocale';
import type { RootState } from 'app/store/rootReducer';
import { recipeValidator } from 'features/recipe/shared/validators/recipeValidator';
import type { AppRecipe } from 'types/recipe/appRecipe';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';
import type { ValidatorError, ValidatorErrors } from 'utils/validator';

interface RecipeTranslationState {
  base: {
    recipe?: AppRecipe;
    fetching: boolean;
    apiError?: string;
  };
  translation: {
    recipe?: AppRecipe;
    fetching: boolean;
    errors: ValidatorErrors;
    submitted: boolean;
    saving: boolean;
    apiError?: string;
  };
  showOnlyEditableFields: boolean;
}

export const initialState: RecipeTranslationState = {
  base: {
    fetching: false,
  },
  translation: {
    fetching: false,
    errors: {},
    submitted: false,
    saving: false,
  },
  showOnlyEditableFields: false,
};

export const recipeTranslationSlice = createSlice({
  name: 'recipeTranslationSlice',
  initialState,
  reducers: {
    recipeFetchRequested(state, _: PayloadAction<string>) {
      state.base.apiError = undefined;
      state.base.recipe = undefined;
      state.base.fetching = true;
    },
    recipeFetchFailed(state, { payload: apiError }: PayloadAction<string>) {
      state.base.apiError = apiError;
      state.base.fetching = false;
    },
    recipeFetchSucceed(state, { payload: recipe }: PayloadAction<AppRecipe>) {
      state.base.fetching = false;
      state.base.recipe = recipe;
    },

    translatedRecipeFetchRequested(
      state,
      _: PayloadAction<{ forkedFromId: string; locale: ApiLocale }>
    ) {
      state.translation.apiError = undefined;
      state.translation.recipe = undefined;
      state.translation.fetching = true;
    },
    translatedRecipeFetchFailed(
      state,
      { payload: apiError }: PayloadAction<string>
    ) {
      state.translation.apiError = apiError;
      state.translation.fetching = false;
    },
    translatedRecipeFetchSucceed(
      state,
      { payload: recipe }: PayloadAction<AppRecipe>
    ) {
      state.translation.fetching = false;
      state.translation.recipe = recipe;
    },
    translatedRecipeNameUpdated(
      state,
      { payload: { name } }: PayloadAction<{ name: string }>
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.translation.recipe!.name = name;
      const errors = recipeValidator.validateField('name', name);
      if (errors) {
        state.translation.errors.name = errors;
      } else {
        delete state.translation.errors.name;
      }
    },
    translatedRecipeDescriptionUpdated(
      state,
      { payload: description }: PayloadAction<string>
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.translation.recipe!.description = description;
    },
    translatedRecipeStepUpdated(
      state,
      {
        payload: { step, stepIndex, errors },
      }: PayloadAction<{
        step: AppRecipeStep;
        stepIndex: number;
        errors?: ValidatorError;
      }>
    ) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        !isEqual(step, state.translation.recipe!.steps[stepIndex])
      ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.translation.recipe!.steps[stepIndex] = step;
      }
      const errorsId = `step-${stepIndex}`;
      if (!errors) {
        delete state.translation.errors[errorsId];
        return;
      }
      if (!isEqual(errors, state.translation.errors[errorsId])) {
        state.translation.errors[errorsId] = errors;
      }
    },
    translatedRecipeSaveRequested(state) {
      state.translation.apiError = undefined;
      state.translation.submitted = true;
      state.translation.saving = true;
    },
    translatedRecipeSaveFailed(
      state,
      { payload: apiError }: PayloadAction<string>
    ) {
      state.translation.saving = false;
      state.translation.apiError = apiError;
    },
    translatedRecipeSaveFinished(state) {
      state.translation.saving = false;
    },

    showOnlyEditableFieldsUpdated(
      state,
      { payload: showOnlyEditableFields }: PayloadAction<boolean>
    ) {
      state.showOnlyEditableFields = showOnlyEditableFields;
    },
  },
});

export const {
  reducer: recipeTranslationReducer,
  actions: {
    recipeFetchRequested,
    recipeFetchFailed,
    recipeFetchSucceed,
    translatedRecipeFetchRequested,
    translatedRecipeFetchFailed,
    translatedRecipeFetchSucceed,
    translatedRecipeNameUpdated,
    translatedRecipeDescriptionUpdated,
    translatedRecipeStepUpdated,
    translatedRecipeSaveRequested,
    translatedRecipeSaveFailed,
    translatedRecipeSaveFinished,
    showOnlyEditableFieldsUpdated,
  },
} = recipeTranslationSlice;

const selectRecipeTranslation = (state: RootState): RecipeTranslationState =>
  state.recipeTranslation;

export const selectRecipe = (state: RootState): AppRecipe | undefined =>
  selectRecipeTranslation(state).base.recipe;

export const selectRecipeFetching = (state: RootState): boolean =>
  selectRecipeTranslation(state).base.fetching;

export const selectTranslatedRecipe = (
  state: RootState
): AppRecipe | undefined => selectRecipeTranslation(state).translation.recipe;

export const selectRecipeTranslationFetching = (state: RootState): boolean =>
  selectRecipeTranslation(state).translation.fetching;

export const selectShowOnlyEditableFields = (state: RootState): boolean =>
  selectRecipeTranslation(state).showOnlyEditableFields;

export const selectRecipeTranslationSaving = (state: RootState): boolean =>
  selectRecipeTranslation(state).translation.saving;

export const selectRecipeTranslationHasErrors = (state: RootState): boolean =>
  !isEmpty(selectRecipeTranslationErrors(state));

export const selectRecipeTranslationErrors = (state: RootState) =>
  selectRecipeTranslation(state).translation.errors;
