import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { recipeBasicInformationStrings } from 'features/recipe/recipeBasicInformation/RecipeBasicInformation.constants';
import { recipeNameFieldStrings } from 'features/recipe/shared/RecipeNameField/RecipeNameField.constants';
import type { AppRecipeFieldChange } from 'features/recipe/shared/types/appRecipeFieldChange';

const { errorMessages } = recipeBasicInformationStrings;
const { label, placeholder } = recipeNameFieldStrings;

export interface RecipeNameFieldProps
  extends Pick<TextFieldProps, 'id' | 'value'> {
  showErrors?: boolean;
  onChange: (change: AppRecipeFieldChange) => void;
}

export const RecipeNameField: FC<RecipeNameFieldProps> = memo(
  function RecipeNameField({ id, showErrors, value, onChange }) {
    return (
      <TextField
        id={id}
        label={label}
        type="text"
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        required
        autoFocus
        error={showErrors}
        value={value}
        helperText={
          showErrors && (
            <HelperText
              message={errorMessages.recipeNameField.required}
              severity={HelperTextSeverity.Critical}
            />
          )
        }
        onChange={(event) => onChange({ value: event.target.value })}
      />
    );
  }
);
