import { Button, ButtonSize, ButtonStyle } from '@dropkitchen/pantry-react';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { push } from 'redux-first-history';

import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { EmptyContent } from 'components/EmptyContent/EmptyContent';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import { recipeReset } from 'features/recipe/recipeSlice';
import { recipesPageStrings } from 'features/recipes/RecipesPage.constants';
import { recipesListStrings } from 'features/recipes/list/RecipesList.constants';
import { ReactComponent as NoRecipesIcon } from 'features/recipes/no-recipes.svg';
import {
  selectRecipesHasAppliedFilters,
  selectRecipesSearchTerm,
} from 'features/recipes/recipesSlice';

export const EmptyRecipes: FC = memo(function EmptyRecipes() {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector(selectRecipesSearchTerm);
  const hasFilters = useAppSelector(selectRecipesHasAppliedFilters);

  const isSearch = searchTerm !== '' || hasFilters;
  const { emptyList, emptySearch } = recipesListStrings;
  const { text, title } = isSearch ? emptySearch : emptyList;
  const image = <NoRecipesIcon />;
  const actions = isSearch ? undefined : (
    /**
     * @todo the actions here should be the same as the ones in the header:
     * create manually, create by url, create by text, etc.
     * We should create a common component to unify them
     */
    <Button
      label={recipesPageStrings.buttons.add}
      buttonStyle={ButtonStyle.Emphasis}
      size={ButtonSize.Large}
      onClick={() => {
        dispatch(recipeReset());
        dispatch(push(generateRecipeRoute({ tab: RecipeTabName.Information })));
      }}
    />
  );

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <EmptyContent
        components={{ actions, image }}
        sx={{ width: '530px', minHeight: '700px' }}
        text={text}
        title={title}
      />
    </Box>
  );
});
