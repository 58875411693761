export enum RecipeTabName {
  Information = 'information',
  Ingredients = 'ingredients',
  Steps = 'steps',
  Review = 'review',
}

export const recipePageStrings = {
  labels: {
    saveButton: 'Save',
    savedButton: 'Saved',
    publishButton: 'Publish',
    unpublishButton: 'Unpublish',
    leaveModal: {
      title: 'Recipe has not been saved',
      body: 'Are you sure you want to exit?',
      bodySavedAgo: (timeAgo: string) =>
        `Recipe was last saved ${timeAgo} ago.`,
      bodyErrors: 'We are unable to save your work',
      bodyValidationErrors: 'because of one or more errors in your recipe.',
      confirmButton: 'Exit without saving',
      cancelButton: 'Cancel',
    },
    coreRecipe: 'Core',
    translationRecipe: 'Translations',
  },
  ariaLabels: {
    title: 'Loading title',
    localeInfo: 'Loading locale information',
  },
} as const;

export const recipePageConstants = {
  autosave: {
    delay: 45000, // ms
    changeMessageDelay: 10000, // ms
  },
  publish: {
    unusedIngredientThreshold: 0.001,
  },
} as const;
