import {
  PantryColor,
  PantryCornerRadius,
  PantryTypography,
  sxCompose,
} from '@dropkitchen/pantry-react';
import { Box, CardContent, Grid, Typography } from '@mui/material';
import type { SxProps } from '@mui/material';
import capitalize from 'lodash/capitalize';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { CapabilitySettingFieldIcon } from 'components/CapabilityField/CapabilitySettingFieldIcon';
import { Card } from 'components/Card/Card';
import type { DraggableContentProps } from 'components/DragAndDrop/Draggable';
import { getIngredientPropertiesAsStrings } from 'features/recipe/review/ingredients/recipeReviewIngredients.utils';
import { CardDeleteButton } from 'features/recipe/shared/CardDeleteButton/CardDeleteButton';
import { RecipeStepTextField } from 'features/recipe/shared/RecipeStepTextField/RecipeStepTextField';
import { recipeStepStrings } from 'features/recipe/steps/list/RecipeStep.constants';
import type { AppCapabilitySetting } from 'types/appCapabilitySetting';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import {
  isVentingTimeSettingId,
  isTimeSettingId,
} from 'types/appCapabilitySettings.utils';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';
import type { AppRecipeStepCapability } from 'types/recipe/appRecipeStepCapability';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';
import { fromAppTimeToSentence } from 'utils/convertTimes';
import type { ValidatorError } from 'utils/validator';

const { titles, ariaLabels } = recipeStepStrings;

export enum RecipeStepStyle {
  Default = 'default',
  Condensed = 'condensed',
}

export interface RecipeStepProps extends DraggableContentProps {
  draggable?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  onChange?: (change: { text: string; errors?: ValidatorError }) => void;
  selected?: boolean;
  step: AppRecipeStep;
  stepIndex: number;
  totalSteps: number;
  sx?: SxProps;
  style?: RecipeStepStyle;
}

export const RecipeStep: FC<RecipeStepProps> = memo(function RecipeStep({
  draggable = false,
  dragging,
  onClick,
  onDelete,
  onChange,
  selected,
  step,
  stepIndex,
  totalSteps,
  sx,
  style = RecipeStepStyle.Default,
}) {
  const stepNumber = `STEP ${stepIndex + 1} OF ${totalSteps}`;
  return (
    <Box sx={sxCompose({ position: 'relative' }, sx)}>
      <Card
        onClick={onClick}
        selected={selected}
        ariaLabel={stepNumber}
        dragging={dragging}
        draggable={draggable}
      >
        <CardContent>
          <Box
            sx={(theme) => ({
              maxWidth: onDelete ? `calc(100% - ${theme.spacing(10)})` : '100%',
            })}
          >
            <Typography
              variant={PantryTypography.Overline}
              color={PantryColor.TextMuted}
              sx={{ mb: 1, display: 'block', boxShadow: 'none' }}
            >
              {stepNumber}
            </Typography>
            {onChange ? (
              <RecipeStepTextField
                id={`${step.id}-text-field`}
                hideLabel
                value={step.text}
                onChange={({ value, errors }) =>
                  onChange({ text: value, errors })
                }
              />
            ) : (
              <Typography
                variant={PantryTypography.Body1SemiBold}
                color={PantryColor.TextDefault}
              >
                {step.text}
              </Typography>
            )}
          </Box>
          {style === RecipeStepStyle.Default && !!step.ingredients?.length && (
            <IngredientsCard ingredients={step.ingredients} />
          )}
          {style === RecipeStepStyle.Default && !!step.capability && (
            <CapabilityCard capability={step.capability} />
          )}
        </CardContent>
      </Card>
      {onDelete && (
        <CardDeleteButton
          label={ariaLabels.deleteButton}
          dragging={dragging}
          onDelete={onDelete}
        />
      )}
    </Box>
  );
});

interface SmartCardProps {
  title: string;
  children: ReactNode;
}
const SmartCard: FC<SmartCardProps> = memo(function SmartCard({
  title,
  children,
}) {
  return (
    <Box
      sx={{
        backgroundColor: PantryColor.SurfaceMuted,
        mt: 2,
        px: 4,
        py: 3,
        borderRadius: PantryCornerRadius.Small,
      }}
    >
      <Typography
        variant={PantryTypography.Body2SemiBold}
        color={PantryColor.FrescoPrimary}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
});

interface IngredientsCardProps {
  ingredients: AppRecipeStepIngredient[];
}
const IngredientsCard: FC<IngredientsCardProps> = memo(
  function IngredientsCard({ ingredients }) {
    return (
      <SmartCard title={titles.ingredients}>
        {ingredients.map((ingredient) => {
          const { name, preparationsAsString, quantityAsString } =
            getIngredientPropertiesAsStrings({
              ingredient: ingredient.ingredient,
              quantity: ingredient.quantity,
            });
          const ingredientId = `${ingredient.ingredient.id}-${ingredient.ingredientIdx}`;
          return (
            <Box
              key={ingredient.ingredientIdx}
              sx={{ mt: 2 }}
              role="listitem"
              aria-labelledby={ingredientId}
            >
              <Typography
                variant={PantryTypography.CaptionSemiBold}
                sx={{ mb: 1, display: 'block' }}
                color={PantryColor.TextDefault}
                id={ingredientId}
              >
                {name}
              </Typography>
              <Typography
                variant={PantryTypography.Caption}
                color={PantryColor.TextDefault}
                sx={{ display: 'block' }}
              >
                {quantityAsString}
              </Typography>
              {!!preparationsAsString?.length && (
                <Typography
                  variant={PantryTypography.Nav}
                  sx={{ mt: 1, display: 'block' }}
                  color={PantryColor.TextSubtle}
                >
                  {preparationsAsString}
                </Typography>
              )}
            </Box>
          );
        })}
      </SmartCard>
    );
  }
);

interface CapabilityCardProps {
  capability: AppRecipeStepCapability;
}
const CapabilityCard: FC<CapabilityCardProps> = memo(function ApplianceCard({
  capability,
}) {
  return (
    <SmartCard title={titles.capabilities}>
      <Typography
        variant={PantryTypography.Caption}
        sx={{ mt: 2, mb: 2, display: 'block' }}
        color={PantryColor.TextDefault}
      >
        {capitalize(capability.name)}
        {capability.phase && (
          <Typography
            variant={PantryTypography.Caption}
            sx={{ mt: 2, mb: 2 }}
            color={PantryColor.TextSubtle}
          >
            {` (${capability.phase.name})`}
          </Typography>
        )}
      </Typography>
      {!!capability.settings?.length && (
        <Grid container spacing={7}>
          {capability.settings.map((setting) => (
            <Grid
              item
              key={setting.id}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <CapabilitySettingFieldIcon
                settingId={setting.id}
                sx={{ color: PantryColor.IconDefault, mr: 1 }}
              />
              <Typography
                variant={PantryTypography.Body2}
                color={PantryColor.TextDefault}
              >
                {getSettingForReviewCard(setting)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </SmartCard>
  );
});

const getSettingForReviewCard = (setting: AppCapabilitySetting): string => {
  const { id, name, value } = setting;
  if (
    isVentingTimeSettingId(id) &&
    value.type === AppCapabilitySettingType.Time
  ) {
    return `${name}: ${fromAppTimeToSentence(value.value, true)}`;
  }

  if (isTimeSettingId(id) && value.type === AppCapabilitySettingType.Time) {
    return fromAppTimeToSentence(value.value, true);
  }

  if (value.type === AppCapabilitySettingType.Numeric) {
    return `${value.value} ${
      value.referenceUnit.abbreviation || value.referenceUnit.name
    }`;
  }

  if (value.type === AppCapabilitySettingType.Nominal) {
    return capitalize(value.referenceValue.name);
  }

  // Boolean
  return `${name}: ${value.value ? 'Yes' : 'No'}`;
};
