import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  CloseIcon,
  DeleteIcon,
  InformationCircleIcon,
  PantryColor,
  PantryTypography,
  Tooltip,
} from '@dropkitchen/pantry-react';
import {
  Checkbox,
  Autocomplete,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import produce from 'immer';
import uniqueId from 'lodash/uniqueId';
import type { FC, SyntheticEvent } from 'react';
import { useCallback, memo, useEffect, useState, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { CapabilityField } from 'components/CapabilityField/CapabilityField';
import type { CapabilitySettingsValidator } from 'components/CapabilityField/CapabilityField.types';
import {
  generateCapabilitySettingsValidator,
  validateCapabilitySettings,
} from 'components/CapabilityField/CapabilityField.validations';
import { ListRow } from 'components/ListRow/ListRow';
import { selectAppliances } from 'features/appliances/appliancesSlice';
import { toNumber } from 'features/recipe/ingredients/form/recipeIngredientForm.utils';
import type { AppRecipeIngredientsUsageStep } from 'features/recipe/recipeSlice';
import {
  createQuantity,
  recipeStepAdded,
  recipeStepUpdated,
  selectIngredientsByStep,
  selectRecipeIngredientsUsage,
  selectRecipeStep,
  selectRecipeLocale,
} from 'features/recipe/recipeSlice';
import { getQuantityAsText } from 'features/recipe/review/ingredients/recipeReviewIngredients.utils';
import { RecipeStepTextField } from 'features/recipe/shared/RecipeStepTextField/RecipeStepTextField';
import type { AppRecipeFieldChange } from 'features/recipe/shared/types/appRecipeFieldChange';
import { recipeStepsFormStrings } from 'features/recipe/steps/form/RecipeStepsForm.constants';
import { RecipeStepsFormIngredientQuantity } from 'features/recipe/steps/form/RecipeStepsFormIngredientQuantity';
import {
  initialState,
  stepReset,
  selectCapability,
  selectSettings,
  selectHasErrors,
  selectIndex,
  selectIngredients,
  selectSubmitted,
  selectText,
  stepApplianceUpdated,
  stepCapabilityUpdated,
  stepCapabilityTypeUpdated,
  stepSettingsUpdated,
  stepFieldValidated,
  stepIngredientsUpdated,
  stepSubmitted,
  stepTextUpdated,
  selectIsStepUnsaved,
  selectPhase,
  stepPhaseUpdated,
} from 'features/recipe/steps/form/recipeStepsFormSlice';
import { selectCapabilities } from 'features/referenceData/capabilities/capabilitiesSlice';
import { AppCapabilityType } from 'types/appCapability';
import type { AppRecipeStep } from 'types/recipe/appRecipeStep';
import { findApplianceByCapability } from 'utils/findApplianceByCapability';

const { labels, placeholders, testIds } = recipeStepsFormStrings;

export const getStepsAsString = (
  usageSteps: AppRecipeIngredientsUsageStep[]
): string => {
  return usageSteps.reduce((steps, { index }, i) => {
    if (i === 0) {
      return `${index + 1}`;
    }
    if (i === usageSteps.length - 1) {
      return `${steps} and ${index + 1}`;
    }
    return `${steps}, ${index + 1}`;
  }, '');
};

export interface RecipeStepsFormProps {
  onClose: () => void;
}

export const RecipeStepsForm: FC<RecipeStepsFormProps> = memo(
  function RecipeStepsForm({ onClose }) {
    const dispatch = useAppDispatch();
    const selectedStepIndex = useAppSelector(selectIndex);
    const ingredients = useAppSelector(
      useMemo(
        () => selectIngredientsByStep(selectedStepIndex),
        [selectedStepIndex]
      )
    );
    const ingredientsUsage = useAppSelector(selectRecipeIngredientsUsage);
    const appliances = useAppSelector(selectAppliances);
    const locale = useAppSelector(selectRecipeLocale);
    const generalCapabilities = useAppSelector(selectCapabilities(locale));
    const text = useAppSelector(selectText);
    const selectedIngredients = useAppSelector(selectIngredients);
    const capability = useAppSelector(selectCapability);
    const selectedSettings = useAppSelector(selectSettings);
    const selectedPhase = useAppSelector(selectPhase);
    const submitted = useAppSelector(selectSubmitted);
    const hasErrors = useAppSelector(selectHasErrors);
    const selectedStep = useAppSelector(selectRecipeStep(selectedStepIndex));
    const hasChanges = useAppSelector(selectIsStepUnsaved);

    const [capabilitySettingsValidator, setCapabilitySettingsValidator] =
      useState<CapabilitySettingsValidator>({});
    const [selectedIngredientIdx, setSelectedIngredientIdx] = useState<
      number | null
    >(null);

    const handleTextChange = useCallback(
      (change: AppRecipeFieldChange) => {
        dispatch(stepTextUpdated(change.value));
        dispatch(stepFieldValidated({ field: 'text', errors: change.errors }));
      },
      [dispatch]
    );

    const resetForm = useCallback(() => {
      dispatch(stepReset());
      dispatch(stepFieldValidated({ field: 'text', errors: undefined }));
    }, [dispatch]);

    useEffect(() => {
      setSelectedIngredientIdx(null);

      // Only load from recipe slice store if there is selected step and there is no changes
      if (selectedStepIndex !== null && !hasChanges) {
        if (!selectedStep) {
          resetForm();
          return;
        }

        dispatch(stepSubmitted(false));
        handleTextChange({ value: selectedStep.text });
        dispatch(stepIngredientsUpdated(selectedStep.ingredients));

        if (!selectedStep.capability) {
          dispatch(stepCapabilityTypeUpdated(initialState.capabilityType));
          dispatch(stepCapabilityUpdated(initialState.capability));
          dispatch(stepSettingsUpdated([]));
          return;
        }

        dispatch(stepCapabilityTypeUpdated(selectedStep.capability.type));

        const appliance =
          selectedStep.capability.type === AppCapabilityType.General
            ? null
            : findApplianceByCapability(selectedStep.capability.id, appliances);
        dispatch(stepApplianceUpdated(appliance));

        /** @todo Remove hardcoded appliance module once we have a UI solution to handle multiple modules {@link https://frescocooks.atlassian.net/browse/PIE-890} */
        const capabilities = appliance
          ? appliance.applianceModules[0].capabilities
          : generalCapabilities;

        const selectedCapability =
          capabilities?.find(({ id }) => id === selectedStep.capability?.id) ||
          null;
        dispatch(stepCapabilityUpdated(selectedCapability));
        dispatch(stepPhaseUpdated(selectedStep.capability.phase ?? null));
        dispatch(stepSettingsUpdated(selectedStep.capability.settings || []));
      }
    }, [
      appliances,
      dispatch,
      generalCapabilities,
      hasChanges,
      handleTextChange,
      resetForm,
      selectedStep,
      selectedStepIndex,
      selectedPhase,
    ]);

    useEffect(() => {
      setCapabilitySettingsValidator(
        capability
          ? generateCapabilitySettingsValidator(capability.allowedSettings)
          : {}
      );
    }, [capability]);

    useEffect(() => {
      dispatch(
        stepFieldValidated({
          field: 'settings',
          errors: validateCapabilitySettings(
            capabilitySettingsValidator,
            Object.values(selectedSettings)
          ),
        })
      );
    }, [capabilitySettingsValidator, selectedSettings, dispatch]);

    const handleSubmit = (event: SyntheticEvent) => {
      event.preventDefault();

      dispatch(stepSubmitted(true));

      if (hasErrors) {
        return;
      }

      const isNewStep = selectedStepIndex === null || !selectedStep;
      const step: AppRecipeStep = {
        id: isNewStep ? uniqueId() : selectedStep.id,
        text,
        ingredients: selectedIngredients,
        capability: capability
          ? {
              id: capability.id,
              name: capability.name,
              type: capability.type,
              settings: Object.values(selectedSettings),
              ...(selectedPhase && { phase: selectedPhase }),
            }
          : undefined,
      };

      if (isNewStep) {
        dispatch(recipeStepAdded(step));
        resetForm();
      } else {
        dispatch(recipeStepUpdated({ index: selectedStepIndex, step }));
      }
    };

    const handleRemoveIngredient = (ingredientIdx: number) => {
      dispatch(
        stepIngredientsUpdated(
          selectedIngredients.filter(
            (ingredient) => ingredient.ingredientIdx !== ingredientIdx
          )
        )
      );
    };

    const handleIngredientAmountEdit = (
      amount: number | null,
      stepIngredientIndex: number
    ): void => {
      const { quantity } = selectedIngredients[stepIngredientIndex];

      const editedQuantity = createQuantity({ unit: quantity.unit, amount });

      dispatch(
        stepIngredientsUpdated(
          produce(selectedIngredients, (draft) => {
            draft[stepIngredientIndex].quantity = editedQuantity;
          })
        )
      );
    };

    const handleClose = () => {
      resetForm();
      onClose();
    };

    return (
      <Box
        component="form"
        aria-label={labels.form}
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
          }}
        >
          <Typography
            variant={PantryTypography.Body1SemiBold}
            color={PantryColor.TextDefault}
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {!!selectedStep && selectedStepIndex !== null
              ? `Step ${selectedStepIndex + 1} - ${selectedStep.text}`
              : labels.addTitle}
          </Typography>
          <Button
            hideLabel
            buttonStyle={ButtonStyle.Subtle}
            size={ButtonSize.Medium}
            leadingIcon={CloseIcon}
            onClick={handleClose}
            label="Close"
          />
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <RecipeStepTextField
              id="step-text"
              autoFocus
              value={text}
              showErrors={submitted}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="step-selected-ingredients"
              value={selectedIngredients}
              isOptionEqualToValue={(option, value) =>
                option.ingredientIdx === value.ingredientIdx
              }
              onChange={(_, options) =>
                dispatch(stepIngredientsUpdated(options))
              }
              options={ingredients}
              getOptionLabel={({ ingredient }) => ingredient.name}
              disableCloseOnSelect
              renderTags={() => null}
              groupBy={({ isUsed }) => (isUsed ? 'Already used' : '')}
              getOptionDisabled={({ isUsed }) => !!isUsed}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={labels.ingredientsField}
                  placeholder={placeholders.ingredientsField}
                />
              )}
              renderOption={(
                props,
                { ingredient, ingredientIdx, isUsed },
                { selected }
              ) => {
                const usage = ingredientsUsage[ingredientIdx];
                if (usage && isUsed) {
                  return (
                    <Box
                      component="li"
                      {...props}
                      key={ingredientIdx}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            typography: PantryTypography.Body2SemiBold,
                            color: PantryColor.TextMuted,
                          }}
                        >
                          {ingredient.name}
                        </Box>
                        <Box
                          component="span"
                          sx={{
                            typography: PantryTypography.Nav,
                            color: PantryColor.TextMuted,
                          }}
                        >
                          used in step {getStepsAsString(usage.used.steps)}
                        </Box>
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          typography: PantryTypography.Caption,
                          color: PantryColor.TextMuted,
                        }}
                      >
                        {getQuantityAsText(usage.used.quantity)}
                      </Box>
                    </Box>
                  );
                }
                return (
                  <Box
                    component="li"
                    {...props}
                    key={ingredientIdx}
                    sx={{
                      typography: PantryTypography.Body2,
                      color: PantryColor.TextDefault,
                      pl: 0,
                    }}
                  >
                    <Checkbox checked={selected} />
                    {ingredient.name}
                  </Box>
                );
              }}
            />
          </Grid>
          {!!selectedIngredients.length && (
            <Grid item xs={12}>
              <Box alignItems="center" sx={{ display: 'flex' }}>
                <Typography
                  variant={PantryTypography.Body2SemiBold}
                  color={PantryColor.FrescoPrimary}
                  sx={{ mr: 1 }}
                >
                  {labels.addedIngredients}
                </Typography>
                <Tooltip message={labels.addedIngredientsTooltip}>
                  <InformationCircleIcon
                    color={PantryColor.IconDefault}
                    data-testid={testIds.addedIngredientsInfoIcon}
                    size={16}
                    sx={{ display: 'flex' }}
                  />
                </Tooltip>
              </Box>
              <Divider
                sx={{ borderColor: PantryColor.BorderSubtle, mb: 3, mt: 2 }}
              />
              {selectedIngredients.map(
                (
                  { ingredient, ingredientIdx, quantity },
                  stepIngredientIndex
                ) => {
                  const quantityAsString = getQuantityAsText({
                    amount: quantity.amount,
                    unit: quantity.unit,
                  });
                  return (
                    <Box key={ingredientIdx}>
                      {ingredientIdx !== selectedIngredientIdx && (
                        <ListRow
                          secondaryAction={
                            <Button
                              buttonStyle={ButtonStyle.Subtle}
                              hideLabel
                              label={labels.removeIngredientButton(
                                ingredient.name
                              )}
                              leadingIcon={DeleteIngredientIcon}
                              onClick={() =>
                                handleRemoveIngredient(ingredientIdx)
                              }
                              size={ButtonSize.Small}
                              sx={{ mr: 2 }}
                            />
                          }
                          sx={{ mb: 2 }}
                          title={`${ingredient.name} - ${quantityAsString}`}
                          onClick={() => {
                            if (ingredient.quantity.amount !== null) {
                              setSelectedIngredientIdx(ingredientIdx);
                            }
                          }}
                        />
                      )}
                      {ingredientIdx === selectedIngredientIdx && (
                        <RecipeStepsFormIngredientQuantity
                          ingredient={ingredient}
                          quantity={quantity}
                          ingredientIdx={ingredientIdx}
                          selectedStepIndex={selectedStepIndex}
                          onAccept={(amount) =>
                            handleIngredientAmountEdit(
                              toNumber(amount),
                              stepIngredientIndex
                            )
                          }
                          onClose={() => setSelectedIngredientIdx(null)}
                        />
                      )}
                    </Box>
                  );
                }
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <CapabilityField />
          </Grid>
          <Grid item xs={12}>
            <Button
              type={ButtonType.Submit}
              label="Save"
              size={ButtonSize.Large}
              buttonStyle={ButtonStyle.Emphasis}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

const DeleteIngredientIcon: FC = memo(function DeleteIngredientIcon() {
  return <DeleteIcon size={16} color={PantryColor.IconDefault} />;
});
