import { getQuantityAsText } from 'features/recipe/review/ingredients/recipeReviewIngredients.utils';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';

export const recipeIngredientFormStrings = {
  labels: {
    ingredientField: 'Ingredient',
    formAdd: 'Add New Ingredient',
    formEdit: (ingredientName: string) => `Edit ${ingredientName}`,
    addedPreparations: 'Added preparations',
    removePreparationButton: (preparation: string) => `Remove ${preparation}`,
  },
  errors: {
    quantity: {
      notZero: 'Quantity cannot be 0',
      notNumber: 'Quantity must be a positive number',
      higherThanMax: (max: number) =>
        `Enter a value less than ${max} or increase the total amount`,
      lowerThanUsed: (quantity: AppRecipeIngredientQuantity) =>
        `${getQuantityAsText(
          quantity
        )} is used in one or more steps. The new amount cannot be less.`,
    },
  },
  messages: {
    noIngredientSelected: 'Your ingredient details will appear here',
  },
} as const;

export const recipeIngredientAmountConstants = {
  fractionsSeparator: '/',
  precision: 3,
  pattern: /^\d+([/|.]?\d+)?$/,
} as const;
