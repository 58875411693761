import { Box, Paper } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { stylesCenterContent } from 'app/theme';

export interface DetailLayoutProps {
  children: ReactNode;
}

export const DetailLayout: FC<DetailLayoutProps> = memo(function DetailLayout({
  children,
}) {
  return (
    <Box sx={{ ...stylesCenterContent }}>
      <Paper
        sx={{ boxShadow: '0px 15px 40px rgba(0, 0, 0, 0.1)', borderRadius: 1 }}
      >
        {children}
      </Paper>
    </Box>
  );
});
