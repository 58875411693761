import { Skeleton, SkeletonVariant } from '@dropkitchen/pantry-react';
import type { Theme } from '@emotion/react';
import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { RecipeType } from 'app/routes/constants';
import { useAppSelector } from 'app/store/hooks';
import type { TableLoadingOverlayProps } from 'components/Table/Table';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { recipesListConstants } from 'features/recipes/list/RecipesList.constants';
import { selectRecipeType } from 'features/recipes/recipesSlice';
import { AppFeature } from 'types/appFeature';

const { table } = recipesListConstants;

const tableCellStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  /** Padding added by MUI Grid to each cell */
  px: '10px',
};
const skeletonStyle: SxProps<Theme> = { borderRadius: 0.5 };

export const RecipesListCheckboxSkeleton: FC = memo(
  function RecipesListCheckboxSkeleton() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 50,
        }}
      >
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ ...skeletonStyle, width: 16, height: 16 }}
        />
      </Box>
    );
  }
);

export const RecipesListIdSkeleton: FC = memo(function RecipesListIdSkeleton() {
  return (
    <Skeleton
      variant={SkeletonVariant.Small}
      sx={{ ...skeletonStyle, width: 66, height: 21 }}
    />
  );
});

export const RecipesListMainInfoSkeleton: FC = memo(
  function RecipesListMainInfoSkeleton() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 4,
        }}
      >
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ ...skeletonStyle, width: 56, height: 56 }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Skeleton
            variant={SkeletonVariant.Small}
            sx={{ ...skeletonStyle, width: 221, height: 21 }}
          />
          <Skeleton
            variant={SkeletonVariant.Small}
            sx={{ ...skeletonStyle, width: 116, height: 18 }}
          />
        </Box>
      </Box>
    );
  }
);

export const RecipesListStateSkeleton: FC = memo(
  function RecipesListStateSkeleton() {
    return (
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ ...skeletonStyle, width: 72, height: 20 }}
      />
    );
  }
);

export const RecipesListLocaleSkeleton: FC = memo(
  function RecipesListLocaleSkeleton() {
    return (
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ ...skeletonStyle, width: 72, height: 20 }}
      />
    );
  }
);

export const RecipesListTranslationsSkeleton: FC = memo(
  function RecipesListTranslationsSkeleton() {
    return (
      <>
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ ...skeletonStyle, width: 30, height: 20, mr: 0.5 }}
        />
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ ...skeletonStyle, width: 30, height: 20, mr: 0.5 }}
        />
        <Skeleton
          variant={SkeletonVariant.Small}
          sx={{ ...skeletonStyle, width: 30, height: 20 }}
        />
      </>
    );
  }
);

export const RecipesListApplianceSkeleton: FC = memo(
  function RecipesListApplianceSkeleton() {
    return (
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ ...skeletonStyle, width: 178, height: 21 }}
      />
    );
  }
);

export const RecipesListDateSkeleton: FC = memo(
  function RecipesListDateSkeleton() {
    return (
      <Skeleton
        variant={SkeletonVariant.Small}
        sx={{ ...skeletonStyle, width: 120, height: 21 }}
      />
    );
  }
);

export const RecipesListSkeleton: FC<TableLoadingOverlayProps> = memo(
  function RecipesListSkeleton({ rowHeight, rowsPerPage, checkboxSelection }) {
    const recipeType = useAppSelector(selectRecipeType);

    return (
      <Box
        aria-label={table.skeletonAriaLabel}
        sx={{
          /** Take the border of the header into account */
          mt: '1px',
        }}
      >
        {Array.from({ length: rowsPerPage }, (_, index) => (
          <Box
            role="row"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            sx={{
              height: rowHeight,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {checkboxSelection && <RecipesListCheckboxSkeleton />}
            <Box
              sx={{
                ...tableCellStyle,
                ...table.columns.mainInfo,
              }}
            >
              <RecipesListMainInfoSkeleton />
            </Box>
            <Box
              sx={{
                ...tableCellStyle,
                ...table.columns.status,
              }}
            >
              <RecipesListStateSkeleton />
            </Box>
            <ToggleableFeature
              requires={AppFeature.TranslationManagement}
              components={{ whenDisabled: null }}
            >
              <Box
                sx={{
                  ...tableCellStyle,
                  ...table.columns.locale,
                }}
              >
                <RecipesListLocaleSkeleton />
              </Box>
            </ToggleableFeature>
            {recipeType === RecipeType.Core && (
              <ToggleableFeature
                requires={AppFeature.TranslationManagement}
                components={{ whenDisabled: null }}
              >
                <Box
                  sx={{
                    ...tableCellStyle,
                    ...table.columns.translations,
                  }}
                >
                  <RecipesListTranslationsSkeleton />
                </Box>
              </ToggleableFeature>
            )}
            <Box
              sx={{
                ...tableCellStyle,
                ...table.columns.appliances,
              }}
            >
              <RecipesListApplianceSkeleton />
            </Box>
            <Box
              sx={{
                ...tableCellStyle,
                ...table.columns.id,
              }}
            >
              <RecipesListIdSkeleton />
            </Box>
            <Box
              sx={{
                ...tableCellStyle,
                justifyContent: 'flex-end',
                ...table.columns.modifiedAt,
              }}
            >
              <RecipesListDateSkeleton />
            </Box>
          </Box>
        ))}
      </Box>
    );
  }
);
