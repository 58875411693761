import {
  PantryColor,
  PantryTypography,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { CardContent, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { Card } from 'components/Card/Card';
import { getIngredientPropertiesAsStrings } from 'features/recipe/review/ingredients/recipeReviewIngredients.utils';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';

export enum RecipeReviewIngredientStyle {
  Default = 'default',
  Muted = 'muted',
}
interface RecipeReviewIngredientProps {
  ingredient: AppRecipeIngredient;
  style?: RecipeReviewIngredientStyle;
  sx?: SxProps;
}

export const RecipeReviewIngredient: FC<RecipeReviewIngredientProps> = memo(
  function RecipeReviewIngredient({
    ingredient,
    style = RecipeReviewIngredientStyle.Default,
    sx,
  }) {
    const {
      name,
      preparationsAsString: preparations,
      quantityAsString: quantity,
    } = getIngredientPropertiesAsStrings({
      ingredient,
      quantity: ingredient.quantity,
    });

    const nameStyle: SxProps = (() => {
      if (style === RecipeReviewIngredientStyle.Muted) {
        return { color: PantryColor.TextMuted };
      }
      return { color: PantryColor.TextDefault };
    })();

    const quantityStyle: SxProps = (() => {
      if (style === RecipeReviewIngredientStyle.Muted) {
        return { color: PantryColor.TextMuted };
      }
      return { color: PantryColor.TextDefault };
    })();

    const preparationsStyle: SxProps = (() => {
      if (style === RecipeReviewIngredientStyle.Muted) {
        return { color: PantryColor.TextMuted };
      }
      return { color: PantryColor.TextSubtle };
    })();

    return (
      <Card sx={sx}>
        <CardContent>
          <Typography
            variant={PantryTypography.Body2SemiBold}
            sx={sxCompose({ mb: 2 }, nameStyle)}
          >
            {name}
          </Typography>
          <Typography variant={PantryTypography.Caption} sx={quantityStyle}>
            {quantity}
          </Typography>
          {!!preparations?.length && (
            <Typography
              variant={PantryTypography.Nav}
              sx={sxCompose({ mt: 2, display: 'block' }, preparationsStyle)}
            >
              {preparations}
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
);
