import { Grid } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { generateRecipeRoute } from 'app/routes/routesUtils';
import { useAppSelector } from 'app/store/hooks';
import { RecipeTabName } from 'features/recipe/RecipePage.constants';
import {
  selectRecipe,
  selectRecipeFetching,
} from 'features/recipe/recipeSlice';
import { recipeReviewStrings } from 'features/recipe/review/RecipeReview.constants';
import { RecipeReviewBasicInformation } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformation';
import { recipeReviewBasicInformationStrings } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformation.constants';
import { RecipeReviewBasicInformationSkeleton } from 'features/recipe/review/basicInformation/RecipeReviewBasicInformationSkeleton';
import { RecipeReviewIngredients } from 'features/recipe/review/ingredients/RecipeReviewIngredients';
import { recipeReviewIngredientsStrings } from 'features/recipe/review/ingredients/RecipeReviewIngredients.constants';
import {
  RecipeReviewEmptyValue,
  RecipeReviewEmptyValueType,
} from 'features/recipe/review/shared/RecipeReviewEmptyValue/RecipeReviewEmptyValue';
import { RecipeReviewListSkeleton } from 'features/recipe/review/shared/RecipeReviewListSkeleton/RecipeReviewListSkeleton';
import { RecipeReviewSteps } from 'features/recipe/review/steps/RecipeReviewSteps';
import { recipeReviewStepsStrings } from 'features/recipe/review/steps/RecipeReviewSteps.constants';
import { RecipeTabContainer } from 'features/recipe/shared/RecipeTabContainer/RecipeTabContainer';

const { sections } = recipeReviewStrings;
const { emptyRequiredFields } = recipeReviewBasicInformationStrings;

export const RecipeReview: FC = memo(function RecipeReview() {
  const recipe = useAppSelector(selectRecipe);
  const isFetching = useAppSelector(selectRecipeFetching);

  return (
    <RecipeTabContainer
      title={
        !isFetching
          ? {
              string: recipe.name,
              component: !recipe.name && (
                <RecipeReviewEmptyValue
                  type={RecipeReviewEmptyValueType.Critical}
                  emptyLink={{
                    to: generateRecipeRoute({
                      id: recipe.id,
                      tab: RecipeTabName.Information,
                    }),
                    text: emptyRequiredFields.links.recipeNameField,
                  }}
                  message={emptyRequiredFields.message}
                />
              ),
            }
          : undefined
      }
    >
      <Grid container>
        <Grid item sm={7}>
          {isFetching ? (
            <RecipeReviewBasicInformationSkeleton />
          ) : (
            <RecipeReviewBasicInformation />
          )}
          {isFetching ? (
            <RecipeReviewListSkeleton title={sections.ingredients} />
          ) : (
            <RecipeReviewIngredients
              ingredients={recipe.ingredients}
              emptyLink={{
                to: generateRecipeRoute({
                  id: recipe.id,
                  tab: RecipeTabName.Ingredients,
                }),
                text: recipeReviewIngredientsStrings.messages.emptyLink,
              }}
            />
          )}
          {isFetching ? (
            <RecipeReviewListSkeleton title={sections.steps} />
          ) : (
            <RecipeReviewSteps
              steps={recipe.steps}
              emptyLink={{
                to: generateRecipeRoute({
                  id: recipe.id,
                  tab: RecipeTabName.Steps,
                }),
                text: recipeReviewStepsStrings.messages.emptyLink,
              }}
            />
          )}
        </Grid>
      </Grid>
    </RecipeTabContainer>
  );
});
