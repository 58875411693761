import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { recipeDescriptionFieldStrings } from 'features/recipe/shared/RecipeDescriptionField/RecipeDescriptionField.constants';
import type { AppRecipeFieldChange } from 'features/recipe/shared/types/appRecipeFieldChange';

export interface RecipeDescriptionFieldProps
  extends Pick<TextFieldProps, 'id' | 'value'> {
  onChange: (change: AppRecipeFieldChange) => void;
}

const { label, placeholder } = recipeDescriptionFieldStrings;

export const RecipeDescriptionField: FC<RecipeDescriptionFieldProps> = memo(
  function RecipeDescriptionField({ id, value, onChange }) {
    return (
      <TextField
        id={id}
        label={label}
        type="text"
        placeholder={placeholder}
        variant="outlined"
        multiline
        fullWidth
        rows={4}
        value={value}
        onChange={(event) => onChange({ value: event.target.value })}
      />
    );
  }
);
