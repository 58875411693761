import type { SxProps } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { memo } from 'react';
import type { FC } from 'react';

import type { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { StatusBadge } from 'components/Badge/StatusBadge';

interface RecipeStatusBadgeProps {
  status: ApiRcpRecipeState;
  sx?: SxProps;
}

export const RecipeStatusBadge: FC<RecipeStatusBadgeProps> = memo(
  function RecipeStatusBadge({ status, sx }) {
    return <StatusBadge status={status} sx={sx} text={capitalize(status)} />;
  }
);
