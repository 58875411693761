import { ExpandMoreIcon, PantryColor } from '@dropkitchen/pantry-react';
import type { SxProps, Theme } from '@mui/material';
import {
  Box,
  useTheme,
  TextField,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import type { FC } from 'react';
import { useEffect, useState, memo, useMemo } from 'react';

import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import { capabilitySettingFieldConstants } from 'components/CapabilityField/CapabilitySettingField.constants';
import { ErrorHelperText } from 'components/ErrorHelperText/ErrorHelperText';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';
import type { ValidatorError } from 'utils/validator';

const borderWidth = 1;
const paddingX = 14;
const paddingY = 11;

export interface QuantityFieldProps {
  quantity: AppRecipeIngredientQuantity | null;
  units: ApiQuantityUnit[];
  onChange: (quantity: {
    amount: number | null;
    unit: ApiQuantityUnit;
  }) => void;
  errors?: ValidatorError;
  placeholder: string;
  id?: string;
  sx?: SxProps<Theme>;
}

export const QuantityField: FC<QuantityFieldProps> = memo(
  function QuantityField({
    quantity,
    units,
    onChange,
    errors = {},
    placeholder,
    id: fieldId,
    sx,
  }) {
    const [unit, setUnit] = useState<ApiQuantityUnit | null>(null);
    const [amount, setAmount] = useState<number | null>(null);
    const theme = useTheme();

    const uniqueUnits = useMemo(
      () => uniqBy(units, (item) => item.id),
      [units]
    );

    useEffect(() => {
      setAmount(quantity?.amount ?? null);
      const selectedUnit =
        quantity && units.find(({ id }) => id === quantity.unit.id);
      setUnit(selectedUnit || units[0]);
    }, [quantity, units]);

    const handleAmountChange = (value: number | null): void => {
      setAmount(value);
      if (value === 0 || !unit) {
        return;
      }
      onChange({ amount: value, unit });
    };

    const handleUnitChange = (unitId: string): void => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const selectedUnit = units.find(({ id }) => id === unitId)!;
      setUnit(selectedUnit);
      if (!amount) {
        return;
      }
      onChange({
        amount: +amount,
        unit: selectedUnit,
      });
    };

    const hasErrors = !!Object.values(errors).length;
    const errorMessage = Object.values(errors).join(
      capabilitySettingFieldConstants.errorsJoinWord
    );

    return (
      <Box data-testid={fieldId}>
        <Paper
          variant="outlined"
          sx={{
            alignItems: 'center',
            backgroundColor: theme.palette.background.paper,
            border: `${borderWidth}px solid ${
              hasErrors ? theme.palette.error.main : theme.palette.grey[400]
            }`,
            boxSizing: 'border-box',
            display: 'flex',
            gap: `${paddingY}px`,
            justifyContent: 'space-between',
            px: `${paddingX}px`,
            py: `${paddingY}px`,
            width: '100%',
            '&:hover': {
              borderColor: hasErrors
                ? theme.palette.error.main
                : theme.palette.grey[900],
            },
            '&:focus-within': {
              border: `${borderWidth * 2}px solid ${
                hasErrors
                  ? theme.palette.error.main
                  : theme.palette.primary.main
              }`,
              py: `${paddingY - borderWidth}px`,
              px: `${paddingX - borderWidth}px`,
            },
            ...sx,
          }}
        >
          <TextField
            id={fieldId}
            fullWidth
            variant="standard"
            placeholder={placeholder}
            type="number"
            inputProps={{
              'aria-label': 'Amount',
            }}
            sx={{
              /*
               * Remove underline added by the standard TextField so Amount and Unit controls
               * look and feel as a unique control
               */
              '& .MuiInput-underline:before': {
                content: 'none',
              },
              '& .MuiInput-underline:after': {
                content: 'none',
              },
            }}
            onChange={(event) =>
              handleAmountChange(
                event.target.value !== '' ? +event.target.value : null
              )
            }
            value={amount || ''}
          />
          <Select
            variant="standard"
            sx={{
              '&::before': {
                content: 'none',
              },
              '&::after': {
                content: 'none',
              },
              '& .MuiSelect-standard:focus': {
                backgroundColor: 'unset',
              },
              // This way we make the custom ExpandIcon clickable
              '& .MuiSelect-standard': {
                zIndex: 1,
              },
            }}
            IconComponent={ExpandIcon}
            value={unit?.id || ''}
            inputProps={{ 'aria-label': 'Unit' }}
            onChange={(event) => handleUnitChange(event.target.value)}
          >
            {uniqueUnits.map(({ id, abbreviation, name }) => (
              <MenuItem key={id} value={id}>
                {abbreviation || name}
              </MenuItem>
            ))}
          </Select>
        </Paper>
        {errorMessage && <ErrorHelperText message={errorMessage} />}
      </Box>
    );
  }
);

const ExpandIcon: FC = memo(function ExpandIcon() {
  return (
    <ExpandMoreIcon
      color={PantryColor.IconDefault}
      size={20}
      sx={{ position: 'absolute', right: 0 }}
    />
  );
});
