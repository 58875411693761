import type { Transition, History, Blocker } from 'history';
import { useCallback, useEffect, useState, useContext } from 'react';
import {
  useLocation,
  useNavigate,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';

export interface UseNavigationPromptParams {
  isEnabled: boolean;
  /**
   * Array of paths that should not block navigation, regardless the when parameter value
   */
  excludedPaths?: string[];
}

export function useNavigationPrompt({
  isEnabled: when,
  excludedPaths,
}: UseNavigationPromptParams) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<Transition | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation: Transition) => {
      const nextPath = nextLocation.location.pathname;
      if (excludedPaths?.includes(nextPath)) {
        setLastLocation(nextLocation);
        confirmNavigation();
        return true;
      }
      if (!confirmedNavigation && nextPath !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location, excludedPaths, confirmNavigation]
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);

      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useBlocker({ blocker: handleBlockedNavigation, isEnabled: when });

  return { showPrompt, confirmNavigation, cancelNavigation };
}

interface UseBlockerParams {
  blocker: Blocker;
  isEnabled?: boolean;
}

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 */
function useBlocker({ blocker, isEnabled = true }: UseBlockerParams): void {
  const navigator = useContext(NavigationContext).navigator as History;

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, isEnabled]);
}
