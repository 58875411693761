import {
  PantryColor,
  PantryTypography,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface EmptyContentProps {
  components: {
    actions?: ReactNode;
    image: ReactNode;
  };
  sx?: SxProps;
  text: string;
  title?: string;
}

export const EmptyContent: FC<EmptyContentProps> = memo(function EmptyContent({
  components,
  sx,
  text,
  title,
}) {
  return (
    <Box
      sx={sxCompose(
        {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          textAlign: 'center',
        },
        sx
      )}
    >
      <Box sx={{ mb: '30px' }}>{components.image}</Box>
      {title && (
        <Typography
          variant={PantryTypography.H6}
          color={PantryColor.TextDefault}
          sx={{ mb: '10px' }}
        >
          {title}
        </Typography>
      )}
      <Typography
        variant={PantryTypography.Body1}
        sx={{ color: PantryColor.TextSubtle, mb: '30px' }}
      >
        {text}
      </Typography>
      {components.actions}
    </Box>
  );
});
