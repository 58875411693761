import { Button, ButtonSize, ButtonStyle } from '@dropkitchen/pantry-react';
import { Grid, TextField, formHelperTextClasses } from '@mui/material';
import type { FC, SyntheticEvent } from 'react';
import { useEffect, memo, useState } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { Dialog } from 'components/Dialog/Dialog';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { recipeFromTextDialogStrings } from 'features/RecipeFromTextDialog/RecipeFromTextDialog.constants';
import { RecipeFromTextDismissalDialog } from 'features/RecipeFromTextDialog/RecipeFromTextDismissalDialog';
import { selectConfigsLocales } from 'features/configs/configsSlice';
import {
  recipeGetFromTextRequested,
  selectRecipeFetching,
} from 'features/recipe/recipeSlice';
import { RecipeLocaleField } from 'features/recipe/shared/RecipeLocaleField/RecipeLocaleField';
import { AppFeature } from 'types/appFeature';

export type RecipeFromTextDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const { titles, labels, helpers, buttons } = recipeFromTextDialogStrings;

export const RecipeFromTextDialog: FC<RecipeFromTextDialogProps> = memo(
  function RecipeFromTextDialog({ isOpen, onClose }) {
    const dispatch = useAppDispatch();

    const isFetching = useAppSelector(selectRecipeFetching);
    const supportedLocales = useAppSelector(selectConfigsLocales);

    const [locale, setLocale] = useState<ApiLocale | null>(
      supportedLocales?.[0] ?? null
    );
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [ingredients, setIngredients] = useState<string>('');
    const [steps, setSteps] = useState<string>('');
    const [isMainDialogOpen, setIsMainDialogOpen] = useState<boolean>(isOpen);
    const [isDismissalDialogOpen, setIsDismissalDialogOpen] =
      useState<boolean>(false);

    useEffect(() => {
      setIsMainDialogOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
      setLocale(supportedLocales?.[0] ?? null);
    }, [supportedLocales]);

    const cleanFields = () => {
      setName('');
      setDescription('');
      setIngredients('');
      setSteps('');
      setLocale(supportedLocales?.[0] ?? null);
    };

    const handleClose = () => {
      if (!!name || !!description || !!ingredients || !!steps) {
        setIsMainDialogOpen(false);
        setIsDismissalDialogOpen(true);
      } else {
        handleCloseConfirmation();
      }
    };

    const handleCloseConfirmation = () => {
      cleanFields();
      setIsDismissalDialogOpen(false);
      onClose();
    };

    const handleSubmit = (e: SyntheticEvent) => {
      if (!locale) {
        // This shouldn't happen as the button is disabled if there isn't a locale
        return;
      }

      if (e.type === 'click') {
        dispatch(
          recipeGetFromTextRequested({
            locale,
            name: name.trim(),
            description: description.trim(),
            ingredients: ingredients.split('\n').filter((item) => !!item),
            steps: steps.split('\n').filter((item) => !!item),
          })
        );
      }
    };

    return (
      <>
        <Dialog
          title={titles.createFromText}
          isOpen={isMainDialogOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
          size="sm"
          components={{
            content: (
              <Grid container spacing={6}>
                <ToggleableFeature
                  requires={AppFeature.TranslationManagement}
                  components={{ whenDisabled: null }}
                >
                  <Grid item xs={12}>
                    <RecipeLocaleField
                      onChange={(event) => setLocale(event)}
                      value={locale}
                      autoFocus
                    />
                  </Grid>
                </ToggleableFeature>
                <Grid item xs={12}>
                  <TextField
                    value={name}
                    name="name"
                    InputLabelProps={{ shrink: true }}
                    required
                    onChange={(event) => setName(event.target.value)}
                    label={labels.name}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={description}
                    name="description"
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ sx: { lineHeight: '18px' } }}
                    onChange={(event) => setDescription(event.target.value)}
                    label={labels.description}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={ingredients}
                    name="ingredients"
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ sx: { lineHeight: '18px' } }}
                    onChange={(event) => setIngredients(event.target.value)}
                    label={labels.ingredients}
                    helperText={
                      <HelperText
                        message={helpers.ingredients}
                        sx={{ mt: 2 }}
                        severity={HelperTextSeverity.Neutral}
                      />
                    }
                    fullWidth
                    variant="outlined"
                    sx={{
                      [`& > .${formHelperTextClasses.root}`]: {
                        ml: 0,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={steps}
                    name="steps"
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ sx: { lineHeight: '18px' } }}
                    onChange={(event) => setSteps(event.target.value)}
                    label={labels.steps}
                    helperText={
                      <HelperText
                        message={helpers.steps}
                        sx={{ mt: 2 }}
                        severity={HelperTextSeverity.Neutral}
                      />
                    }
                    fullWidth
                    variant="outlined"
                    sx={{
                      [`& > .${formHelperTextClasses.root}`]: {
                        ml: 0,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            ),
            buttons: (
              <>
                <Button
                  label={buttons.cancel}
                  buttonStyle={ButtonStyle.Subtle}
                  size={ButtonSize.Large}
                  onClick={handleClose}
                />
                <Button
                  label={buttons.continue}
                  buttonStyle={ButtonStyle.Emphasis}
                  size={ButtonSize.Large}
                  loading={isFetching}
                  disabled={!name || !locale}
                  onClick={(e) => handleSubmit(e)}
                />
              </>
            ),
          }}
        />
        <RecipeFromTextDismissalDialog
          isOpen={isDismissalDialogOpen}
          onClose={() => {
            setIsDismissalDialogOpen(false);
            setIsMainDialogOpen(true);
          }}
          onSubmit={handleCloseConfirmation}
        />
      </>
    );
  }
);
