import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from 'app/store/rootReducer';

export interface ErrorState {
  errors: string[];
}
export const initialState: ErrorState = {
  errors: [],
};

export const errorSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    errorOccurred(state, { payload: error }: PayloadAction<string>) {
      state.errors.push(error);
    },
    errorDismissed(state, { payload: index }: PayloadAction<number>) {
      state.errors.splice(index, 1);
    },
  },
});

export const {
  reducer: errorReducer,
  actions: { errorOccurred, errorDismissed },
} = errorSlice;

export const selectErrors = (state: RootState) => state.error.errors;
