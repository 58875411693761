import type { ApiAplCapabilityAllowedSettingValue } from 'api/types/appliance/apiAplCapabilityAllowedSettingValue';
import type { ApiAplCapabilitySettingValue } from 'api/types/appliance/apiAplCapabilitySettingValue';
import { ApiCapabilitySettingType } from 'api/types/common/apiCapabilitySettingType';
import type { ApiEntityId } from 'api/types/common/apiEntityId';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import type { AppCapabilitySettingValueBoolean } from 'types/appCapabilitySettingValueBoolean';
import type { AppCapabilitySettingValueNominal } from 'types/appCapabilitySettingValueNominal';
import type { AppCapabilitySettingValueNumeric } from 'types/appCapabilitySettingValueNumeric';
import type { AppCapabilitySettingValueTime } from 'types/appCapabilitySettingValueTime';
import { isTimeSettingId } from 'types/appCapabilitySettings.utils';
import { fromSecondsToAppTime } from 'utils/convertTimes';

export type AppCapabilitySettingValue =
  | AppCapabilitySettingValueBoolean
  | AppCapabilitySettingValueNumeric
  | AppCapabilitySettingValueNominal
  | AppCapabilitySettingValueTime;

export const fromApiAplCapabilitySettingValue = (
  defaultValue: ApiAplCapabilitySettingValue,
  allowedValues: ApiAplCapabilityAllowedSettingValue,
  settingId: ApiEntityId
): AppCapabilitySettingValue => {
  const { type, value } = defaultValue;
  if (type === ApiCapabilitySettingType.Nominal && allowedValues.nominal) {
    return {
      type: AppCapabilitySettingType.Nominal,
      referenceValue: { id: value.id, name: value.name },
    };
  }
  if (type === ApiCapabilitySettingType.Numeric && allowedValues.numeric) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { referenceUnit } = allowedValues.numeric.find(
      ({ referenceUnit: { id } }) => id === value.referenceUnitId
    )!;
    if (isTimeSettingId(settingId)) {
      return {
        type: AppCapabilitySettingType.Time,
        value: fromSecondsToAppTime(value.value, { removeZeros: true }),
        referenceUnit,
      };
    }
    return {
      type: AppCapabilitySettingType.Numeric,
      value: value.value,
      referenceUnit,
    };
  }
  if (type === ApiCapabilitySettingType.Boolean && allowedValues.boolean) {
    return {
      type: AppCapabilitySettingType.Boolean,
      value: value.value,
    };
  }
  throw new Error(
    `Default value: ${JSON.stringify(
      value
    )} is invalid. Allowed values are ${JSON.stringify(allowedValues)}`
  );
};
