import type { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import type { AppConfigAuthor } from 'api/configs';
import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import type { ApiLocale } from 'api/types/common/apiLocale';
import { fromAppRecipe } from 'api/types/recipe/recipeWithRefs/apiRcpExistingRecipeWithRefs';
import { appRecipeRoutes } from 'app/routes/constants';
import { selectConfigsAuthor } from 'features/configs/configsSlice';
import { errorOccurred } from 'features/error/errorSlice';
import {
  apiFetchRecipeSaga,
  apiPutRecipeSaga,
  getApplianceTags,
} from 'features/recipe/recipeSagas';
import { apiFetchRecipesSaga } from 'features/recipes/recipesSagas';
import type { TagsById } from 'features/referenceData/tags/tagsSlice';
import { recipeTranslationStrings } from 'features/translation/RecipeTranslation.constants';
import {
  recipeFetchFailed,
  recipeFetchRequested,
  recipeFetchSucceed,
  selectTranslatedRecipe,
  translatedRecipeFetchFailed,
  translatedRecipeFetchRequested,
  translatedRecipeFetchSucceed,
  translatedRecipeSaveFailed,
  translatedRecipeSaveRequested,
  translatedRecipeSaveFinished,
  selectRecipeTranslationHasErrors,
} from 'features/translation/recipeTranslationSlice';
import type { AppRecipe } from 'types/recipe/appRecipe';
import { fromApiRcpRecipe } from 'types/recipe/appRecipe';

export function* fetchRecipe({ payload: recipeId }: PayloadAction<string>) {
  const response = (yield call(apiFetchRecipeSaga, {
    recipeId,
  })) as ApiRequestSagaReturnType<typeof apiFetchRecipeSaga>;
  if (!response.ok) {
    yield put(recipeFetchFailed(response.details.message));
    yield put(errorOccurred(response.details.message));
    return;
  }

  const applianceTags = (yield call(
    getApplianceTags,
    response.data.locale
  )) as TagsById;
  yield put(recipeFetchSucceed(fromApiRcpRecipe(response.data, applianceTags)));
}

export function* fetchTranslatedRecipe({
  payload: { forkedFromId, locale },
}: PayloadAction<{ forkedFromId: string; locale: ApiLocale }>) {
  const response = (yield call(apiFetchRecipesSaga, {
    from: 0,
    searchTerm: '',
    size: 1, // Assume there will be only one fork for a given combination of forked_from_id + locale
    forkedFromId,
    locales: [locale],
  })) as ApiRequestSagaReturnType<typeof apiFetchRecipesSaga>;

  if (!response.ok) {
    yield put(translatedRecipeFetchFailed(response.details.message));
    yield put(errorOccurred(response.details.message));
    return;
  }

  if (!response.data.total) {
    // We couldn't find the recipe. We redirect to the list and display an error
    yield put(push(`/${appRecipeRoutes.root}`));
    yield put(
      errorOccurred(recipeTranslationStrings.errors.translationNotFound)
    );
    return;
  }

  const applianceTags = (yield call(getApplianceTags, locale)) as TagsById;
  yield put(
    translatedRecipeFetchSucceed(
      fromApiRcpRecipe(response.data.items[0], applianceTags)
    )
  );
}

export function* saveTranslatedRecipe() {
  const hasErrors = (yield select(selectRecipeTranslationHasErrors)) as boolean;
  if (hasErrors) {
    yield put(translatedRecipeSaveFinished());
    return;
  }

  const translatedRecipe = (yield select(selectTranslatedRecipe)) as AppRecipe;
  const authorData = (yield select(selectConfigsAuthor)) as AppConfigAuthor;
  const recipeWithExtraData: AppRecipe = {
    ...translatedRecipe,
    author: { ...authorData, name: translatedRecipe.author.name },
  };
  const response = (yield call(apiPutRecipeSaga, {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    recipeId: recipeWithExtraData.id!,
    recipe: fromAppRecipe(recipeWithExtraData),
  })) as ApiRequestSagaReturnType<typeof apiPutRecipeSaga>;

  if (!response.ok) {
    yield put(translatedRecipeSaveFailed(response.details.message));
    yield put(errorOccurred(response.details.message));
    return;
  }
  yield put(translatedRecipeSaveFinished());
}

function* recipeFetchWatcher() {
  yield takeLatest(recipeFetchRequested, fetchRecipe);
}

function* translatedRecipeFetchWatcher() {
  yield takeLatest(translatedRecipeFetchRequested, fetchTranslatedRecipe);
}

function* translatedRecipeSaveWatcher() {
  yield takeLatest(translatedRecipeSaveRequested, saveTranslatedRecipe);
}

export const recipeTranslationRestartableSagas = [
  recipeFetchWatcher,
  translatedRecipeFetchWatcher,
  translatedRecipeSaveWatcher,
];
