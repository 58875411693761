import capitalize from 'lodash/capitalize';

import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';

interface GetIngredientPropertiesAsStringsParams {
  ingredient: AppRecipeIngredient;
  quantity: AppRecipeIngredientQuantity;
}

export const getIngredientPropertiesAsStrings = ({
  ingredient,
  quantity,
}: GetIngredientPropertiesAsStringsParams): {
  name: string;
  quantityAsString: string;
  preparationsAsString: string | undefined;
} => {
  return {
    name: capitalize(ingredient.name),
    quantityAsString: getQuantityAsText(quantity),
    preparationsAsString: ingredient.preparations
      ?.map((preparation) => preparation.name)
      .join(', '),
  };
};

export const getQuantityAsText = (
  quantity: AppRecipeIngredientQuantity
): string => {
  const quantityAmount = quantity.amount !== null ? `${quantity.amount} ` : '';
  const quantityUnit = quantity.unit.abbreviation || quantity.unit.name;
  return quantity.text ?? `${quantityAmount}${quantityUnit}`;
};
