import type { ApiRcpRecipeIngredient } from 'api/types/recipe/apiRcpRecipeIngredient';
import type { ApiRcpRecipeStepIngredient } from 'api/types/recipe/apiRcpRecipeStepIngredient';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import { fromApiRcpRecipeIngredient } from 'types/recipe/appRecipeIngredient';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';
import { fromApiRcpRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';

export interface AppRecipeStepIngredient {
  ingredientIdx: number;
  ingredient: AppRecipeIngredient;
  quantity: AppRecipeIngredientQuantity;
  isUsed?: boolean;
}

export function fromApiRcpRecipeStepIngredient({
  apiStepIngredient,
  recipeIngredients,
}: {
  apiStepIngredient: ApiRcpRecipeStepIngredient;
  recipeIngredients: ApiRcpRecipeIngredient[];
}): AppRecipeStepIngredient {
  return {
    ingredientIdx: apiStepIngredient.ingredientIdx,
    ingredient: fromApiRcpRecipeIngredient(
      recipeIngredients[apiStepIngredient.ingredientIdx]
    ),
    quantity: fromApiRcpRecipeIngredientQuantity(apiStepIngredient.quantity),
  };
}
