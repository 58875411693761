import { formatDistance } from 'date-fns';
import type { FC } from 'react';
import { useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import { recipePageStrings } from 'features/recipe/RecipePage.constants';
import {
  selectRecipeLastSavedAt,
  selectRecipeApiError,
  selectRecipeHasErrors,
  selectRecipeHasUnsavedChanges,
  selectRecipeSaving,
  recipeSaveBeforeLeavingRequested,
} from 'features/recipe/recipeSlice';
import { getCurrentTime } from 'utils/getCurrentTime';

export interface LeaveWithUnsavedChangesDialogProps {
  onCancel: () => void;
  onConfirm: () => void;
}
export const LeaveWithUnsavedChangesDialog: FC<LeaveWithUnsavedChangesDialogProps> =
  memo(function LeaveWithUnsavedChangesDialog({ onCancel, onConfirm }) {
    const dispatch = useAppDispatch();
    const { recipeId } = useParams();

    const hasChanges = useAppSelector(selectRecipeHasUnsavedChanges);
    const isSaving = useAppSelector(selectRecipeSaving);
    const apiError = useAppSelector(selectRecipeApiError);
    const hasErrors = useAppSelector(selectRecipeHasErrors);
    const lastSavedAt = useAppSelector(selectRecipeLastSavedAt);

    const [open, setOpen] = useState<boolean>(false);
    const { labels: modalLabels } = recipePageStrings;
    const isEdit = !!recipeId;

    useEffect(() => {
      // There are unsaved changes. Try to save them before displaying the modal.
      if (isEdit) {
        dispatch(recipeSaveBeforeLeavingRequested(recipeId));
        return;
      }
      // We're creating a recipe. We don't dispatch anything but just show the modal.
      setOpen(true);
    }, [dispatch, recipeId, isEdit]);

    useEffect(() => {
      // Recipe has been saved. Go to the new location
      if (!hasChanges) {
        onConfirm();
        return;
      }
      // Recipe has an error. Display modal.
      if (apiError || hasErrors) {
        setOpen(true);
      }
    }, [apiError, hasChanges, hasErrors, isSaving, onConfirm]);

    const handleClose = (hasConfirmed: boolean) => {
      if (hasConfirmed) {
        onConfirm();
      } else {
        onCancel();
      }
      setOpen(false);
    };

    return (
      <ConfirmationDialog
        isOpen={open}
        text={{
          ...modalLabels.leaveModal,
          body: getDialogBodyText({ apiError, hasErrors, isEdit, lastSavedAt }),
        }}
        onClose={handleClose}
      />
    );
  });

export const getDialogBodyText = ({
  apiError,
  hasErrors,
  isEdit,
  lastSavedAt,
}: {
  apiError: string | undefined;
  hasErrors: boolean;
  isEdit: boolean;
  lastSavedAt: number | undefined;
}) => {
  const { labels: modalLabels } = recipePageStrings;
  const now = getCurrentTime();
  const timeAgo = formatDistance(lastSavedAt ?? now, now);
  let message = '';

  if (hasErrors || apiError) {
    message += `${modalLabels.leaveModal.bodyErrors}`;
  }

  if (hasErrors) {
    message += ` ${modalLabels.leaveModal.bodyValidationErrors} `;
  }

  if (apiError) {
    message += `${!hasErrors ? '. ' : ''}${apiError} `;
  }

  message += `${modalLabels.leaveModal.body} `;

  if (isEdit) {
    message += modalLabels.leaveModal.bodySavedAgo(timeAgo);
  }

  return message;
};
