import type { FC } from 'react';
import { memo } from 'react';

import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  recipeDiscardChanges,
  recipeUnpublishRequested,
  selectRecipe,
  selectRecipeHasUnsavedChanges,
  selectRecipeUnpublishing,
} from 'features/recipe/recipeSlice';

import { publishedRecipeUpdatedDialogStrings } from './PublishedRecipeUpdatedDialog.constants';

const { title, body, confirmButton, cancelButton } =
  publishedRecipeUpdatedDialogStrings;

export const PublishedRecipeUpdatedDialog: FC = memo(
  function PublishedRecipeUpdatedDialog() {
    const dispatch = useAppDispatch();

    const recipe = useAppSelector(selectRecipe);
    const unpublishing = useAppSelector(selectRecipeUnpublishing);
    const hasUnsavedChanges = useAppSelector(selectRecipeHasUnsavedChanges);

    const isOpen =
      recipe.state === ApiRcpRecipeState.Published && hasUnsavedChanges;

    return (
      <ConfirmationDialog
        isOpen={isOpen}
        text={{ title, body, confirmButton, cancelButton }}
        isConfirmButtonLoading={unpublishing}
        onClose={(hasConfirmed) =>
          dispatch(
            hasConfirmed
              ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                recipeUnpublishRequested(recipe.id!)
              : recipeDiscardChanges()
          )
        }
      />
    );
  }
);
