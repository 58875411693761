const constants = {
  tab: {
    height: 115,
    condensed: {
      height: 64,
    },
    iconSize: '32px',
  },
  animationDuration: '250ms',
} as const;

export const tabsListConstants = {
  ...constants,
  stickPoint: {
    scrollingDownOffset: 10,
    /**
     * Using the height difference when the tab is in its full form versus its condensed form as an offset for
     * the stick point because that prevents the animation to flicker due to the tab height being changed
     */
    scrollingUpOffset: constants.tab.height - constants.tab.condensed.height,
  },
} as const;
