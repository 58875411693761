import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState, memo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ConfirmationDialog } from 'components/ConfirmationDialog/ConfirmationDialog';
import type { DropResult } from 'components/DragAndDrop/DragZone';
import {
  DragZone,
  calculateIndexAfterReordering,
} from 'components/DragAndDrop/DragZone';
import { Draggable } from 'components/DragAndDrop/Draggable';
import { RecipeIngredientForm } from 'features/recipe/ingredients/form/RecipeIngredientForm';
import {
  selectIndex,
  selectIsIngredientUnsaved,
  ingredientIndexUpdated,
  ingredientReset,
  ingredientFormShown,
} from 'features/recipe/ingredients/form/recipeIngredientFormSlice';
import { RecipeIngredient } from 'features/recipe/ingredients/list/RecipeIngredient';
import {
  recipeIngredientParams,
  recipeIngredientStrings,
} from 'features/recipe/ingredients/list/RecipeIngredient.constants';
import {
  recipeIngredientDeleted,
  recipeIngredientMoved,
  selectRecipeIngredients,
} from 'features/recipe/recipeSlice';
import { RecipePageListWithFormContainer } from 'features/recipe/shared/RecipePageListWithFormContainer/RecipePageListWithFormContainer';

const {
  deleteIngredientDialog: { title, body, confirmButton, cancelButton },
} = recipeIngredientStrings;

export const recipeIngredientsTitle = 'Ingredients';
export const noIngredientsText = 'Your prep info will appear here';
export const addIngredientsButtonText = 'Add Ingredient(s)';

export const RecipeIngredients: FC = memo(function RecipeIngredients() {
  const dispatch = useAppDispatch();
  const recipeIngredients = useAppSelector(selectRecipeIngredients);
  const selectedIngredientIndex = useAppSelector(selectIndex);
  const hasUnsavedIngredient = useAppSelector(selectIsIngredientUnsaved);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [ingredientToDeleteIndex, setIngredientToDeleteIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (selectedIngredientIndex !== null || hasUnsavedIngredient) {
      dispatch(ingredientFormShown(true));
    }
  }, [selectedIngredientIndex, hasUnsavedIngredient, dispatch]);

  const deleteIngredient = () => {
    if (ingredientToDeleteIndex === null) {
      throw new Error(
        'You have to set the index of the ingredient to be deleted first'
      );
    }
    if (ingredientToDeleteIndex === selectedIngredientIndex) {
      resetIngredient();
    }
    dispatch(recipeIngredientDeleted(ingredientToDeleteIndex));
    if (
      selectedIngredientIndex &&
      ingredientToDeleteIndex < selectedIngredientIndex
    ) {
      dispatch(ingredientIndexUpdated(selectedIngredientIndex - 1));
    }
    setIngredientToDeleteIndex(null);
  };

  const resetIngredient = () => dispatch(ingredientReset());

  const handleDrop = ({ from, to }: DropResult) => {
    dispatch(recipeIngredientMoved({ from, to }));

    if (selectedIngredientIndex === null) {
      return;
    }

    dispatch(
      ingredientIndexUpdated(
        calculateIndexAfterReordering(selectedIngredientIndex, from, to)
      )
    );
  };

  return (
    <>
      <RecipePageListWithFormContainer
        title={recipeIngredientsTitle}
        buttons={{
          add: {
            label: addIngredientsButtonText,
            onClick: () => {
              resetIngredient();
              dispatch(ingredientFormShown(true));
            },
          },
        }}
        components={{
          list: !recipeIngredients.length ? (
            <Typography
              variant={PantryTypography.Body1}
              color={PantryColor.TextSubtle}
              gutterBottom
            >
              {noIngredientsText}
            </Typography>
          ) : (
            <DragZone
              dragZoneId="ingredients-list"
              onDrop={handleDrop}
              componentProps={{ placeholder: { sx: { pb: 6 } } }}
            >
              {recipeIngredients.map((recipeIngredient, index) => (
                <Draggable
                  // Using index as key as we can add same ingredient more than once so ingredient.id is not suitable
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  draggableId={index}
                  index={index}
                  renderContent={(props) => (
                    <RecipeIngredient
                      ingredient={recipeIngredient}
                      onClick={() => {
                        dispatch(ingredientReset());
                        dispatch(ingredientIndexUpdated(index));
                      }}
                      onDelete={() => {
                        setIngredientToDeleteIndex(index);
                        setIsDeleteDialogOpen(true);
                      }}
                      selected={index === selectedIngredientIndex}
                      draggable
                      {...props}
                    />
                  )}
                />
              ))}
            </DragZone>
          ),
          form: (
            <RecipeIngredientForm
              onCancel={() => {
                dispatch(ingredientFormShown(false));
                resetIngredient();
              }}
            />
          ),
        }}
      />
      {ingredientToDeleteIndex !== null && (
        <ConfirmationDialog
          isOpen={isDeleteDialogOpen}
          text={{
            body,
            confirmButton,
            cancelButton,
            title: title.replace(
              recipeIngredientParams.ingredientName,
              `"${recipeIngredients[ingredientToDeleteIndex].name}"`
            ),
          }}
          onClose={(hasConfirmed) => {
            setIsDeleteDialogOpen(false);
            if (hasConfirmed) {
              deleteIngredient();
            }
          }}
        />
      )}
    </>
  );
});
