import { Grid } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { useAppSelector } from 'app/store/hooks';
import { selectShowOnlyEditableFields } from 'features/translation/recipeTranslationSlice';

export const RecipeTranslationDetailsSection: FC<{ children: ReactNode[] }> =
  memo(function RecipeTranslationDetailsSection({ children }) {
    const showOnlyEditableFields = useAppSelector(selectShowOnlyEditableFields);

    return (
      <Grid container rowGap={4} columnSpacing={6}>
        <Grid item sm={12}>
          {children[0]}
        </Grid>
        {!showOnlyEditableFields && (
          <Grid item sm={4}>
            {children[1]}
          </Grid>
        )}
        <Grid item sm={!showOnlyEditableFields ? 8 : 12}>
          {children[2]}
        </Grid>
      </Grid>
    );
  });
