export const recipeFromTextDialogStrings = {
  titles: {
    createFromText: 'Create a recipe from text',
  },
  labels: {
    name: 'Recipe name',
    description: 'Recipe description',
    ingredients: 'Ingredients',
    steps: 'Steps',
  },
  helpers: {
    ingredients:
      'Each ingredient (name, quantity and prep) should be represented one per line',
    steps: 'Each step should be represented one per line',
  },
  buttons: {
    cancel: 'Cancel',
    continue: 'Continue',
  },
} as const;
