import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRcpRecipeStepCapability } from 'api/types/recipe/apiRcpRecipeStepCapability';
import type { AppCapabilityType } from 'types/appCapability';
import { getCapabilityType } from 'types/appCapability';
import type { AppCapabilityAllowedPhase } from 'types/appCapabilityAllowedPhase';
import type { AppCapabilitySetting } from 'types/appCapabilitySetting';
import { fromApiRcpRecipeStepCapabilitySetting } from 'types/appCapabilitySetting';

export interface AppRecipeStepCapability {
  id: ApiEntityId;
  name: string;
  type: AppCapabilityType;
  settings?: AppCapabilitySetting[];
  phase?: AppCapabilityAllowedPhase;
}

export function fromApiRcpRecipeStepCapability({
  referenceCapability,
  settings,
  phase,
}: ApiRcpRecipeStepCapability): AppRecipeStepCapability {
  return {
    id: referenceCapability.id,
    name: referenceCapability.name,
    type: getCapabilityType(referenceCapability.id),
    ...(settings && {
      settings: settings?.map(fromApiRcpRecipeStepCapabilitySetting),
    }),
    phase,
  };
}
