import type { PayloadAction } from '@reduxjs/toolkit';
import { select, put, takeEvery, call } from 'redux-saga/effects';

import { apiGetCapabilities } from 'api/capabilities';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import type { ApiList, ApiResponse } from 'api/types';
import type { ApiRefCapability } from 'api/types/referenceData/apiRefCapability';
import { errorOccurred } from 'features/error/errorSlice';
import {
  capabilitiesFetchFailed,
  capabilitiesFetching,
  capabilitiesFetchRequested,
  capabilitiesFetchSucceed,
  selectShouldFetchCapabilities,
} from 'features/referenceData/capabilities/capabilitiesSlice';
import type { ReferenceDataFetchActionPayload } from 'features/referenceData/types/referenceDataFetchActionPayload';
import { fromApiRefCapability } from 'types/appCapability';

export const apiFetchCapabilitiesSaga =
  createApiRequestSaga(apiGetCapabilities);

export function* fetchCapabilities({
  payload: { locale },
}: PayloadAction<ReferenceDataFetchActionPayload>) {
  const shouldFetch = (yield select(
    selectShouldFetchCapabilities(locale)
  )) as boolean;
  if (!shouldFetch) {
    return;
  }

  yield put(capabilitiesFetching({ locale }));
  const response = (yield call(apiFetchCapabilitiesSaga, {
    locale,
  })) as ApiResponse<ApiList<ApiRefCapability>>;
  if (!response.ok) {
    yield put(
      capabilitiesFetchFailed({ locale, data: response.details.message })
    );
    yield put(errorOccurred(response.details.message));
    return;
  }

  yield put(
    capabilitiesFetchSucceed({
      locale,
      data: response.data.items.map(fromApiRefCapability),
    })
  );
}

function* capabilitiesFetchWatcher() {
  yield takeEvery(capabilitiesFetchRequested, fetchCapabilities);
}

export const capabilitiesSagas = [capabilitiesFetchWatcher];
