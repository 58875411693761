import { PantryColor, sxCompose } from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import {
  Card as MuiCard,
  CardActionArea,
  cardActionAreaClasses,
  cardContentClasses,
} from '@mui/material';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

export interface CardProps {
  onClick?: () => void;
  selected?: boolean;
  children: ReactNode;
  ariaLabel?: string;
  dragging?: boolean;
  draggable?: boolean;
  sx?: SxProps;
}

const draggingStyles: SxProps = {
  boxShadow: '0px 16px 32px 0px rgba(25, 26, 34, 0.1)',
  transform: 'rotate(3deg)',
};

export const Card: FC<CardProps> = memo(function Card({
  onClick,
  selected,
  children,
  ariaLabel,
  dragging = false,
  draggable = false,
  sx,
}) {
  return (
    <MuiCard
      raised={selected}
      sx={sxCompose(
        sx,
        {
          boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.08)',
          transition: 'transform 300ms',
          [`.${cardContentClasses.root}:last-of-type`]: {
            // Override default padding-bottom of 24px added by MUI
            pb: 4,
          },
          [`.${cardActionAreaClasses.root}`]: {
            border: '1px solid',
            borderRadius: 1,
            borderColor:
              !selected && !dragging
                ? PantryColor.BorderSubtle
                : PantryColor.FrescoPrimary,
            transition: 'border-color 300ms',

            [`&:hover, &.${cardActionAreaClasses.focusVisible}`]: {
              borderColor: !selected
                ? PantryColor.BorderDefault
                : PantryColor.FrescoPrimary,

              [`.${cardActionAreaClasses.focusHighlight}`]: {
                opacity: 0,
              },
            },
          },
        },
        dragging && draggingStyles
      )}
      data-testid="card"
    >
      {!!onClick && (
        <CardActionArea
          onClick={onClick}
          aria-label={ariaLabel}
          sx={draggable ? { cursor: 'move' } : { cursor: 'pointer' }}
        >
          {children}
        </CardActionArea>
      )}
      {!onClick && children}
    </MuiCard>
  );
});
