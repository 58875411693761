import { put, call, select, takeEvery, all } from 'redux-saga/effects';

import { apiGetAppliance, apiGetAppliances } from 'api/appliances';
import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
/** [TMX-POC] Commented for the PoC */
// import { appConfig } from 'config/config';
import type { ApiAplCapabilityType } from 'api/types/appliance/apiAplCapabilityType';
import {
  appliancesFetchFailed,
  appliancesFetching,
  appliancesFetchRequested,
  appliancesFetchSucceed,
  selectShouldFetchAppliances,
} from 'features/appliances/appliancesSlice';
import { runConfigSelector } from 'features/configs/configsSagas';
import { selectConfigsSupportedApplianceCapabilityTypes } from 'features/configs/configsSlice';
import { fromApiAplAppliance } from 'types/appliance/appAppliance';

export const apiFetchApplianceSaga = createApiRequestSaga(apiGetAppliance);
export const apiFetchAppliancesSaga = createApiRequestSaga(apiGetAppliances);

export function* fetchAppliances() {
  const shouldFetch = (yield select(selectShouldFetchAppliances)) as boolean;
  if (!shouldFetch) {
    return;
  }

  yield put(appliancesFetching());

  const response = (yield call(
    apiFetchAppliancesSaga
  )) as ApiRequestSagaReturnType<typeof apiFetchAppliancesSaga>;

  if (!response.ok) {
    yield put(appliancesFetchFailed(response.details.message));
    return;
  }

  const appliances = (yield all(
    response.data.items
      /** [TMX-POC] Commented because for tmx poc, we don't filter the appliances by org namespace */
      // .filter(({ id }) => id.includes(appConfig.organizationId()))
      .map((appliance) => call(fetchAppliance, appliance.id))
  )) as ApiRequestSagaReturnType<typeof fetchAppliance>[];

  // If there is an API error for any of the appliances, fetchAppliance saga will return null.
  // Therefore, we remove null values from the array
  yield put(appliancesFetchSucceed(appliances.filter(Boolean)));
}

export function* fetchAppliance(applianceId: string) {
  const response = (yield call(
    apiFetchApplianceSaga,
    applianceId
  )) as ApiRequestSagaReturnType<typeof apiFetchApplianceSaga>;

  if (!response.ok) {
    return null;
  }
  const supportedApplianceCapabilityTypes = (yield call(
    runConfigSelector,
    selectConfigsSupportedApplianceCapabilityTypes
  )) as ApiAplCapabilityType[];
  return fromApiAplAppliance(response.data, supportedApplianceCapabilityTypes);
}

function* appliancesFetchWatcher() {
  yield takeEvery(appliancesFetchRequested, fetchAppliances);
}

export const appliancesRestartableSagas = [appliancesFetchWatcher];
