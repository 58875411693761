import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '@dropkitchen/pantry-react';
import type { FC } from 'react';
import { memo } from 'react';

import { Dialog } from 'components/Dialog/Dialog';

export type ConfirmationDialogProps = {
  isOpen: boolean;
  text: {
    title: string;
    body: string;
    confirmButton: string;
    cancelButton: string;
  };
  isConfirmButtonLoading?: boolean;
  onClose: (hasConfirmed: boolean) => void;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = memo(
  function ConfirmationDialog({
    isOpen,
    onClose,
    text,
    isConfirmButtonLoading = false,
  }) {
    return (
      <Dialog
        title={text.title}
        isOpen={isOpen}
        onClose={() => onClose(false)}
        onSubmit={() => onClose(true)}
        size="sm"
        components={{
          content: text.body,
          buttons: (
            <>
              <Button
                label={text.cancelButton}
                buttonStyle={ButtonStyle.Subtle}
                size={ButtonSize.Large}
                onClick={() => onClose(false)}
              />
              <Button
                label={text.confirmButton}
                type={ButtonType.Submit}
                buttonStyle={ButtonStyle.Emphasis}
                size={ButtonSize.Large}
                loading={isConfirmButtonLoading}
              />
            </>
          ),
        }}
      />
    );
  }
);
