import type { ApiRcpRecipeStepIngredient } from 'api/types/recipe/apiRcpRecipeStepIngredient';
import type { ApiRcpIngredientQuantityWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpIngredientQuantityWithRefs';
import { fromAppRecipeIngredientQuantity } from 'api/types/recipe/recipeWithRefs/apiRcpIngredientQuantityWithRefs';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';

export interface ApiRcpRecipeStepIngredientWithRefs
  extends Pick<ApiRcpRecipeStepIngredient, 'ingredientIdx'> {
  quantity: ApiRcpIngredientQuantityWithRefs;
}

export function fromAppRecipeStepIngredient({
  ingredientIdx,
  quantity,
}: AppRecipeStepIngredient): ApiRcpRecipeStepIngredientWithRefs {
  return {
    ingredientIdx,
    quantity: fromAppRecipeIngredientQuantity(quantity),
  };
}
