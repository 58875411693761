import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  PantryColor,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import { Box, Grid, TextField, Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useState, memo } from 'react';
import type { StringSchema, ValidationError } from 'yup';
import { string } from 'yup';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { ErrorHelperText } from 'components/ErrorHelperText/ErrorHelperText';
import { FrescoLogo } from 'components/FrescoLogo/FrescoLogo';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { signInPageStrings } from 'features/auth/SignInPage.constants';
import {
  authSignInRequested,
  selectAuthIsSignInPending,
  selectAuthSignInError,
} from 'features/auth/authSlice';

const { errors, labels } = signInPageStrings;

const formSchema: Record<string, StringSchema> = {
  email: string().email(errors.invalidEmail),
};

export const SignInPage: FC = memo(function SignInPage() {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const isPending = useAppSelector(selectAuthIsSignInPending);
  const apiError = useAppSelector(selectAuthSignInError);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState<string>('');

  const validateEmail = useCallback(
    (onValid: () => void): void => {
      formSchema.email
        .validate(email)
        .then(() => {
          onValid();
        })
        .catch((error: ValidationError) => setEmailError(error.errors[0]));
    },
    [email]
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateEmail(() => {
      setEmailError('');
      dispatch(authSignInRequested({ username: email, password }));
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);

    if (emailError) {
      validateEmail(() => {
        setEmailError('');
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 16 }}>
      <Box sx={{ width: '100%', maxWidth: 380, p: 4 }}>
        <FrescoLogo
          sx={{
            height: 24,
            width: 'auto',
            mb: 8,
            color: theme.palette.primary.main,
          }}
        />
        <Typography
          variant={PantryTypography.H5}
          color={PantryColor.TextDefault}
          sx={{ mb: 8 }}
        >
          {labels.title}
        </Typography>
        <Grid container spacing={8} component="form" onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <TextField
              label={labels.email}
              value={email}
              error={!!emailError}
              helperText={
                emailError && (
                  <HelperText
                    message={emailError}
                    severity={HelperTextSeverity.Critical}
                  />
                )
              }
              onChange={handleChange}
              onBlur={() => {
                validateEmail(() => setEmailError(''));
              }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={labels.password}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          {apiError && (
            <Grid item xs={12}>
              <ErrorHelperText message={apiError} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type={ButtonType.Submit}
              fullWidth
              size={ButtonSize.Large}
              label={labels.signIn}
              buttonStyle={ButtonStyle.Emphasis}
              loading={isPending}
              disabled={!email || !password}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
