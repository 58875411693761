import type { TextFieldProps } from '@mui/material';
import { Autocomplete, TextField, formHelperTextClasses } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiLocale } from 'api/types/common/apiLocale';
import { useAppSelector } from 'app/store/hooks';
import {
  HelperText,
  HelperTextSeverity,
} from 'components/HelperText/HelperText';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { selectConfigsLocales } from 'features/configs/configsSlice';
import { recipeLocaleFieldStrings } from 'features/recipe/shared/RecipeLocaleField/RecipeLocaleField.constants';
import { AppFeature } from 'types/appFeature';

const { label, placeholder, hint, getOptionText } = recipeLocaleFieldStrings;

interface RecipeLocaleFieldProps extends Pick<TextFieldProps, 'autoFocus'> {
  value: ApiLocale | null;
  onChange: (locale: ApiLocale | null) => void;
}

export const RecipeLocaleField: FC<RecipeLocaleFieldProps> = memo(
  function RecipeLocaleField({ value, onChange, autoFocus }) {
    const supportedTranslationLocales = useAppSelector(selectConfigsLocales);

    return (
      <ToggleableFeature
        requires={AppFeature.TranslationManagement}
        components={{ whenDisabled: null }}
      >
        <Autocomplete
          fullWidth
          options={supportedTranslationLocales ?? []}
          onChange={(_e, option) => {
            onChange(option);
          }}
          getOptionLabel={(locale) => getOptionText(locale)}
          value={value}
          renderInput={(props) => (
            <TextField
              type="text"
              label={label}
              placeholder={placeholder}
              variant="outlined"
              required
              autoFocus={autoFocus}
              helperText={
                <HelperText
                  message={hint}
                  severity={HelperTextSeverity.Neutral}
                  sx={{
                    mt: 2,
                  }}
                />
              }
              sx={{
                [`& > .${formHelperTextClasses.root}`]: {
                  ml: 0,
                },
              }}
              {...props}
            />
          )}
        />
      </ToggleableFeature>
    );
  }
);
