import { appliancesRestartableSagas } from 'features/appliances/appliancesSagas';
import { authRestartableSagas } from 'features/auth/authSagas';
import { mediaRestartableSagas } from 'features/media/mediaSagas';
import { recipeRestartableSagas } from 'features/recipe/recipeSagas';
import { recipesRestartableSagas } from 'features/recipes/recipesSagas';
import { referenceDataRestartableSagas } from 'features/referenceData/referenceDataSagas';
import { recipeTranslationRestartableSagas } from 'features/translation/recipeTranslationSagas';

/**
 * Most of app sagas are using `takeEvery` so in case if they accidentally crash
 * it makes sense to report an error and restart them automatically so they could
 * continue handling other actions
 */
export const getRestartableSagas = (): ((...args: unknown[]) => unknown)[] => [
  ...authRestartableSagas,
  ...mediaRestartableSagas,
  ...recipeRestartableSagas,
  ...referenceDataRestartableSagas,
  ...recipesRestartableSagas,
  ...appliancesRestartableSagas,
  ...recipeTranslationRestartableSagas,
];
