export const recipesSearchStrings = {
  buttons: {
    clear: 'CLEAR',
  },
  placeholders: {
    textField: 'Search for a recipe',
  },
} as const;

export const recipeSearchConstants = {
  width: 832,
} as const;
