import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import type { ApiRequestSagaReturnType } from 'api/createApiRequestSaga';
import { createApiRequestSaga } from 'api/createApiRequestSaga';
import { MediaImageType, MediaResourceType } from 'api/media';
import type { ApiGetRecipeFromTextRequest } from 'api/recipes';
import {
  apiGetRecipe,
  apiPostRecipe,
  apiPublishRecipe,
  apiPutRecipe,
  apiGetRecipeFromUrl,
  apiGetRecipeFromText,
  apiUnpublishRecipe,
} from 'api/recipes';
import type { ApiLocale } from 'api/types/common/apiLocale';
import { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import { fromAppRecipe as toExistingRecipeWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpExistingRecipeWithRefs';
import { fromAppRecipe as toNewRecipeWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpNewRecipeWithRefs';
import { generateRecipeRoute } from 'app/routes/routesUtils';
import { errorOccurred } from 'features/error/errorSlice';
import {
  mediaUploadFailed,
  mediaUploadRequested,
  mediaUploadFinished,
} from 'features/media/mediaSlice';
import {
  recipePageConstants,
  RecipeTabName,
} from 'features/recipe/RecipePage.constants';
import type { RecipeGetFromUrlPayload } from 'features/recipe/recipeSlice';
import {
  recipeFetchFailed,
  recipeFetchRequested,
  recipeFetchSucceed,
  recipePublishFailed,
  recipePublishing,
  recipePublishRequested,
  recipePublishSucceed,
  recipeSaveFailed,
  recipeSaveRequested,
  recipeSaveFinished,
  recipeSaving,
  selectRecipe,
  recipeGetFromUrlRequested,
  recipeSubmitted,
  selectRecipeHasErrors,
  recipeUnpublishRequested,
  recipeUnpublishFailed,
  recipeUnpublishing,
  recipeUnpublishSucceed,
  recipeGetFromTextRequested,
  recipeHasChanged,
  selectRecipeHasUnsavedChanges,
  recipeAutoSaveRequested,
  recipeFieldUpdated,
  recipeIngredientAdded,
  recipeIngredientDeleted,
  recipeIngredientMoved,
  recipeIngredientUpdated,
  recipeStepAdded,
  recipeStepDeleted,
  recipeStepMoved,
  recipeStepUpdated,
  recipeSaveBeforeLeavingRequested,
  selectRecipeHasUnusedIngredients,
  recipePublishConfirmationRequired,
  recipePublishConfirmed,
  recipePublishCanceled,
} from 'features/recipe/recipeSlice';
import type { TagsById } from 'features/referenceData/tags/tagsSlice';
import {
  tagsFetchRequested,
  selectTagsFetching,
  selectShouldFetchTags,
  tagsFetchFailed,
  tagsFetchSucceed,
  selectApplianceTags,
} from 'features/referenceData/tags/tagsSlice';
import type { AppRecipe } from 'types/recipe/appRecipe';
import { fromApiRcpRecipe, fromApiRusRecipe } from 'types/recipe/appRecipe';

export const apiFetchRecipeSaga = createApiRequestSaga(apiGetRecipe);
export const apiPostRecipeSaga = createApiRequestSaga(apiPostRecipe);
export const apiPutRecipeSaga = createApiRequestSaga(apiPutRecipe);
export const apiPublishRecipeSaga = createApiRequestSaga(apiPublishRecipe);
export const apiUnpublishRecipeSaga = createApiRequestSaga(apiUnpublishRecipe);
export const apiGetRecipeFromUrlSaga =
  createApiRequestSaga(apiGetRecipeFromUrl);
export const apiGetRecipeFromTextSaga =
  createApiRequestSaga(apiGetRecipeFromText);

export const recipeActionsThat = {
  autosave: [
    recipeAutoSaveRequested.type,
    recipeSaveBeforeLeavingRequested.type,
  ],
  triggerAutosaving: [
    recipeIngredientAdded.type,
    recipeIngredientUpdated.type,
    recipeIngredientDeleted.type,
    recipeIngredientMoved.type,
    recipeStepAdded.type,
    recipeStepUpdated.type,
    recipeStepDeleted.type,
    recipeStepMoved.type,
    recipeFieldUpdated.type,
  ],
  cancelAutosaving: [
    recipeSaveRequested.type,
    recipeSaveBeforeLeavingRequested.type,
  ],
};

export function* saveRecipe({
  payload: recipeId,
  type,
}: PayloadAction<string | undefined>) {
  const isAutosaving = recipeActionsThat.autosave.includes(type);
  yield put(recipeSubmitted());

  const hasErrors = (yield select(selectRecipeHasErrors)) as boolean;
  if (hasErrors) {
    yield put(recipeSaveFinished({ success: false }));
    return;
  }

  yield put(recipeSaving());
  const recipe = (yield select(selectRecipe)) as AppRecipe;
  const isCreate = !recipeId;

  let response;
  if (isCreate) {
    response = (yield call(apiPostRecipeSaga, {
      recipe: toNewRecipeWithRefs(recipe),
    })) as ApiRequestSagaReturnType<typeof apiPostRecipeSaga>;
  } else {
    response = (yield call(apiPutRecipeSaga, {
      recipeId,
      recipe: toExistingRecipeWithRefs(recipe),
    })) as ApiRequestSagaReturnType<typeof apiPutRecipeSaga>;
  }

  if (!response.ok) {
    yield put(recipeSaveFailed(response.details.message));
    if (!isAutosaving) {
      yield put(errorOccurred(response.details.message));
    }
    return;
  }
  if (isCreate) {
    yield put(
      mediaUploadRequested({
        resourceType: MediaResourceType.Recipes,
        resourceId: response.data.id,
        imageType: MediaImageType.Hero,
      })
    );
    yield take([mediaUploadFailed, mediaUploadFinished]);
    yield put(push(generateRecipeRoute({ id: response.data.id })));
  }
  yield put(recipeSaveFinished({ success: true }));
}

export function* publishRecipe({ payload: recipeId }: PayloadAction<string>) {
  const hasUnsavedChanges = (yield select(
    selectRecipeHasUnsavedChanges
  )) as boolean;
  if (hasUnsavedChanges) {
    yield put(recipeSaveRequested(recipeId));
    const { saveFinished, saveApiError } = (yield race({
      saveFinished: take(recipeSaveFinished),
      saveApiError: take(recipeSaveFailed),
    })) as {
      saveFinished: ReturnType<typeof recipeSaveFinished> | undefined;
      saveApiError: ReturnType<typeof recipeSaveFailed> | undefined;
    };
    if (saveApiError || !saveFinished?.payload.success) {
      return;
    }
  } else {
    yield put(recipeSubmitted());
    const hasErrors = (yield select(selectRecipeHasErrors)) as boolean;
    if (hasErrors) {
      return;
    }
  }

  const hasUnusedIngredients = (yield select(
    selectRecipeHasUnusedIngredients
  )) as boolean;
  if (hasUnusedIngredients) {
    yield put(recipePublishConfirmationRequired());
    const { publishCanceled } = (yield race({
      publishConfirmed: take(recipePublishConfirmed),
      publishCanceled: take(recipePublishCanceled),
    })) as {
      publishConfirmed: ReturnType<typeof recipePublishConfirmed> | undefined;
      publishCanceled: ReturnType<typeof recipePublishCanceled> | undefined;
    };
    if (publishCanceled) {
      return;
    }
  }

  yield put(recipePublishing());

  const response = (yield call(apiPublishRecipeSaga, {
    recipeId,
  })) as ApiRequestSagaReturnType<typeof apiPublishRecipeSaga>;

  if (!response.ok) {
    yield put(recipePublishFailed(response.details.message));
    yield put(errorOccurred(response.details.message));
    return;
  }
  yield put(recipePublishSucceed());
}

export function* unpublishRecipe({ payload: recipeId }: PayloadAction<string>) {
  yield put(recipeUnpublishing());

  const response = (yield call(apiUnpublishRecipeSaga, {
    recipeId,
  })) as ApiRequestSagaReturnType<typeof apiUnpublishRecipeSaga>;

  if (!response.ok) {
    yield put(recipeUnpublishFailed(response.details.message));
    return;
  }
  yield put(recipeUnpublishSucceed());
}

export function* fetchRecipe({ payload: recipeId }: PayloadAction<string>) {
  const response = (yield call(apiFetchRecipeSaga, {
    recipeId,
  })) as ApiRequestSagaReturnType<typeof apiFetchRecipeSaga>;
  if (!response.ok) {
    yield put(recipeFetchFailed(response.details.message));
    yield put(errorOccurred(response.details.message));
    return;
  }

  const applianceTags = (yield call(
    getApplianceTags,
    response.data.locale
  )) as TagsById;
  yield put(recipeFetchSucceed(fromApiRcpRecipe(response.data, applianceTags)));
}

export function* getApplianceTags(locale: ApiLocale) {
  // We need the appliances tags in order to split tags between appliances and general
  // so we put the fetch action and if they are already in the state, they won't be fetched again
  const shouldFetchTags = (yield select(
    selectShouldFetchTags(locale)
  )) as boolean;
  if (shouldFetchTags) {
    yield put(tagsFetchRequested({ locale }));
    yield take([tagsFetchSucceed, tagsFetchFailed]);
  }

  const isFetchingTags = (yield select(selectTagsFetching(locale))) as boolean;
  if (isFetchingTags) {
    yield take([tagsFetchSucceed, tagsFetchFailed]);
  }

  const applianceTags = (yield select(
    selectApplianceTags(locale)
  )) as ReturnType<ReturnType<typeof selectApplianceTags>>;
  return applianceTags;
}

export function* getRecipeFromUrl({
  payload: { url, locale },
}: PayloadAction<RecipeGetFromUrlPayload>) {
  const response = (yield call(apiGetRecipeFromUrlSaga, {
    url,
  })) as ApiRequestSagaReturnType<typeof apiGetRecipeFromUrlSaga>;
  if (!response.ok) {
    yield put(recipeFetchFailed(response.details.message));
    yield put(errorOccurred(response.details.message));
    return;
  }

  const applianceTags = (yield call(getApplianceTags, locale)) as TagsById;
  yield put(
    recipeFetchSucceed(
      fromApiRusRecipe(response.data, { applianceTags, locale })
    )
  );
  yield put(push(generateRecipeRoute({ tab: RecipeTabName.Information })));
}

export function* getRecipeFromText({
  payload,
}: PayloadAction<ApiGetRecipeFromTextRequest>) {
  const response = (yield call(
    apiGetRecipeFromTextSaga,
    payload
  )) as ApiRequestSagaReturnType<typeof apiGetRecipeFromTextSaga>;
  if (!response.ok) {
    yield put(recipeFetchFailed(response.details.message));
    yield put(errorOccurred(response.details.message));
    return;
  }

  const applianceTags = (yield call(
    getApplianceTags,
    payload.locale
  )) as TagsById;
  yield put(
    recipeFetchSucceed(
      fromApiRusRecipe(response.data, { applianceTags, locale: payload.locale })
    )
  );
  yield put(push(generateRecipeRoute({ tab: RecipeTabName.Information })));
}

function* recipeSaveWatcher() {
  yield takeLatest(recipeSaveRequested, saveRecipe);
}

function* recipeSaveBeforeLeavingRequestedWatcher() {
  yield takeLatest(recipeSaveBeforeLeavingRequested, saveRecipe);
}

function* recipePublishWatcher() {
  yield takeLatest(recipePublishRequested, publishRecipe);
}

function* recipeUnpublishWatcher() {
  yield takeLatest(recipeUnpublishRequested, unpublishRecipe);
}

function* recipeFetchWatcher() {
  yield takeLatest(recipeFetchRequested, fetchRecipe);
}

function* recipeGetFromUrlWatcher() {
  yield takeLatest(recipeGetFromUrlRequested, getRecipeFromUrl);
}

function* recipeGetFromTextWatcher() {
  yield takeLatest(recipeGetFromTextRequested, getRecipeFromText);
}

export function* autosaveRecipe(action: Action<string>) {
  /** Let the incoming action cancel the previous saga but do nothing else */
  if (recipeActionsThat.cancelAutosaving.includes(action.type)) {
    return;
  }

  yield put(recipeHasChanged(true));

  const { id, state } = (yield select(selectRecipe)) as ReturnType<
    typeof selectRecipe
  >;
  const isCreating = !id;
  const isPublished = state === ApiRcpRecipeState.Published;
  if (isCreating || isPublished) {
    return;
  }

  yield delay(recipePageConstants.autosave.delay);
  yield call(saveRecipe, recipeAutoSaveRequested(id));
}

export const shouldAutosave = (action: Action<string>): boolean =>
  recipeActionsThat.triggerAutosaving.includes(action.type) ||
  recipeActionsThat.cancelAutosaving.includes(action.type);

function* recipeAutosaveWatcher() {
  yield takeLatest(shouldAutosave, autosaveRecipe);
}

export const recipeRestartableSagas = [
  recipeFetchWatcher,
  recipeSaveWatcher,
  recipePublishWatcher,
  recipeUnpublishWatcher,
  recipeGetFromUrlWatcher,
  recipeGetFromTextWatcher,
  recipeAutosaveWatcher,
  recipeSaveBeforeLeavingRequestedWatcher,
];
