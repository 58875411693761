import { number } from 'yup';

import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import {
  capabilityFieldParams,
  capabilityFieldStrings,
} from 'components/CapabilityField/CapabilityField.constants';
import type {
  CapabilitySettingsValidator,
  CapabilitySettingsErrors,
} from 'components/CapabilityField/CapabilityField.types';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type { AppCapabilitySetting } from 'types/appCapabilitySetting';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import type { AppCapabilitySettingValueNumeric } from 'types/appCapabilitySettingValueNumeric';
import { isTimeSettingId } from 'types/appCapabilitySettings.utils';
import {
  fromAppTimeToSeconds,
  fromAppTimeToSentence,
  fromSecondsToAppTime,
} from 'utils/convertTimes';
import { Validator } from 'utils/validator';

export const generateNumericSettingValidatorId = (
  settingId: ApiEntityId,
  unitId: ApiRefId
): string => `${settingId}-${unitId}`;

export const generateNumericSettingValidatorMessages = (
  settingId: ApiEntityId,
  unit: string,
  min = 0,
  max = 0,
  step = 0
) => {
  const {
    minPlaceholder,
    maxPlaceholder,
    stepPlaceholder,
    unitAbbreviationPlaceholder,
  } = capabilityFieldParams;

  if (isTimeSettingId(settingId)) {
    const errorMessages = capabilityFieldStrings.errors.timeSetting;
    return {
      min: errorMessages.min.replace(
        minPlaceholder,
        fromAppTimeToSentence(fromSecondsToAppTime(min, { removeZeros: false }))
      ),
      max: errorMessages.max.replace(
        maxPlaceholder,
        fromAppTimeToSentence(fromSecondsToAppTime(max, { removeZeros: false }))
      ),
      minAndMax: errorMessages.minAndMax
        .replace(
          minPlaceholder,
          fromAppTimeToSentence(
            fromSecondsToAppTime(min, { removeZeros: false })
          )
        )
        .replace(
          maxPlaceholder,
          fromAppTimeToSentence(
            fromSecondsToAppTime(max, { removeZeros: false })
          )
        ),
      step: errorMessages.step.replace(
        stepPlaceholder,
        fromAppTimeToSentence(
          fromSecondsToAppTime(step, { removeZeros: false })
        )
      ),
      stepWithMin: errorMessages.stepWithMin
        .replace(
          minPlaceholder,
          fromAppTimeToSentence(
            fromSecondsToAppTime(min, { removeZeros: false })
          )
        )
        .replace(
          stepPlaceholder,
          fromAppTimeToSentence(
            fromSecondsToAppTime(step, { removeZeros: false })
          )
        ),
    };
  }

  const errorMessages = capabilityFieldStrings.errors.numericSetting;
  return {
    min: errorMessages.min
      .replace(minPlaceholder, `${min}`)
      .replaceAll(unitAbbreviationPlaceholder, `${unit}`),
    max: errorMessages.max
      .replace(maxPlaceholder, `${max}`)
      .replaceAll(unitAbbreviationPlaceholder, `${unit}`),
    minAndMax: errorMessages.minAndMax
      .replace(minPlaceholder, `${min}`)
      .replace(maxPlaceholder, `${max}`)
      .replaceAll(unitAbbreviationPlaceholder, `${unit}`),
    step:
      step === 1
        ? errorMessages.stepOf1
        : errorMessages.step
            .replace(stepPlaceholder, `${step}`)
            .replaceAll(unitAbbreviationPlaceholder, `${unit}`),
    stepWithMin: errorMessages.stepWithMin
      .replace(minPlaceholder, `${min}`)
      .replaceAll(unitAbbreviationPlaceholder, `${unit}`)
      .replace(stepPlaceholder, `${step}`),
  };
};

export const generateNumericValidator = (
  settingId: ApiEntityId,
  unit: string,
  min?: number,
  max?: number,
  step?: number
): Validator => {
  const errorMessages = generateNumericSettingValidatorMessages(
    settingId,
    unit,
    min,
    max,
    step
  );
  let validator = number();
  if (step !== undefined) {
    validator = validator.test(
      'step',
      min ? errorMessages.stepWithMin : errorMessages.step,
      (value) => {
        if (value === undefined) {
          return true;
        }
        if (min) {
          return Number.isInteger((value - min) / step);
        }
        return Number.isInteger(value / step);
      }
    );
  }
  const maxAndMinMessage =
    min !== undefined && max !== undefined && errorMessages.minAndMax;
  if (min !== undefined) {
    validator = validator.min(min, maxAndMinMessage || errorMessages.min);
  }
  if (max !== undefined) {
    validator = validator.max(max, maxAndMinMessage || errorMessages.min);
  }
  return new Validator({ numeric: validator });
};

export const generateCapabilitySettingsValidator = (
  settings: AppCapabilityAllowedSetting[]
): CapabilitySettingsValidator => {
  return settings.reduce((validators, setting) => {
    if (setting.allowedValues.numeric) {
      return {
        ...validators,
        ...setting.allowedValues.numeric.reduce(
          (
            settingValidators: CapabilitySettingsValidator,
            { unit, min, max, step }
          ) => {
            const validatorId = generateNumericSettingValidatorId(
              setting.id,
              unit.id
            );
            const validator = generateNumericValidator(
              setting.id,
              unit.abbreviation || unit.name,
              min,
              max,
              step
            );
            if (settingValidators[validatorId]) {
              settingValidators[validatorId].push(validator);
            } else {
              settingValidators[validatorId] = [validator];
            }
            return settingValidators;
          },
          {}
        ),
      };
    }
    return validators;
  }, {} as CapabilitySettingsValidator);
};

export const validateCapabilitySettings = (
  validator: CapabilitySettingsValidator,
  settings: AppCapabilitySetting[]
): CapabilitySettingsErrors | undefined => {
  if (!settings.length) {
    return undefined;
  }
  const result = settings.reduce((errors, setting) => {
    if (
      setting.value.type === AppCapabilitySettingType.Numeric ||
      setting.value.type === AppCapabilitySettingType.Time
    ) {
      const validatorId = generateNumericSettingValidatorId(
        setting.id,
        setting.value.referenceUnit.id
      );
      if (!validator[validatorId]) {
        return errors;
      }

      const settingErrors = validator[validatorId].map((item) => {
        return item.validate({
          numeric:
            setting.value.type === AppCapabilitySettingType.Time
              ? fromAppTimeToSeconds(setting.value.value)
              : (setting.value as AppCapabilitySettingValueNumeric).value,
        });
      });

      // If settingErrors includes any undefined, it's because the value is valid for one of the allowed values
      return settingErrors && !settingErrors.includes(undefined)
        ? { ...errors, [setting.id]: settingErrors }
        : errors;
    }
    return errors;
  }, {} as CapabilitySettingsErrors);
  return Object.values(result).length ? result : undefined;
};
