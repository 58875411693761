import type { Reducer } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import type { RouterState } from 'redux-first-history';

import { tabsListReducer } from 'components/Tab/tabsListSlice';
import { appliancesReducer } from 'features/appliances/appliancesSlice';
import { authReducer } from 'features/auth/authSlice';
import { configsReducer } from 'features/configs/configsSlice';
import { errorReducer } from 'features/error/errorSlice';
import { mediaReducer } from 'features/media/mediaSlice';
import { recipeIngredientFormReducer } from 'features/recipe/ingredients/form/recipeIngredientFormSlice';
import { recipeReducer } from 'features/recipe/recipeSlice';
import { recipeStepsFormReducer } from 'features/recipe/steps/form/recipeStepsFormSlice';
import { recipesReducer } from 'features/recipes/recipesSlice';
import { referenceDataReducer } from 'features/referenceData/referenceDataReducer';
import { recipeTranslationReducer } from 'features/translation/recipeTranslationSlice';

export const createRootReducer = (routerReducer: Reducer<RouterState>) =>
  combineReducers({
    auth: authReducer,
    media: mediaReducer,
    recipe: recipeReducer,
    recipes: recipesReducer,
    referenceData: referenceDataReducer,
    router: routerReducer,
    appliances: appliancesReducer,
    error: errorReducer,
    recipeIngredientForm: recipeIngredientFormReducer,
    recipeStepsForm: recipeStepsFormReducer,
    tabsList: tabsListReducer,
    recipeTranslation: recipeTranslationReducer,
    configs: configsReducer,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
