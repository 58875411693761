import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { TabContext, TabPanel } from '@mui/lab';
import type { TabProps } from '@mui/material';
import { Box, Tab, Tabs } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { RecipeType } from 'app/routes/constants';
import { generateRecipeListRoute } from 'app/routes/routesUtils';
import { useAppDispatch } from 'app/store/hooks';
import { ToggleableFeature } from 'components/ToggleableFeature/ToggleableFeature';
import { ListLayout } from 'features/layout/ListLayout';
import { recipesPageStrings } from 'features/recipes/RecipesPage.constants';
import { AddRecipe } from 'features/recipes/addRecipe/AddRecipe';
import { RecipesList } from 'features/recipes/list/RecipesList';
import { recipeTypeChanged } from 'features/recipes/recipesSlice';
import { AppFeature } from 'types/appFeature';

const { titles, tabs } = recipesPageStrings;

export interface RecipesPageProps {
  recipeType: RecipeType;
}

export const RecipesPage: FC<RecipesPageProps> = memo(function RecipesPage({
  recipeType,
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(recipeTypeChanged(recipeType));
  }, [dispatch, recipeType]);

  const handleTabClick = (type: RecipeType) =>
    navigate(generateRecipeListRoute({ type }));

  return (
    <ListLayout
      title={titles.main}
      actionComponent={
        <Box sx={{ display: 'flex', gap: 4 }}>
          <AddRecipe />
        </Box>
      }
    >
      <ToggleableFeature
        requires={AppFeature.TranslationManagement}
        components={{
          whenDisabled: <RecipesList />,
        }}
      >
        <>
          <RecipesListTabs value={recipeType}>
            <RecipesListTab
              label={tabs[RecipeType.Core]}
              value={RecipeType.Core}
              onClick={handleTabClick}
            />
            <RecipesListTab
              label={tabs[RecipeType.Translation]}
              value={RecipeType.Translation}
              onClick={handleTabClick}
            />
          </RecipesListTabs>
          <TabContext value={recipeType}>
            <RecipesListTabPanel value={RecipeType.Core}>
              <RecipesList />
            </RecipesListTabPanel>
            <RecipesListTabPanel value={RecipeType.Translation}>
              <RecipesList />
            </RecipesListTabPanel>
          </TabContext>
        </>
      </ToggleableFeature>
    </ListLayout>
  );
});

interface RecipesListTabsProps {
  children: ReactNode[];
  value: RecipeType;
}

const RecipesListTabs: FC<RecipesListTabsProps> = memo(
  function RecipesListTabs({ value, children }) {
    return (
      <Tabs
        variant="standard"
        value={value}
        sx={{
          borderBottom: '1px solid',
          borderColor: PantryColor.BorderDefault,
        }}
      >
        {children}
      </Tabs>
    );
  }
);

interface RecipesListTabProps extends Omit<TabProps, 'value' | 'onClick'> {
  value: RecipeType;
  onClick: (type: RecipeType) => void;
}

const RecipesListTab: FC<RecipesListTabProps> = memo(function RecipesListTab({
  label,
  value,
  onClick,
  ...props
}) {
  return (
    <Tab
      {...props}
      sx={{
        py: 3,
        px: 12,
        typography: PantryTypography.Subtitle2,
        color: PantryColor.TextSubtle,
        textTransform: 'capitalize',
        width: '250px',
        '&.Mui-selected': {
          color: PantryColor.TextDefault,
        },
      }}
      label={label}
      value={value}
      onClick={() => onClick(value)}
    />
  );
});

interface RecipesListTabPanelProps {
  children: ReactNode;
  value: RecipeType;
}

const RecipesListTabPanel: FC<RecipesListTabPanelProps> = memo(
  function RecipesListTabPanel({ children, value }) {
    return (
      <TabPanel value={value} sx={{ py: 8 }}>
        {children}
      </TabPanel>
    );
  }
);
