import * as duration from 'duration-fns';

import type { ApiDurationISO8601 } from 'api/types/common/apiDurationISO8601';
import type { AppTime } from 'types/appTime';

const toAppTime = (
  time: duration.Duration,
  { removeZeros = false }: { removeZeros?: boolean }
): AppTime => {
  const { years, months, weeks, days, hours, minutes, seconds } = time;
  const totalHours = duration.toHours(
    duration.sum({ years }, { months }, { weeks }, { days }, { hours })
  );
  return {
    hours: totalHours === 0 && removeZeros ? undefined : totalHours,
    minutes: minutes === 0 && removeZeros ? undefined : minutes,
    seconds: seconds === 0 && removeZeros ? undefined : seconds,
  };
};

const removeEmptyValues = (time: AppTime): AppTime => {
  const { hours, minutes, seconds } = time;
  return {
    ...(!!hours && { hours }),
    ...(!!minutes && { minutes }),
    ...(!!seconds && { seconds }),
  };
};

/**
 * Covert time from AppTime to ISO 8601
 */
export const fromAppTimeToISO = (time: AppTime): ApiDurationISO8601 => {
  return duration.toString(duration.normalize(removeEmptyValues(time)));
};

/**
 * Convert time from ISO 8601 to AppTime
 */
export const fromISOToAppTime = (
  isoTime: ApiDurationISO8601,
  { removeZeros = false }: { removeZeros?: boolean }
): AppTime => {
  return toAppTime(duration.normalize(duration.parse(isoTime)), {
    removeZeros,
  });
};

/**
 * Convert time from AppTime to seconds
 */
export const fromAppTimeToSeconds = (time: AppTime): number => {
  return duration.toSeconds(duration.sum(removeEmptyValues(time)));
};

/**
 * Convert time from seconds to AppTime
 */
export const fromSecondsToAppTime = (
  time: number,
  { removeZeros = false }: { removeZeros?: boolean }
): AppTime => {
  return toAppTime(duration.normalize({ seconds: time }), { removeZeros });
};

/**
 * Convert time from AppTime to a sentence
 */
export const fromAppTimeToSentence = (
  time: AppTime,
  abbreviated = false
): string => {
  const { hours, minutes, seconds } = time;

  const hoursText = {
    plural: abbreviated ? 'h' : 'hours',
    singular: abbreviated ? 'h' : 'hour',
  };
  const minutesText = {
    plural: abbreviated ? 'min' : 'minutes',
    singular: abbreviated ? 'min' : 'minute',
  };
  const secondsText = {
    plural: abbreviated ? 'sec' : 'seconds',
    singular: abbreviated ? 'sec' : 'second',
  };

  let sentence = '';
  if (hours) {
    sentence = `${hours} ${hours > 1 ? hoursText.plural : hoursText.singular}`;
  }
  if (minutes) {
    sentence = `${sentence} ${minutes} ${
      minutes > 1 ? minutesText.plural : minutesText.singular
    }`;
  }
  if (seconds) {
    sentence = `${sentence} ${seconds} ${
      seconds > 1 ? secondsText.plural : secondsText.singular
    }`;
  }
  return sentence.trim();
};

/**
 * Convert time from seconds to sentence
 */
export const fromSecondsToSentence = (
  time: number,
  abbreviated = true
): string =>
  fromAppTimeToSentence(
    fromSecondsToAppTime(time, { removeZeros: false }),
    abbreviated
  );

/**
 * Transforms a time unit to a string, adding leading 0s
 * @param time a number representing a time unit (hours, minutes or seconds)
 * @param pad number of characters that the resulting string must have, that will be filled with 0s
 * @returns a string representing the number, with leading 0s
 *
 * @example timeUnitToString(8, 2) = '08'
 * @example timeUnitToString(undefined, 2) = ''
 */
export const timeUnitToString = (time: number | undefined, pad = 0): string =>
  time?.toString().padStart(pad, '0') ?? '';

export const noTime: AppTime = {
  hours: undefined,
  minutes: undefined,
  seconds: undefined,
};
