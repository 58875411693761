import {
  InformationCircleIcon,
  PantryColor,
  PantryTypography,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import type { AppLink } from 'types/appLink';

export enum RecipeReviewEmptyValueType {
  Warning = 'warning',
  Critical = 'critical',
}

export interface RecipeReviewEmptyValueProps {
  emptyLink?: AppLink;
  message: string;
  type?: RecipeReviewEmptyValueType;
  sx?: SxProps;
}

export const RecipeReviewEmptyValue: FC<RecipeReviewEmptyValueProps> = memo(
  function RecipeReviewEmptyValue({
    emptyLink,
    message,
    type = RecipeReviewEmptyValueType.Warning,
    sx,
  }) {
    const colors = (() => {
      if (type === RecipeReviewEmptyValueType.Warning) {
        return {
          message: PantryColor.ActionWarning,
          icon: PantryColor.IconWarning,
        };
      }
      return {
        message: PantryColor.ActionCritical,
        icon: PantryColor.IconCritical,
      };
    })();

    return (
      <>
        <Typography
          variant={PantryTypography.Body2}
          sx={sxCompose(
            {
              display: 'flex',
              alignItems: 'center',
              color: colors.message,
            },
            sx
          )}
        >
          <InformationCircleIcon size={16} sx={{ mr: 1 }} color={colors.icon} />
          {message}
        </Typography>
        {!!emptyLink && (
          <Link to={emptyLink.to} style={{ textDecorationLine: 'none' }}>
            <Typography
              variant={PantryTypography.Caption}
              sx={{
                color: PantryColor.FrescoPrimary,
              }}
            >
              {emptyLink.text}
            </Typography>
          </Link>
        )}
      </>
    );
  }
);
