import {
  Button,
  ButtonSize,
  ButtonStyle,
  DeleteIcon,
} from '@dropkitchen/pantry-react';
import type { FC } from 'react';
import { memo } from 'react';

export interface CardDeleteButtonProps {
  label: string;
  dragging?: boolean;
  onDelete: () => void;
}

export const CardDeleteButton: FC<CardDeleteButtonProps> = memo(
  function CardDeleteButton({ label, dragging, onDelete }) {
    return (
      /**
       * Workaround to position the delete button on the card's header.
       * MUI's CardActionArea generates an HTML button that wraps the entire card. We can't use CardActionArea and
       * CardHeader's action property together as that'll generate a button inside a button.
       */
      <Button
        sx={(theme) => ({
          position: 'absolute',
          top: dragging ? theme.spacing(8) : theme.spacing(4),
          right: theme.spacing(2),
          ...(dragging && { transform: 'rotate(3deg)' }),
        })}
        label={label}
        hideLabel
        leadingIcon={DeleteIcon}
        buttonStyle={ButtonStyle.Subtle}
        size={ButtonSize.Medium}
        onClick={(event) => {
          event.preventDefault();
          onDelete();
        }}
      />
    );
  }
);
