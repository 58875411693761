import { useNavigationPrompt } from 'hooks/router/useNavigationPrompt';
import { useBeforeUnload } from 'hooks/useBeforeUnload';

export interface UseLeavePageWithUnsavedChangesParams {
  isEnabled: boolean;
  /**
   * Array of paths that should not block navigation, regardless the when parameter value
   */
  excludedPaths?: string[];
  onBeforeUnload: () => void;
}

export function useLeavePageWithUnsavedChanges({
  isEnabled,
  excludedPaths,
  onBeforeUnload,
}: UseLeavePageWithUnsavedChangesParams) {
  useBeforeUnload({ isEnabled, onBeforeUnload });

  return useNavigationPrompt({
    isEnabled,
    excludedPaths,
  });
}
