import type { IconProps } from '@dropkitchen/pantry-react';
import {
  PantryTypography,
  InformationCircleIcon,
  PantryColor,
  WarningIcon,
  sxCompose,
} from '@dropkitchen/pantry-react';
import type { SxProps } from '@mui/material';
import { Typography } from '@mui/material';
import { memo } from 'react';
import type { FC } from 'react';

export enum HelperTextSeverity {
  Critical = 'critical',
  Neutral = 'neutral',
  Information = 'information',
}

export interface HelperTextProps {
  message: string;
  severity: HelperTextSeverity;
  sx?: SxProps;
}

export const HelperText: FC<HelperTextProps> = memo(function HelperText({
  message,
  sx,
  severity: variant,
}) {
  const iconProps: IconProps = {
    sx: { mr: 1 },
    size: 16,
  };
  const color = (() => {
    if (variant === HelperTextSeverity.Critical) {
      return PantryColor.TextCritical;
    }
    if (variant === HelperTextSeverity.Neutral) {
      return PantryColor.TextDefault;
    }
    if (variant === HelperTextSeverity.Information) {
      return PantryColor.TextAccent;
    }
  })();

  return (
    <Typography
      variant={PantryTypography.CaptionSemiBold}
      sx={sxCompose(sx, { display: 'flex', alignItems: 'center', color })}
    >
      {variant === HelperTextSeverity.Critical && (
        <WarningIcon {...iconProps} color={PantryColor.IconCritical} />
      )}
      {variant === HelperTextSeverity.Neutral && (
        <InformationCircleIcon {...iconProps} color={PantryColor.IconDefault} />
      )}
      {variant === HelperTextSeverity.Information && (
        <InformationCircleIcon {...iconProps} color={PantryColor.IconAccent} />
      )}
      {message}
    </Typography>
  );
});
