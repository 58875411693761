import { generatePath } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';

import { createApiFn, fetchJson } from 'api/fetchJson';
import {
  apiCreateRecipeUrl,
  apiGetRecipeUrl,
  apiPublishRecipeUrl,
  apiRecipeFromUrl,
  apiRecipeFromTextUrl,
  apiGetRecipesUrl,
  apiPathParams,
  apiUpdateRecipeUrl,
  apiUnpublishRecipeUrl,
  apiDeleteRecipeUrl,
  apiForkRecipeUrl,
} from 'api/paths';
import type { ApiRequestFn } from 'api/types';
import { CustomHeader, HttpMethod } from 'api/types';
import type { ApiLocale } from 'api/types/common/apiLocale';
import type { ApiRcpRecipe } from 'api/types/recipe/apiRcpRecipe';
import type { ApiRcpRecipeId } from 'api/types/recipe/apiRcpRecipeId';
import type { ApiRcpRecipeState } from 'api/types/recipe/apiRcpRecipeState';
import type { ApiRcpExistingRecipeWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpExistingRecipeWithRefs';
import type { ApiRcpNewRecipeWithRefs } from 'api/types/recipe/recipeWithRefs/apiRcpNewRecipeWithRefs';
import type { ApiRusRecipe } from 'api/types/recipeUnderstanding/apiRusRecipe';
import type { ApiRefId } from 'api/types/referenceData/apiRefId';
import type { ApiSchRecipes } from 'api/types/search/apiSchRecipes';
import { appConfig } from 'config/config';

export interface ApiSearchRecipesRequest {
  from: number;
  searchTerm: string;
  size: number;
  forkedFromId?: string | null;
  locales?: ApiLocale[];
  tags?: ApiRefId[];
  state?: ApiRcpRecipeState;
}

export const apiGetRecipes: ApiRequestFn<
  ApiSchRecipes,
  ApiSearchRecipesRequest
> = async (
  signal: AbortSignal,
  { from, searchTerm, size, forkedFromId, locales, tags, state }
) => {
  const queryParams = new URLSearchParams({
    from: from.toString(),
    size: size.toString(),
  });
  return fetchJson({
    httpMethod: HttpMethod.Post,
    url: `${apiGetRecipesUrl}?${queryParams.toString()}`,
    signal,
    body: snakecaseKeys({
      query: searchTerm,
      forkedFromId,
      locales,
      tags,
      state,
    }),
  });
};

export interface ApiPostRecipeRequest {
  recipe: ApiRcpNewRecipeWithRefs;
}
export const postRecipe = async ({ recipe }: ApiPostRecipeRequest) =>
  fetchJson<ApiRcpRecipe>({
    httpMethod: HttpMethod.Post,
    url: apiCreateRecipeUrl,
    body: snakecaseKeys(recipe),
  });
export const apiPostRecipe: ApiRequestFn<ApiRcpRecipe, ApiPostRecipeRequest> =
  createApiFn(postRecipe);

export interface ApiPutRecipeRequest {
  recipe: ApiRcpExistingRecipeWithRefs;
  recipeId: ApiRcpRecipeId;
}
export const putRecipe = async ({ recipe, recipeId }: ApiPutRecipeRequest) =>
  fetchJson<ApiRcpRecipe>({
    httpMethod: HttpMethod.Put,
    url: generatePath(apiUpdateRecipeUrl, {
      [apiPathParams.recipeId]: recipeId,
    }),
    body: snakecaseKeys(recipe),
  });
export const apiPutRecipe: ApiRequestFn<ApiRcpRecipe, ApiPutRecipeRequest> =
  createApiFn(putRecipe);

export interface ApiGetRecipeRequest {
  recipeId: ApiRcpRecipeId;
}
export const getRecipe = async ({ recipeId }: ApiGetRecipeRequest) =>
  fetchJson<ApiRcpRecipe>({
    httpMethod: HttpMethod.Get,
    url: generatePath(apiGetRecipeUrl, { [apiPathParams.recipeId]: recipeId }),
  });
export const apiGetRecipe: ApiRequestFn<ApiRcpRecipe, ApiGetRecipeRequest> =
  createApiFn(getRecipe);

export interface ApiPublishRecipeRequest {
  recipeId: ApiRcpRecipeId;
}
export const publishRecipe = async ({ recipeId }: ApiPublishRecipeRequest) =>
  fetchJson<void>({
    httpMethod: HttpMethod.Post,
    url: generatePath(apiPublishRecipeUrl, {
      [apiPathParams.recipeId]: recipeId,
    }),
    body: {},
  });
export const apiPublishRecipe: ApiRequestFn<void, ApiPublishRecipeRequest> =
  createApiFn(publishRecipe);

export interface ApiUnpublishRecipeRequest {
  recipeId: ApiRcpRecipeId;
}
export const unpublishRecipe = async ({
  recipeId,
}: ApiUnpublishRecipeRequest) =>
  fetchJson<void>({
    httpMethod: HttpMethod.Post,
    url: generatePath(apiUnpublishRecipeUrl, {
      [apiPathParams.recipeId]: recipeId,
    }),
    body: {},
  });
export const apiUnpublishRecipe: ApiRequestFn<void, ApiUnpublishRecipeRequest> =
  createApiFn(unpublishRecipe);

export interface ApiGetRecipeFromUrlRequest {
  url: string;
}
export const getRecipeFromUrl = async ({ url }: ApiGetRecipeFromUrlRequest) =>
  fetchJson<ApiRusRecipe>({
    httpMethod: HttpMethod.Post,
    url: apiRecipeFromUrl,
    body: { url },
    headers: {
      [CustomHeader.OrganizationId]: appConfig.organizationId(),
    },
  });
export const apiGetRecipeFromUrl: ApiRequestFn<
  ApiRusRecipe,
  ApiGetRecipeFromUrlRequest
> = createApiFn(getRecipeFromUrl);

export interface ApiGetRecipeFromTextRequest {
  locale: ApiLocale;
  name: string;
  description: string;
  ingredients: string[];
  steps: string[];
}
export const getRecipeFromText = async (data: ApiGetRecipeFromTextRequest) =>
  fetchJson<ApiRusRecipe>({
    httpMethod: HttpMethod.Post,
    url: apiRecipeFromTextUrl,
    body: data,
    headers: {
      [CustomHeader.OrganizationId]: appConfig.organizationId(),
    },
  });
export const apiGetRecipeFromText: ApiRequestFn<
  ApiRusRecipe,
  ApiGetRecipeFromTextRequest
> = createApiFn(getRecipeFromText);

export const deleteRecipe = async (recipeId: ApiRcpRecipeId) =>
  fetchJson<void>({
    httpMethod: HttpMethod.Delete,
    url: generatePath(apiDeleteRecipeUrl, {
      [apiPathParams.recipeId]: recipeId,
    }),
  });
export const apiDeleteRecipe: ApiRequestFn<void, string> =
  createApiFn(deleteRecipe);

export interface ApiForkRecipeRequest {
  recipeId: ApiRcpRecipeId;
  locale: ApiLocale;
}
export const forkRecipe = async ({
  recipeId,
  locale,
}: ApiForkRecipeRequest) => {
  const queryParams = new URLSearchParams({ locale });
  return fetchJson<ApiRcpRecipe>({
    httpMethod: HttpMethod.Post,
    url: `${generatePath(apiForkRecipeUrl, {
      [apiPathParams.recipeId]: recipeId,
    })}?${queryParams.toString()}`,
  });
};
export const apiForkRecipe: ApiRequestFn<ApiRcpRecipe, ApiForkRecipeRequest> =
  createApiFn(forkRecipe);
