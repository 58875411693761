export const recipeReviewStrings = {
  placeholders: {
    noValue: '--',
  },
  sections: {
    basicInformation: 'Basic info',
    ingredients: 'Ingredients',
    steps: 'Steps',
  },
  errors: {
    image: 'No image added',
  },
};
