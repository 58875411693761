import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  TextField,
  Box,
  InputLabel,
  Switch,
  Grid,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { useCallback, useEffect, memo, useState, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { capabilityFieldStrings } from 'components/CapabilityField/CapabilityField.constants';
import type { CapabilitySettingsError } from 'components/CapabilityField/CapabilityField.types';
import { capabilitySettingFieldConstants } from 'components/CapabilityField/CapabilitySettingField.constants';
import { areDependenciesMet } from 'components/CapabilityField/CapabilitySettingFieldDependencies';
import { CapabilitySettingFieldIcon } from 'components/CapabilityField/CapabilitySettingFieldIcon';
import { QuantityField } from 'components/QuantityField/QuantityField';
import { TimeField } from 'components/timeField/TimeField';
import {
  selectSettingById,
  selectErrorBySettingId,
  selectSubmitted,
  stepSettingUpdated,
  selectSettings,
  selectSettingsDependencies,
} from 'features/recipe/steps/form/recipeStepsFormSlice';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type { AppCapabilitySettingTypes } from 'types/appCapabilitySettingType';
import { AppCapabilitySettingType } from 'types/appCapabilitySettingType';
import type { AppCapabilitySettingValue } from 'types/appCapabilitySettingValue';
import type { AppCapabilitySettingValueNominal } from 'types/appCapabilitySettingValueNominal';
import { isTimeSettingId } from 'types/appCapabilitySettings.utils';
import type { AppTime } from 'types/appTime';
import type { AppRecipeIngredientQuantity } from 'types/recipe/appRecipeIngredientQuantity';
import { hasTime } from 'utils/validateTimes';
import type { ValidatorError } from 'utils/validator';

export interface CapabilitySettingFieldProps {
  setting: AppCapabilityAllowedSetting;
}

const { labels } = capabilityFieldStrings;

/**
 * @todo Remove this workaround to generate the setting name when we have a property with a readable English name
 * {@link https://frescocooks.atlassian.net/browse/ROUX-1533}
 */
export const getSettingName = (name: string): string => {
  const [firstChar, ...rest] = name.replace('setting', '');
  return `${firstChar.toUpperCase()}${rest.join('')}`.trim();
};

export const groupErrorsByType = (
  errors: CapabilitySettingsError | undefined
) =>
  errors?.reduce((errorsByType: ValidatorError, error) => {
    if (error.numeric) {
      Object.keys(error.numeric)?.forEach((errorType) => {
        if (errorsByType[errorType]) {
          errorsByType[errorType] += `${
            capabilitySettingFieldConstants.errorsJoinWord
          }${error.numeric?.[errorType] ?? ''}`;
        } else {
          errorsByType[errorType] = error.numeric?.[errorType] ?? '';
        }
      });
    }
    return errorsByType;
  }, {});

export const CapabilitySettingField: FC<CapabilitySettingFieldProps> = memo(
  function CapabilitySettingField({ setting }) {
    const dispatch = useAppDispatch();

    const [canSelectValueType, setCanSelectValueType] = useState<boolean>();
    const [valueType, setValueType] =
      useState<AppCapabilitySettingTypes | null>(null);
    const [value, setValue] = useState<
      | boolean
      | AppTime
      | AppRecipeIngredientQuantity
      | null
      | AppCapabilitySettingValueNominal['referenceValue']
    >(null);
    const [shouldBeRendered, setShouldBeRendered] = useState<boolean>(true);

    const selectedSetting = useAppSelector(selectSettingById(setting.id));
    const settingsDependencies = useAppSelector(selectSettingsDependencies);
    const settings = useAppSelector(selectSettings);
    const errors = useAppSelector(selectErrorBySettingId(setting.id));
    const submitted = useAppSelector(selectSubmitted);

    const groupedNumericErrors = useMemo(
      () => groupErrorsByType(errors),
      [errors]
    );

    const applyValues = (settingValue: AppCapabilitySettingValue) => {
      setValueType(settingValue.type);
      if (settingValue.type === AppCapabilitySettingType.Time) {
        setValue(settingValue.value);
        return;
      }
      if (settingValue.type === AppCapabilitySettingType.Nominal) {
        setValue(settingValue.referenceValue);
        return;
      }
      if (settingValue.type === AppCapabilitySettingType.Numeric) {
        setValue({
          amount: settingValue.value,
          unit: settingValue.referenceUnit,
        });
        return;
      }
      setValue(settingValue.value);
    };

    const handleValueChange = useCallback(
      (settingValue: AppCapabilitySettingValue | null) => {
        dispatch(
          stepSettingUpdated({
            settingId: setting.id,
            setting: settingValue
              ? {
                  id: setting.id,
                  name: setting.name,
                  value: settingValue,
                }
              : null,
          })
        );
      },
      [dispatch, setting]
    );

    useEffect(() => {
      const {
        allowedValues: { nominal, numeric, boolean },
      } = setting;
      const hasNominal = !!nominal?.length;
      const hasNumeric = !!numeric?.length;
      const hasNominalAndNumeric = hasNominal && hasNumeric;
      setCanSelectValueType(hasNominalAndNumeric);

      const hasBoolean = !!boolean?.length;
      if (hasBoolean) {
        setValue(false);
        setValueType(AppCapabilitySettingType.Boolean);
        return;
      }

      setValue(null);
      const hasOnlyNumeric = !hasNominal && hasNumeric;
      setValueType(
        hasNominalAndNumeric || hasOnlyNumeric
          ? AppCapabilitySettingType.Numeric
          : AppCapabilitySettingType.Nominal
      );
    }, [dispatch, setting]);

    useEffect(() => {
      if (selectedSetting) {
        applyValues(selectedSetting.value);
      }
    }, [selectedSetting]);

    useEffect(() => {
      const dependencies = settingsDependencies?.dependsOn[setting.id];
      setShouldBeRendered(
        !dependencies ||
          areDependenciesMet(dependencies, Object.values(settings))
      );
    }, [setting, settings, settingsDependencies]);

    if (!shouldBeRendered) {
      return null;
    }

    const fieldLabel = (
      <InputLabel
        htmlFor={`${setting.id}${isTimeSettingId(setting.id) ? '-hours' : ''}`}
      >
        <Box
          component="div"
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          <CapabilitySettingFieldIcon
            settingId={setting.id}
            sx={{ color: PantryColor.IconDefault }}
          />
          <Typography
            variant={PantryTypography.Body2}
            color={PantryColor.TextSubtle}
          >
            {getSettingName(setting.name)}
          </Typography>
        </Box>
      </InputLabel>
    );
    if (isTimeSettingId(setting.id) && setting.allowedValues.numeric?.length) {
      const { step, unit } = setting.allowedValues.numeric[0];
      const oneMinute = 60; // seconds
      return (
        <Grid item xs={12}>
          {fieldLabel}
          <TimeField
            id={setting.id}
            sx={{ mt: 1 }}
            value={value ? (value as AppTime) : undefined}
            showSeconds={
              step === undefined || !Number.isInteger(step / oneMinute)
            }
            errors={submitted ? groupedNumericErrors : undefined}
            onChange={(time) => {
              setValue(time);
              handleValueChange(
                hasTime(time)
                  ? {
                      type: AppCapabilitySettingType.Time,
                      value: time,
                      referenceUnit: unit,
                    }
                  : null
              );
            }}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        {fieldLabel}
        {canSelectValueType && (
          <FormControl>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={valueType}
              onChange={(event) => {
                setValue(null);
                setValueType(event.target.value as AppCapabilitySettingTypes);
                handleValueChange(null);
              }}
            >
              <FormControlLabel
                value={AppCapabilitySettingType.Nominal}
                control={<Radio />}
                label={labels.nominalRadioButton}
              />
              <FormControlLabel
                value={AppCapabilitySettingType.Numeric}
                control={<Radio />}
                label={labels.numericRadioButton}
              />
            </RadioGroup>
          </FormControl>
        )}
        {valueType === AppCapabilitySettingType.Nominal && (
          <Autocomplete
            id={setting.id}
            sx={{ mt: 1 }}
            isOptionEqualToValue={(option, selected) =>
              option.id === selected.id
            }
            onChange={(_e, option) => {
              setValue(option);
              handleValueChange(
                option ? { type: valueType, referenceValue: option } : null
              );
            }}
            value={
              value
                ? (value as AppCapabilitySettingValueNominal['referenceValue'])
                : null
            }
            options={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              setting.allowedValues.nominal!
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={getSettingName(setting.name)}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
          />
        )}
        {valueType === AppCapabilitySettingType.Numeric && (
          <QuantityField
            id={setting.id}
            placeholder={getSettingName(setting.name)}
            sx={{ mt: 1 }}
            quantity={value as AppRecipeIngredientQuantity}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            units={setting.allowedValues.numeric!.map(({ unit }) => unit)}
            errors={submitted ? groupedNumericErrors : undefined}
            onChange={(quantity) => {
              setValue(quantity);
              handleValueChange(
                quantity.amount
                  ? {
                      type: valueType,
                      value: quantity.amount,
                      referenceUnit: quantity.unit,
                    }
                  : null
              );
            }}
          />
        )}
        {valueType === AppCapabilitySettingType.Boolean && (
          <Switch
            id={setting.id}
            checked={value as boolean}
            onChange={(event) => {
              setValue(event.target.checked);
              handleValueChange({
                type: valueType,
                value: event.target.checked,
              });
            }}
          />
        )}
      </Grid>
    );
  }
);
