import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { Box, CardContent, Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { Card } from 'components/Card/Card';
import type { DraggableContentProps } from 'components/DragAndDrop/Draggable';
import { recipeIngredientStrings } from 'features/recipe/ingredients/list/RecipeIngredient.constants';
import { CardDeleteButton } from 'features/recipe/shared/CardDeleteButton/CardDeleteButton';
import type { AppRecipeIngredient } from 'types/recipe/appRecipeIngredient';

const { ariaLabels } = recipeIngredientStrings;

export interface RecipeIngredientProps extends DraggableContentProps {
  draggable?: boolean;
  ingredient: AppRecipeIngredient;
  onClick?: () => void;
  onDelete?: () => void;
  selected?: boolean;
}

export const RecipeIngredient: FC<RecipeIngredientProps> = memo(
  function RecipeIngredient({
    draggable = false,
    dragging,
    ingredient,
    onClick,
    onDelete,
    selected,
  }) {
    return (
      <Box sx={{ position: 'relative' }}>
        <Card
          sx={{ mb: 6 }}
          onClick={onClick}
          selected={selected}
          ariaLabel={ingredient.name}
          draggable={draggable}
          dragging={dragging}
        >
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography
                  variant={PantryTypography.Body2SemiBold}
                  color={PantryColor.TextDefault}
                >
                  {ingredient.name}
                </Typography>
                <Typography
                  variant={PantryTypography.Caption}
                  color={PantryColor.TextDefault}
                >
                  {ingredient.quantity.text}
                </Typography>
                {!!ingredient.preparations?.length && (
                  <Typography
                    variant={PantryTypography.Nav}
                    sx={{ color: PantryColor.TextSubtle }}
                  >
                    {ingredient.preparations
                      .map((preparation) => preparation.name)
                      .join(', ')}
                  </Typography>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        {onDelete && (
          <CardDeleteButton
            label={ariaLabels.deleteButton}
            dragging={dragging}
            onDelete={onDelete}
          />
        )}
      </Box>
    );
  }
);
