import {
  InformationCircleIcon,
  PantryColor,
  PantryTypography,
} from '@dropkitchen/pantry-react';
import { Typography } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import type { ApiAplCapabilityAttachment } from 'api/types/appliance/apiAplCapabilityAttachment';
import { capabilityFieldStrings } from 'components/CapabilityField/CapabilityField.constants';

const { helpers } = capabilityFieldStrings;

interface CapabilityAttachmentsProps {
  attachments: ApiAplCapabilityAttachment[];
}

export const CapabilityAttachments: FC<CapabilityAttachmentsProps> = memo(
  function CapabilityAttachments({ attachments }) {
    return (
      <>
        <Typography
          variant={PantryTypography.CaptionSemiBold}
          sx={{
            display: 'flex',
            alignItems: 'top',
          }}
        >
          <InformationCircleIcon
            size={16}
            sx={{ mr: 1 }}
            color={PantryColor.IconDefault}
          />
          {helpers.attachments.message}
        </Typography>
        <ul style={{ marginTop: '4px', marginBottom: 0 }}>
          {attachments.map(({ id, name, isOptional }) => (
            <li key={id}>
              <Typography variant={PantryTypography.CaptionSemiBold}>{`${name}${
                isOptional ? ` - ${helpers.attachments.optional}` : ''
              }`}</Typography>
            </li>
          ))}
        </ul>
      </>
    );
  }
);
