import { combineReducers } from '@reduxjs/toolkit';

import { capabilitiesReducer } from 'features/referenceData/capabilities/capabilitiesSlice';
import { ingredientsReducer } from 'features/referenceData/ingredients/ingredientsSlice';
import { preparationsReducer } from 'features/referenceData/preparations/preparationsSlice';
import { tagsReducer } from 'features/referenceData/tags/tagsSlice';

export const referenceDataReducer = combineReducers({
  ingredients: ingredientsReducer,
  capabilities: capabilitiesReducer,
  preparations: preparationsReducer,
  tags: tagsReducer,
});
