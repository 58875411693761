import type { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ApiEntityId } from 'api/types/common/apiEntityId';
import type { RootState } from 'app/store/rootReducer';
import type {
  CapabilitySettingsError,
  CapabilitySettingsErrors,
} from 'components/CapabilityField/CapabilityField.types';
import type {
  CapabilitySettingFieldDependencies,
  CapabilitySettingFieldDependents,
} from 'components/CapabilityField/CapabilitySettingFieldDependencies';
import {
  areDependenciesMet,
  generateCapabilitySettingFieldDependsOn,
} from 'components/CapabilityField/CapabilitySettingFieldDependencies';
import { authSignOutFinished } from 'features/auth/authSlice';
import {
  recalculateStepIngredientAmount,
  createQuantity,
  recipeFetchRequested,
  recipeGetFromTextRequested,
  recipeGetFromUrlRequested,
  recipeReset,
  recipeIngredientDeleted,
  recipeIngredientUpdated,
  recipeDiscardChanges,
  recipeFromScratchRequested,
} from 'features/recipe/recipeSlice';
import type { AppCapability } from 'types/appCapability';
import { AppCapabilityType } from 'types/appCapability';
import type { AppCapabilityAllowedPhase } from 'types/appCapabilityAllowedPhase';
import type { AppCapabilityAllowedSetting } from 'types/appCapabilityAllowedSetting';
import type { AppCapabilitySetting } from 'types/appCapabilitySetting';
import type { AppAppliance } from 'types/appliance/appAppliance';
import type { AppRecipeStepIngredient } from 'types/recipe/appRecipeStepIngredient';
import type { ValidatorError } from 'utils/validator';

type Settings = Record<ApiEntityId, AppCapabilitySetting>;

interface Errors {
  text?: ValidatorError;
  settings?: CapabilitySettingsErrors;
}

export interface RecipeStepsFormState {
  text: string;
  ingredients: AppRecipeStepIngredient[];
  capabilityType: AppCapabilityType;
  appliance: AppAppliance | null;
  capability: AppCapability | null;
  phase: AppCapabilityAllowedPhase | null;
  settings: Settings;
  settingsDependencies: CapabilitySettingFieldDependencies | null;
  errors: Errors;
  submitted: boolean;
  index: number | null;
  isUnsaved: boolean;
  isShowing: boolean;
}

export const initialState: RecipeStepsFormState = {
  text: '',
  ingredients: [],
  capabilityType: AppCapabilityType.General,
  appliance: null,
  capability: null,
  phase: null,
  settings: {},
  settingsDependencies: null,
  errors: {},
  submitted: false,
  index: null,
  isUnsaved: false,
  isShowing: true,
};

const resetStateActions = [
  authSignOutFinished.type,
  recipeReset.type,
  recipeFetchRequested.type,
  recipeFromScratchRequested.type,
  recipeGetFromTextRequested.type,
  recipeGetFromUrlRequested.type,
];
const shouldResetState = (action: Action<string>) =>
  resetStateActions.includes(action.type);

export const recipeStepsFormSlice = createSlice({
  name: 'recipeStepsFormSlice',
  initialState,
  reducers: {
    stepIndexUpdated(state, { payload }: PayloadAction<number | null>) {
      state.index = payload;
    },
    stepTextUpdated(state, { payload: text }: PayloadAction<string>) {
      state.text = text;
      state.isUnsaved = true;
    },
    stepIngredientsUpdated(
      state,
      {
        payload: ingredients,
      }: PayloadAction<AppRecipeStepIngredient[] | undefined>
    ) {
      state.ingredients = ingredients || [];
      state.isUnsaved = true;
    },
    stepCapabilityTypeUpdated(
      state,
      { payload: capabilityType }: PayloadAction<AppCapabilityType>
    ) {
      state.capabilityType = capabilityType;
      state.isUnsaved = true;
    },
    stepApplianceUpdated(
      state,
      { payload: appliance }: PayloadAction<AppAppliance | null>
    ) {
      state.appliance = appliance;
      state.isUnsaved = true;
    },
    stepCapabilityUpdated(
      state,
      { payload: capability }: PayloadAction<AppCapability | null>
    ) {
      state.capability = capability;
      state.phase = null;
      state.isUnsaved = true;
    },
    stepPhaseUpdated(
      state,
      { payload: phase }: PayloadAction<AppCapabilityAllowedPhase | null>
    ) {
      state.phase = phase;
      state.isUnsaved = true;
    },
    stepSettingUpdated(
      state,
      {
        payload: { settingId, setting },
      }: PayloadAction<{
        settingId: ApiEntityId;
        setting: AppCapabilitySetting | null;
      }>
    ) {
      state.isUnsaved = true;
      const clearSetting = (id: ApiEntityId) => delete state.settings[id];

      const dependents =
        state.settingsDependencies?.dependents[settingId] || [];

      if (!setting) {
        clearSetting(settingId);
        Object.values(dependents).forEach((dependent) =>
          clearSetting(dependent.setting.id)
        );
        return;
      }

      state.settings[settingId] = setting;
      Object.values(dependents).forEach((dependent) => {
        const meetsDependencies = areDependenciesMet(
          { [setting.id]: dependent.dependency },
          [setting]
        );
        if (!meetsDependencies) {
          clearSetting(dependent.setting.id);
          return;
        }
        const dependencyHasValue = !!state.settings[dependent.setting.id];
        if (dependencyHasValue) {
          return;
        }
        if (dependent.setting.defaultValue) {
          state.settings[dependent.setting.id] = {
            id: dependent.setting.id,
            name: dependent.setting.name,
            value: dependent.setting.defaultValue,
          };
        }
      });
    },
    stepSettingsUpdated(
      state,
      { payload: settings }: PayloadAction<AppCapabilitySetting[]>
    ) {
      state.isUnsaved = true;
      state.settings = settings.reduce(
        (currentSettings, setting) => ({
          ...currentSettings,
          [setting.id]: setting,
        }),
        {}
      );
    },
    stepFieldValidated<T extends keyof Errors>(
      state: RecipeStepsFormState,
      {
        payload: { field, errors },
      }: PayloadAction<{ field: T; errors: Errors[T] }>
    ) {
      if (!errors) {
        delete state.errors[field];
      } else {
        state.errors[field] = errors;
      }
    },
    stepSubmitted(state, { payload: submitted }: PayloadAction<boolean>) {
      state.submitted = submitted;
    },
    stepSettingsDependenciesUpdated(
      state,
      { payload: settings }: PayloadAction<AppCapabilityAllowedSetting[]>
    ) {
      state.isUnsaved = true;
      if (!settings.length) {
        state.settingsDependencies = null;
        return;
      }
      state.settingsDependencies =
        settings.reduce<CapabilitySettingFieldDependencies>(
          (dependencies, setting) => {
            if (!setting.dependsOnSetting) {
              return dependencies;
            }
            return {
              ...dependencies,
              dependsOn: {
                ...dependencies.dependsOn,
                [setting.id]: generateCapabilitySettingFieldDependsOn(
                  setting.dependsOnSetting
                ),
              },
              dependents: {
                ...dependencies.dependents,
                ...setting.dependsOnSetting.reduce<CapabilitySettingFieldDependents>(
                  (previous, dependency) => {
                    return {
                      ...previous,
                      [dependency.referenceSettingId]: {
                        ...dependencies.dependents[
                          dependency.referenceSettingId
                        ],
                        ...previous[dependency.referenceSettingId],
                        [setting.id]: {
                          setting,
                          dependency: dependency.allowedValues,
                        },
                      },
                    };
                  },
                  {}
                ),
              },
            };
          },
          { dependents: {}, dependsOn: {} }
        );
    },
    stepReset(state) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isShowing, ...resetState } = initialState;
      // Do not reset whether the form is shown or not
      return { ...resetState, isShowing: state.isShowing };
    },
    stepFormShown(state, { payload: isShowing }: PayloadAction<boolean>) {
      state.isShowing = isShowing;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        recipeIngredientDeleted,
        (state, { payload: ingredientIdxToRemove }) => {
          state.ingredients = state.ingredients.filter(
            ({ ingredientIdx }) => ingredientIdx !== ingredientIdxToRemove
          );
        }
      )
      .addCase(
        recipeIngredientUpdated,
        (state, { payload: { index, ingredient } }) => {
          for (const [
            iterator,
            stepIngredient,
          ] of state.ingredients.entries()) {
            if (stepIngredient.ingredientIdx !== index) {
              continue;
            }

            const recalculatedAmount = recalculateStepIngredientAmount({
              oldTotalAmount:
                state.ingredients[iterator].ingredient.quantity.amount,
              newTotalAmount: ingredient.quantity.amount,
              stepAmount: stepIngredient.quantity.amount,
            });
            stepIngredient.ingredient = ingredient;
            stepIngredient.quantity = createQuantity({
              unit: ingredient.quantity.unit,
              amount: recalculatedAmount,
            });
          }
        }
      )
      .addCase(recipeDiscardChanges, () => initialState)
      .addMatcher(shouldResetState, () => initialState);
  },
});

export const {
  reducer: recipeStepsFormReducer,
  actions: {
    stepIndexUpdated,
    stepTextUpdated,
    stepIngredientsUpdated,
    stepCapabilityTypeUpdated,
    stepApplianceUpdated,
    stepCapabilityUpdated,
    stepPhaseUpdated,
    stepSettingUpdated,
    stepSettingsUpdated,
    stepSettingsDependenciesUpdated,
    stepFieldValidated,
    stepSubmitted,
    stepReset,
    stepFormShown,
  },
} = recipeStepsFormSlice;

const selectState = (state: RootState) => state.recipeStepsForm;

export const selectIndex = (state: RootState) => selectState(state).index;

export const selectText = (state: RootState) => selectState(state).text;

export const selectIngredients = (state: RootState) =>
  selectState(state).ingredients;

export const selectCapabilityType = (state: RootState) =>
  selectState(state).capabilityType;

export const selectAppliance = (state: RootState) =>
  selectState(state).appliance;

export const selectCapability = (state: RootState) =>
  selectState(state).capability;

export const selectPhase = (state: RootState) => selectState(state).phase;

export const selectErrors = (state: RootState) => selectState(state).errors;

export const selectErrorsByField =
  (field: keyof Errors) => (state: RootState) =>
    selectState(state).errors[field];

export const selectErrorBySettingId =
  (settingId: ApiEntityId) =>
  (state: RootState): CapabilitySettingsError | undefined =>
    (selectState(state).errors.settings || {})[settingId];

export const selectHasErrors = (state: RootState) =>
  !!Object.values(selectState(state).errors).length;

export const selectSettings = (state: RootState) => selectState(state).settings;

export const selectSettingsDependencies = (state: RootState) =>
  selectState(state).settingsDependencies;

export const selectSettingById =
  (settingId: ApiEntityId) =>
  (state: RootState): AppCapabilitySetting | undefined =>
    selectState(state).settings[settingId];

export const selectSubmitted = (state: RootState) =>
  selectState(state).submitted;

export const selectIsStepUnsaved = (state: RootState) =>
  selectState(state).isUnsaved;

export const selectIsFormShowing = (state: RootState) =>
  selectState(state).isShowing;
