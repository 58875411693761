export const recipeIngredientParams = {
  ingredientName: 'ingredientName',
} as const;

export const recipeIngredientStrings = {
  deleteIngredientDialog: {
    title: `Delete ${recipeIngredientParams.ingredientName}`,
    body: 'Are you sure you want to delete this ingredient?',
    confirmButton: 'Yes, delete',
    cancelButton: 'Cancel',
  },
  ariaLabels: {
    deleteButton: 'delete',
  },
} as const;
