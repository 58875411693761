import { PantryColor, PantryTypography } from '@dropkitchen/pantry-react';
import { checkboxClasses } from '@mui/material';
import type { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { gridClasses, DataGrid } from '@mui/x-data-grid';
import type { FC, SyntheticEvent } from 'react';
import { useEffect, useState, memo } from 'react';

import { tableDefaults } from 'components/Table/Table.constants';
import { TablePagination } from 'components/Table/TablePagination';

export interface TableRow {
  id: string;
}

export type TableColumn = GridColDef;

export type TableLoadingOverlayProps = Required<
  Pick<TableProps, 'rowHeight' | 'rowsPerPage' | 'checkboxSelection'>
>;

export interface TableProps {
  ariaLabel: string;
  columns: TableColumn[];
  rows: TableRow[];
  rowCount: number;
  rowHeight: number;
  loading: boolean;
  checkboxSelection?: boolean;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  components: {
    loadingOverlay: FC<TableLoadingOverlayProps>;
  };
  componentsProps?: {
    row?: {
      onMouseEnter?: (event: SyntheticEvent<HTMLElement>) => void;
      onMouseLeave?: (event: SyntheticEvent<HTMLElement>) => void;
    };
  };
  onPaginationChange: (event: TablePaginationEvent) => void;
  onSelectedRowsChange?: (rows: string[]) => void;
}

export interface TablePaginationEvent {
  page: number;
  rowsPerPage: number;
}

export const Table: FC<TableProps> = memo(function Table({
  ariaLabel,
  columns,
  rows,
  loading,
  rowCount,
  rowHeight,
  checkboxSelection = tableDefaults.checkboxSelection,
  page: initialPage = tableDefaults.firstPage,
  rowsPerPage: initialRowsPerPage = tableDefaults.rowsPerPage,
  rowsPerPageOptions = tableDefaults.rowsPerPageOptions,
  components: { loadingOverlay },
  componentsProps = {},
  onPaginationChange,
  onSelectedRowsChange = () => {},
}) {
  const [page, setPage] = useState<number>(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  useEffect(() => {
    setPage(initialPage);
  }, [initialPage]);

  return (
    <DataGrid
      sx={{
        [`&.${gridClasses.root}`]: {
          border: 'none',
          backgroundColor: PantryColor.SurfaceDefault,
          color: PantryColor.TextDefault,

          /** As we use row height auto, the row height prop is ignored so we need to give some padding to the cells */
          [`&.${gridClasses['root--densityStandard']} .${gridClasses.cell}`]: {
            py: 4,
          },

          /** MUI creates a wrapper around the loader component but it doesn't have any particular identifier */
          [`& .${gridClasses.main} > div[style*="position: absolute;"]`]: {
            zIndex: 1,
            backgroundColor: PantryColor.SurfaceDefault,
          },

          [`& .${gridClasses.columnHeaders}`]: {
            borderRadius: 0,
            border: 'none',

            [`& .${gridClasses.columnHeader}`]: {
              '&:focus': {
                outline: 'none',
              },
              [`& .${gridClasses.columnSeparator}`]: {
                display: 'none',
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                typography: PantryTypography.Body1,
                color: PantryColor.TextMuted,
              },
            },
          },

          [`& .${gridClasses.virtualScroller}`]: {
            pb: 6,

            [`& .${gridClasses.virtualScrollerContent}`]: {
              /**
               * Height is automatically calculated by DataGrid when autoHeight is set to true.
               * Since we use skeleton-styled loaders, we set a minimum height in order to force the grid to render an approximate height when loading.
               */
              minHeight: `${
                rowHeight * (rows.length || rowsPerPage)
              }px !important`,

              [`& .${gridClasses.row}`]: {
                borderTop: '1px dashed',
                borderColor: PantryColor.BorderSubtle,

                '&:hover': {
                  boxShadow: `0px 2px 12px rgba(0, 0, 0, 0.08)`,
                  backgroundColor: 'inherit',
                },

                '&.Mui-selected': {
                  backgroundColor: PantryColor.SurfaceMuted,
                },

                [`& .${gridClasses.cell}`]: {
                  border: 'none',
                  '&:focus, &:focus-within': {
                    outline: 'none',
                  },
                },
              },
            },
          },

          [`& .${gridClasses.footerContainer}`]: {
            borderTop: '1px dashed',
            borderColor: PantryColor.BorderSubtle,
            pt: 2,
          },

          [`& .${checkboxClasses.root}`]: {
            color: PantryColor.BorderDefault,

            [`&.${checkboxClasses.checked}`]: {
              color: PantryColor.SurfaceEmphasis,
            },
          },
        },
      }}
      aria-label={ariaLabel}
      /** Tables won't have internal scroll. Their height will depend on the selected rows per page and scrolling will be handled by a parent container */
      autoHeight
      /** Disable every built-in column menu or filter */
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      /**
       * Disable virtualization as we'll have a maximum of around 80~100 rows being rendered at the same time and they will usually include an image
       * which - if virtualization is enabled - will be added an removed from the DOM, triggering many unnecessary requests
       */
      disableVirtualization
      /** Do not allow selection of a row on click */
      disableSelectionOnClick
      /** Do not show the number of selected rows in the footer */
      hideFooterSelectedRowCount
      /** When using server pagination, we need to keep the selected rows selected even if they are not part of the current page */
      keepNonExistentRowsSelected
      /** Data related props */
      columns={columns}
      rows={rows}
      loading={loading}
      /** Pagination related props */
      pagination
      paginationMode="server"
      page={page}
      pageSize={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      rowCount={rowCount}
      /** Row selection related props */
      checkboxSelection={checkboxSelection}
      selectionModel={selectedRows}
      /** Style related props */
      getRowHeight={() => 'auto'}
      getEstimatedRowHeight={() => rowHeight}
      /** Custom component and component props */
      componentsProps={{
        pagination: {
          rowsPerPageOptions,
          rowCount,
        },
        loadingOverlay: {
          rowHeight,
          rowsPerPage,
          checkboxSelection,
        },
        row: { ...componentsProps.row },
      }}
      components={{
        /* eslint-disable @typescript-eslint/naming-convention */
        Pagination: TablePagination,
        LoadingOverlay: loadingOverlay,
        /* eslint-enable @typescript-eslint/naming-convention */
      }}
      /** Event handlers */
      onPageChange={(value) => {
        setPage(value);
        onPaginationChange({ page: value, rowsPerPage });
      }}
      onPageSizeChange={(updatedRowsPerPage) => {
        setRowsPerPage(updatedRowsPerPage);
        const updatedPage = Math.ceil(
          (page * rowsPerPage) / updatedRowsPerPage
        );
        setPage(updatedPage);
        onPaginationChange({
          page: updatedPage,
          rowsPerPage: updatedRowsPerPage,
        });
      }}
      onSelectionModelChange={(value: GridSelectionModel) => {
        const selected = value.map((v) => v.toString());
        setSelectedRows(selected);
        onSelectedRowsChange(selected);
      }}
    />
  );
});
