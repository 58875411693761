import { appConfig } from 'config/config';

export const apiPathParams = {
  recipeId: 'recipe_id',
  applianceId: 'appliance_id',
  resourceType: 'resource_type',
  resourceId: 'resource_id',
};

const apiHostUrl = appConfig.isDevOrTestEnv()
  ? '/api'
  : appConfig.apiGatewayUrl();
const apiMediaHostUrl = appConfig.isDevOrTestEnv()
  ? `http://${window.location.host}/api-media`
  : appConfig.apiMediaUrl();

const configsUrl = appConfig.isDevOrTestEnv()
  ? '/configs'
  : appConfig.configsUrl();

export const apiRecipesUrl = `${apiHostUrl}/recipes` as const;
export const apiCreateRecipeUrl = apiRecipesUrl;
export const apiGetRecipeUrl =
  `${apiRecipesUrl}/:${apiPathParams.recipeId}` as const;
export const apiUpdateRecipeUrl =
  `${apiRecipesUrl}/:${apiPathParams.recipeId}` as const;
export const apiDeleteRecipeUrl =
  `${apiRecipesUrl}/:${apiPathParams.recipeId}` as const;
export const apiForkRecipeUrl =
  `${apiRecipesUrl}/:${apiPathParams.recipeId}/fork` as const;
export const apiPublishRecipeUrl =
  `${apiRecipesUrl}/:${apiPathParams.recipeId}/publish` as const;
export const apiUnpublishRecipeUrl =
  `${apiRecipesUrl}/:${apiPathParams.recipeId}/unpublish` as const;

export const apiIngredientsUrl = `${apiHostUrl}/reference/ingredients` as const;

const apiSearchUrl = `${apiHostUrl}/search`;
const apiReferenceDataPrefix = `${apiSearchUrl}/reference` as const;
export const apiPreparationsUrl =
  `${apiReferenceDataPrefix}/preparations` as const;
export const apiCapabilitiesUrl =
  `${apiReferenceDataPrefix}/capabilities` as const;
export const apiTagsUrl = `${apiReferenceDataPrefix}/tags` as const;

const apiRecipeUnderstandingPrefix =
  `${apiHostUrl}/recipe-understanding` as const;

export const apiRecipeFromUrl =
  `${apiRecipeUnderstandingPrefix}/from-url` as const;
export const apiRecipeFromTextUrl =
  `${apiRecipeUnderstandingPrefix}/from-text` as const;

export const apiImagesUrl = `${apiHostUrl}/media/images` as const;
export const apiGetListImagesUrl =
  `${apiImagesUrl}/:${apiPathParams.resourceType}/:${apiPathParams.resourceId}` as const;
export const apiGetImagesUrl = `${apiMediaHostUrl}/media/images` as const;

export const apiGetRecipesUrl = `${apiSearchUrl}/recipes` as const;

export const apiAppliancesUrl = `${apiHostUrl}/appliances`;
export const apiGetApplianceUrl =
  `${apiAppliancesUrl}/:${apiPathParams.applianceId}` as const;

export const configAppliancesPath =
  `${configsUrl}/appliances_list.json` as const;
export const configPath = `${configsUrl}/cms_configs.json` as const;

export const apiUserUrl = `${apiHostUrl}/user` as const;
