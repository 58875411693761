import type { ApiLocale } from 'api/types/common/apiLocale';
import { appDisplayCodeByLocale } from 'types/appDisplayCodeByLocale';
import { appRegionByLocale } from 'types/appRegionByLocale';

export const recipeLocaleFieldStrings = {
  label: 'Recipe locale',
  placeholder: 'Select recipe locale',
  hint: "A recipe's locale cannot be changed at a later stage",
  getOptionText: (locale: ApiLocale) =>
    `${appRegionByLocale[locale]}, ${appDisplayCodeByLocale[locale]}`,
} as const;
