import type { ApiAplCapabilityAllowedSettingValueNumeric } from 'api/types/appliance/apiAplCapabilityAllowedSettingValueNumeric';
import type { ApiQuantityUnit } from 'api/types/common/apiQuantityUnit';
import type { ApiRefCapabilityAllowedSettingDependencyValueNumeric } from 'api/types/referenceData/apiRefCapabilityAllowedSettingDependency';

export interface AppCapabilityAllowedSettingValueNumeric {
  min?: number;
  max?: number;
  step?: number;
  unit: ApiQuantityUnit;
}

export const fromApiAplCapabilityAllowedSettingValueNumeric = ({
  min,
  max,
  step,
  referenceUnit,
}: ApiAplCapabilityAllowedSettingValueNumeric): AppCapabilityAllowedSettingValueNumeric => ({
  min,
  max,
  step,
  unit: referenceUnit,
});

export const fromApiRefCapabilityAllowedSettingDependencyValueNumeric = ({
  min,
  max,
  referenceUnit,
}: ApiRefCapabilityAllowedSettingDependencyValueNumeric): AppCapabilityAllowedSettingValueNumeric => ({
  min,
  max,
  unit: referenceUnit,
});
